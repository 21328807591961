import '../../styles/grid.css';
import { Columns } from '../../constants/RoleScreenConstants';
import { Button } from '@material-ui/core';

export const ServiceProviderVerificationDetailColumns = [{
    label: Columns.MaaaNumber.label,
    name: Columns.MaaaNumber.name,
    options: {
        setCellProps: () => ({style: {whiteSpace:"pre"}})
    }
},
{
    label: Columns.EntityName.label,
    name: Columns.EntityName.name,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            if (value?.user?.firstName) {
                return `${value.user?.firstName} ${value.user?.lastName}`;
            }
            else if (value?.regionalCompany?.name){
                return value.regionalCompany?.name;
            }
            return '';
        },
        setCellProps: () => ({style: {whiteSpace:"pre"}})
    }
},
{
    label: Columns.EntityReferenceNumber.label,
    name: Columns.EntityReferenceNumber.name,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            if (value?.entityReferenceNumber) {
                return value?.entityReferenceNumber;
            }
            return '';
        },
        setCellProps: () => ({style: {whiteSpace:"pre"}})
    }
},
{
    label: Columns.EntityReferenceType.label,
    name: Columns.EntityReferenceType.name,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            if (value?.entityReferenceType) {
                return value?.entityReferenceType === "IdNumber" ? "Id Number" : "Company Registration Number";
            }
            return '';
        },
        setCellProps: () => ({style: {whiteSpace:"pre"}})
    }
},
{
    label: Columns.BankName.label,
    name: Columns.BankName.name,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            if (value?.bank?.name) {
                return value.bank?.name;
            }
            return '';
        },
        setCellProps: () => ({style: {whiteSpace:"pre"}})
    }
},
{
    label: Columns.BankAccountType.label,
    name: Columns.BankAccountType.name,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            if (value?.bankAccountType?.description) {
                return value.bankAccountType?.description;
            }
            return '';
        },
        setCellProps: () => ({style: {whiteSpace:"pre"}})
    }
},
{
    label: Columns.BankAccountNumber.label,
    name: Columns.BankAccountNumber.name,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            if (value?.accountNumber) {
                return value.accountNumber;
            }
            return '';
        },
        setCellProps: () => ({style: {whiteSpace:"pre"}})
    }
},
{
    label: Columns.BranchCode.label,
    name: Columns.BranchCode.name,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            if (value?.branchCode) {
                return value.branchCode;
            }
            return '';
        },
        setCellProps: () => ({style: {whiteSpace:"pre"}})
    }
},
{
    label: Columns.PhoneNumber.label,
    name: Columns.PhoneNumber.name,
    options: {
        setCellProps: () => ({style: {whiteSpace:"pre"}})
    }
},
{
    label: Columns.EmailAddress.label,
    name: Columns.EmailAddress.name,
    options: {
        setCellProps: () => ({style: {whiteSpace:"pre"}})
    }
},
{
    label: Columns.EditButton.label,
    name: Columns.EditButton.name,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            return <Button value={value}>{'Edit'}</Button>;
        },
        download: false
    }
}];