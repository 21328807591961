import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { Component } from 'react';
import { Checkbox, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, FormControlLabel, RadioGroup, Radio, Card } from '@material-ui/core';
import userService from "../../services/UserService";
import { formatToLocalNumber, formatNumber, getParentDropdownValues, handleChange, isFormValid, handleRadioButtonChange, isNumber, sortData, shouldDisableButton, validateCompanyRegisterNumber, validateIdNumber } from '../../common/FormHelpers';
import { DashboardTabs, Roles } from '../../constants/RoleScreenConstants';
import { alertMessages, alertType } from '../AlertNotification';
import NumberFormat from 'react-number-format';
import { trackPromise } from 'react-promise-tracker';
import { Areas } from '../../constants/areas';
import { Spinner } from '../Spinner';
import { FormLabel } from '@material-ui/core';

export class UserDialog extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			open: this.props.open,
			isIdNumberValid: true,
			notification: {
				show: false,
				message: '',
				severity: ''
			},
			dropdownValues: [],
			tabName: this.props.tabName,
			requiredInputs: {
				firstName: {
					error: false,
					message: ''
				},
				lastName: {
					error: false,
					message: ''
				},
				idNumber: {
					error: false,
					message: ''
				},
				businessRegistrationNumber: {
					error: false,
					message: ''
				},
				trafficRegisterNumber: {
					error: false,
					message: ''
				},
				phoneNumber: {
					error: false,
					message: ''
				},
				role: {
					error: false,
					message: ''
				},
				parentId: {
					error: false,
					message: ''
				}
			},
			helperText: '',
			userObj: {
				user: {
					id: '',
					firstName: '',
					lastName: '',
					idNumber: '',
					businessRegistrationNumber: '',
					trafficRegisterNumber: '',
					phoneNumber: '',
					emailAddress: '',
					role: ''
				},
				role: '',
				parentId: '',
				isAlsoDriver: false,
				prdpExpiryDate: null,
				prdpRenewalDate: null,
				prdpRenewedBeforeExpiryDate: false, //default: after
				prdpRenewalApproved: true, //default: approved
			},
			idNumberField: {
				id: 'idNumber',
				label: 'SA ID Number',
				placeholder: 'e.g. 8123456789123',
				type: 'number'
			},
			managePrDPRenewalObj: {
				disableBeforeAfter: false,
				disableAll: true,
				hasError: false,
				errorMessage: '',
			},
			shouldDisableButton: false
		}

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleParentDropdownChange = this.handleParentDropdownChange.bind(this);
		this.upsertUser = this.upsertUser.bind(this);
		this.handleRadioButtonChange = this.handleRadioButtonChange.bind(this);
		this.getParentLabel = this.getParentLabel.bind(this);
		this.handlePrDpInputChange = this.handlePrDpInputChange.bind(this);
		this.handlePrDpRadiobuttonChange = this.handlePrDpRadiobuttonChange.bind(this);
	}

	handleParentDropdownChange(event, newVal) {
		let userDetail = this.state.userObj;
		userDetail.parentId = newVal.id ?? '';
		this.setState({ userObj: userDetail });
	}

	handleInputChange(event) {
		let userObj = handleChange(event, this.state);
		this.setState({ userObj: userObj });
	}

	handleIdNumberChange = (event) => {
		let userObj = handleChange(event, this.state);
		this.setState({ userObj: userObj }, () => {
			this.validateIdNumber(event.target.value);
		});
	}

	validateIdNumber = (value, id = null) => {
		let isValid = true;

		if (id === null) id = this.state.idNumberField.id;
		if (id === 'idNumber') isValid = validateIdNumber(value);
		else if (id === 'businessRegistrationNumber') isValid = validateCompanyRegisterNumber(value);

		this.setState({ isIdNumberValid: isValid, shouldDisableButton: !isValid });
	}

	idNumberFieldErrorMessage() {
		if (this.state.isIdNumberValid === true) {
			if (this.state.idNumberField.id === 'idNumber') return this.state.requiredInputs.idNumber.message;
			else if (this.state.idNumberField.id === 'trafficRegisterNumber') return this.state.requiredInputs.trafficRegisterNumber.message;
			else return this.state.requiredInputs.businessRegistrationNumber.message;
		} else {
			if (this.state.idNumberField.id === 'idNumber') return "Please enter a valid South African ID number"
			else if (this.state.idNumberField.id === 'businessRegistrationNumber') return "Please enter a valid South African Company Registration number"
			else return "";
		}
	}

	idNumberFieldHasError = () => {
		if (this.state.isIdNumberValid === true) {
			if (this.state.idNumberField.id === 'idNumber') return this.state.requiredInputs.idNumber.error;
			else if (this.state.idNumberField.id === 'trafficRegisterNumber') return this.state.requiredInputs.trafficRegisterNumber.error;
			else return this.state.requiredInputs.businessRegistrationNumber.error;
		} else return true;
	}


	handleRadioButtonChange(event) {
		let selected = handleRadioButtonChange(event.target.value);
		let requiredInputs = this.state.requiredInputs;
		let userObj = this.state.userObj;
		let inputValue = null;

		let idNumberField = {
			label: selected.label,
			placeholder: selected.placeholder,
			type: selected.type,
			id: selected.id
		}

		switch (selected.id) {
			case 'trafficRegisterNumber':
				if (requiredInputs.idNumber || requiredInputs.businessRegistrationNumber) {
					if (userObj.user.idNumber) {
						userObj.user.trafficRegisterNumber = userObj.user.idNumber;
						userObj.user.idNumber = '';
					}

					if (userObj.user.businessRegistrationNumber) {
						userObj.user.trafficRegisterNumber = userObj.user.businessRegistrationNumber;
						userObj.user.businessRegistrationNumber = '';
					}
					inputValue = userObj.user.trafficRegisterNumber;

					delete requiredInputs.idNumber;
					delete requiredInputs.businessRegistrationNumber;
				}
				break;
			case 'businessRegistrationNumber':
				if (requiredInputs.idNumber || requiredInputs.trafficRegisterNumber) {
					if (userObj.user.idNumber) {
						userObj.user.businessRegistrationNumber = userObj.user.idNumber;
						userObj.user.idNumber = '';
					}

					if (userObj.user.trafficRegisterNumber) {
						userObj.user.businessRegistrationNumber = userObj.user.trafficRegisterNumber;
						userObj.user.trafficRegisterNumber = '';
					}
					inputValue = userObj.user.businessRegistrationNumber;

					delete requiredInputs.idNumber;
					delete requiredInputs.trafficRegisterNumber;
				}
				break;
			case 'idNumber':
				if (requiredInputs.trafficRegisterNumber || requiredInputs.businessRegistrationNumber) {
					if (userObj.user.trafficRegisterNumber) {
						userObj.user.businessRegistrationNumber = userObj.user.trafficRegisterNumber;
						userObj.user.trafficRegisterNumber = '';
					}

					if (userObj.user.businessRegistrationNumber) {
						userObj.user.idNumber = userObj.user.businessRegistrationNumber;
						userObj.user.businessRegistrationNumber = '';
					}
					inputValue = userObj.user.idNumber;

					delete requiredInputs.trafficRegisterNumber;
					delete requiredInputs.businessRegistrationNumber;
				}
				break;
			default:
				break;
		}

		if (requiredInputs[selected.id] === undefined) {
			requiredInputs[selected.id] = {
				error: false,
				message: ''
			};
		}

		this.setState({ idNumberField: idNumberField, requiredInputs: requiredInputs, userObj: userObj }, () => {
			if (inputValue != null) this.validateIdNumber(inputValue, selected.id);
		});
	}

	handlePrDpInputChange(event) {
		const name = event?.target?.name;
		const value = event?.target?.value;

		if (name != null) {
			this.setState((state) => {
				const userObj = state.userObj;
				userObj[name] = value;

				const manageRenewalObj = state.managePrDPRenewalObj;
				manageRenewalObj.disableAll = false;
				manageRenewalObj.disableBeforeAfter = false;
				manageRenewalObj.hasError = false;
				manageRenewalObj.errorMessage = "";

				if (name === "prdpRenewalDate") {
					if (this.isDateValid(value)) {
						//check to see if it is equal to or before the expiry date
						const prdpExpiryDate = state?.userObj?.prdpExpiryDate;
						const isAfter = this.isDateAfter(prdpExpiryDate, value)

						if (isAfter) {				
							//return the error to say that “PrDP date later the PrDPexpiry: Driver not Eligible”
							manageRenewalObj.hasError = true;
							manageRenewalObj.errorMessage = "PrDP date later than PrDPexpiry: Driver not Eligible";

							//disable before/after radio button and default to 'after'
							manageRenewalObj.disableBeforeAfter = true;
							userObj.prdpRenewedBeforeExpiryDate = false;
							userObj.prdpRenewalApproved = true;

						} else {
							if(userObj.prdpRenewedBeforeExpiryDate == null){
								userObj.prdpRenewedBeforeExpiryDate = false;
							}
							if(userObj.prdpRenewalApproved == null){
								userObj.prdpRenewalApproved = true;
							}

							//enable before/after radio button
							manageRenewalObj.disableBeforeAfter = false;
							manageRenewalObj.hasError = false;
						}
					} else {
						manageRenewalObj.disableAll = true;
						manageRenewalObj.hasError = true;
						manageRenewalObj.errorMessage = "An invalid PrDP Renewal date has been entered";

						userObj.prdpRenewedBeforeExpiryDate = null;
						userObj.prdpRenewalApproved = null;
					}
				}
				return { managePrDPRenewalObj: manageRenewalObj, userObj: userObj };
			});
		}
	}

	beforeAfterValue(){
		if(this.state?.userObj?.prdpRenewedBeforeExpiryDate === true) return "before";
		if(this.state?.userObj?.prdpRenewedBeforeExpiryDate === false) return "after";
		return null;
	}

	beforeAfterBool(stringValue){
		if(stringValue === 'before') return true;
		if(stringValue === 'after') return false;
		return null;
	}

	approvedDeclinedValue(){
		if(this.state?.userObj?.prdpRenewalApproved === true) return "approved";
		if(this.state?.userObj?.prdpRenewalApproved === false) return "declined";
		return null;
	}

	approvedDeclinedBool(stringValue){
		if(stringValue === 'approved') return true;
		if(stringValue === 'declined') return false;
		return null;
	}

	handlePrDpRadiobuttonChange(event) {
		const name = event?.target?.name;
		
		let value = null;
		if(name === "prdpRenewedBeforeExpiryDate") value = this.beforeAfterBool(event?.target?.value);
		else if(name === "prdpRenewalApproved") value = this.approvedDeclinedBool(event?.target?.value);
		else value = event?.target?.value === true || event?.target?.value === "true";

		if (name != null) {
			this.setState((state) => {
				const userObj = state.userObj;
				userObj[name] = value;
				return { userObj: userObj };
			});
		}
	}

	styles = {
		radioButton: {
			color: "blue",
			"&$checked": {
				color: "red"
			}
		}
	};

	upsertUser() {
		let { status, inputFields } = isFormValid(this.state.userObj, this.state.requiredInputs);

		if (status) {
			let userObj = this.state.userObj;

			userObj.user.phoneNumber = formatNumber(userObj.user.phoneNumber);

			if (this.props.row.action === 'Create') {
				this.setState({ shouldDisableButton: true });

				userObj.user.id = undefined;
				userObj.prdpRenewalDate = null;
				userObj.prdpRenewalApproved = true;
				userObj.prdpRenewedBeforeExpiryDate = false;

				trackPromise(
					userService.CreateAsync(userObj)
						.then(response => {
							this.setState({ shouldDisableButton: false });

							if (response?.success) {
								this.props.closeDialog(true, response?.message ?? alertMessages.saveSuccess, alertType.success);

								return;
							}

							this.props.closeDialog(true, response?.message ?? alertMessages.saveSuccess, alertType.warning);
						})
						.catch(error => {
							this.setState({ shouldDisableButton: false });
							this.props.closeDialog(true, alertMessages.saveError, alertType.error, true);
						}),
					Areas.dialog
				);
			}
			else {
				
				if(userObj.prdpRenewalDate === '' || userObj.prdpRenewalDate === null){
					userObj.prdpRenewalDate = null;
					userObj.prdpRenewalApproved = true; //default
					userObj.prdpRenewedBeforeExpiryDate = false; //default
				}	
				if(userObj.prdpRenewalApproved === null){
					userObj.prdpRenewalApproved = true; //default
				}
				if(userObj.prdpRenewedBeforeExpiryDate === null){
					userObj.prdpRenewedBeforeExpiryDate = false; //default
				}	

				trackPromise(
					userService.UpdateAsync(userObj)
						.then(response => {
							this.setState({ shouldDisableButton: false });

							if (response?.success) {
								this.props.closeDialog(true, response?.message ?? alertMessages.updateSuccess, alertType.success);

								return;
							}

							this.props.closeDialog(true, response?.message ?? alertMessages.updateError, alertType.warning);
						})
						.catch(error => {
							this.setState({ shouldDisableButton: false });
							this.props.closeDialog(true, alertMessages.updateError, alertType.error, true);
						}),
					Areas.dialog
				);
			}
		}
		else {
			this.setState({ requiredInputs: inputFields });
		}
	}

	getParentLabel(tabName) {
		switch (tabName) {
			case DashboardTabs.RegionalCompanyUsers:
				return "Regional Company";
			case DashboardTabs.Operators:
				return "Regional Company";
			case DashboardTabs.Drivers:
				return "Operator";
			default:
				return "";
		}
	}

	setSelectedRow = () => {
		let idNumberField = this.state.idNumberField;
		let user = this.props.row?.userObj.user;
		let requiredInputs = this.state.requiredInputs;

		if (this.props.tabName === DashboardTabs.Drivers) {
			delete requiredInputs.businessRegistrationNumber;
		}

		if (this.props.tabName === DashboardTabs.Operators) {
			delete requiredInputs.trafficRegisterNumber;
		}

		if (this.props.tabName !== DashboardTabs.Drivers && this.props.tabName !== DashboardTabs.Operators) {
			delete requiredInputs.businessRegistrationNumber;
			delete requiredInputs.trafficRegisterNumber;
		}

		if (user.trafficRegisterNumber && user.trafficRegisterNumber !== '') {
			idNumberField = {
				id: 'trafficRegisterNumber',
				label: 'Traffic Register Number',
				placeholder: 'e.g. 12345678W1234',
				type: 'text'
			}

			if (requiredInputs.idNumber) {
				delete requiredInputs.idNumber;
			}

			if (requiredInputs.businessRegistrationNumber) {
				delete requiredInputs.businessRegistrationNumber;
			}

			if (requiredInputs.trafficRegisterNumber === undefined) {
				requiredInputs.trafficRegisterNumber = {
					error: false,
					message: ''
				};
			}
		}
		else if (user.businessRegistrationNumber && user.businessRegistrationNumber !== '') {
			idNumberField = {
				id: 'businessRegistrationNumber',
				label: 'Business Registration Number',
				placeholder: 'e.g. 81234567891',
				type: 'number'
			}

			if (requiredInputs.trafficRegisterNumber) {
				delete requiredInputs.trafficRegisterNumber;
			}

			if (requiredInputs.idNumber) {
				delete requiredInputs.idNumber;
			}

			if (requiredInputs.businessRegistrationNumber === undefined) {
				requiredInputs.businessRegistrationNumber = {
					error: false,
					message: ''
				};
			}
		}
		else {
			idNumberField = {
				id: 'idNumber',
				label: 'SA ID Number',
				placeholder: 'e.g. 8123456789123',
				type: 'number'
			}

			if (requiredInputs.trafficRegisterNumber) {
				delete requiredInputs.trafficRegisterNumber;
			}

			if (requiredInputs.businessRegistrationNumber) {
				delete requiredInputs.businessRegistrationNumber;
			}

			if (requiredInputs.idNumber === undefined) {
				requiredInputs.idNumber = {
					error: false,
					message: ''
				};
			}
		}

		this.setState({ userObj: this.props.row.userObj, action: this.props.row.action, idNumberField: idNumberField, requiredInputs: requiredInputs }, () => {
			this.handlePrDpInputChange({
				target: { name: "prdpRenewalDate", value: this.props.row.userObj.prdpRenewalDate }
			});
		});
	}

	componentDidMount() {
		this._isMounted = true;
		let requiredInputs = this.state.requiredInputs;

		if (this.props.tabName === DashboardTabs.UtsUsers) {
			delete requiredInputs.parentId;

			this.setState({ requiredInputs: requiredInputs, shouldDisableButton: false });
		} else {
			trackPromise(
				getParentDropdownValues(this.props.tabName)
					.then(response => {
						if (response?.success) {
							return this.setState({ dropdownValues: response.data?.sort(sortData) ?? [], shouldDisableButton: false });
						}

						this.props.closeDialog(true, response.message ?? alertMessages.saveError, alertType.error, true);
					})
					.catch(error => {
						this.setState({ shouldDisableButton: false });
						this.props.closeDialog(true, alertMessages.saveError, alertType.error, true);
					}),
				Areas.dialog
			);
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	userIsAllowedToEditPrDPRenewal() {
		const allowedRoles = [
			Roles.Admin,
			Roles.BlueDotAdmin
		];
		return allowedRoles.includes(this?.props?.user?.role)
	}

	isDateValid(date) {
		return !isNaN(new Date(date).getTime());
	}

	isDateAfter(beforeDate, afterDate) {
		return Date.parse(beforeDate) < Date.parse(afterDate);
	}

	shouldDisplayPrDPRenewalFields(role, isAlsoDriver = false) {
		return role === Roles.Driver || (role === Roles.Operator && isAlsoDriver === true);
	}

	isUpdatingUser(){
		return this?.state?.action === "Update";
	}

	render() {
		const { userObj, action } = this.props.row;

		return (
			<div>
				<Dialog open={this.props.open} onEnter={this.setSelectedRow}>
					<DialogTitle id="form-dialog-title">{action} User</DialogTitle>
					<DialogContent>
						<div className="row justify-content-around">
							<Spinner area={Areas.dialog} className="h-50 container-fluid" innerClassName="col h-100 text-center" style={{ position: 'absolute' }} />
						</div>
						<form>
							<TextField
								autoFocus
								required
								error={this.state.requiredInputs.firstName.error}
								helperText={this.state.requiredInputs.firstName.message}
								margin="dense"
								name="firstName"
								label="First Name"
								type="text"
								fullWidth
								editable="true"
								defaultValue={userObj.user.firstName}
								onChange={this.handleInputChange}
							/>
							<TextField
								autoFocus
								required
								error={this.state.requiredInputs.lastName.error}
								helperText={this.state.requiredInputs.lastName.message}
								margin="dense"
								name="lastName"
								label="Last Name"
								type="text"
								fullWidth
								onChange={this.handleInputChange}
								defaultValue={userObj.user.lastName}
							/>
							<TextField
								autoFocus
								margin="dense"
								required
								disabled={this.props.row?.action.toLowerCase() === 'update'}
								error={this.idNumberFieldHasError()}
								helperText={this.idNumberFieldErrorMessage()}
								name={this.state.idNumberField.id}
								label={this.state.idNumberField.label}
								placeholder={this.state.idNumberField.placeholder}
								type="text"
								fullWidth
								onChange={this.handleIdNumberChange}
								// onBlur={(e) => this.validateIdNumber(e.target.value)}
								defaultValue={userObj.user.idNumber !== '' ? userObj.user.idNumber : userObj.user.trafficRegisterNumber !== '' ? userObj.user.trafficRegisterNumber : userObj.user.businessRegistrationNumber !== '' ? userObj.user.businessRegistrationNumber : ''}
							/>
							<RadioGroup
								defaultValue="SA ID Number"
								value={this.state.idNumberField.id}
								style={{ display: userObj.user.role === Roles.Driver || userObj.user.role === Roles.Operator ? "" : "none" }}
								row
								onChange={this.handleRadioButtonChange}
							>
								<FormControlLabel
									disabled={this.props.row?.action.toLowerCase() === 'update'}
									key="SA ID Number"
									color="default"
									size="small"
									value="idNumber"
									control={<Radio color="default" size="small" style={this.styles.radioButton} />}
									label="SA ID Number"
								/>
								<FormControlLabel
									disabled={this.props.row?.action.toLowerCase() === 'update'}
									key="Business Registration Number"
									value="businessRegistrationNumber"
									color="default"
									size="small"
									style={{ display: userObj.user.role === Roles.Operator ? "" : "none" }}
									control={<Radio color="default" size="small" style={this.styles.radioButton} />}
									label="Company Registration Number"
								/>
								<FormControlLabel
									disabled={this.props.row?.action.toLowerCase() === 'update'}
									key="Traffic Register Number"
									value="trafficRegisterNumber"
									color="default"
									style={{ display: userObj.user.role === Roles.Driver ? "" : "none" }}
									size="small"
									control={<Radio color="default" size="small" style={this.styles.radioButton} />}
									label="Traffic Register Number"
								/>
							</RadioGroup>
							<NumberFormat
								format="### ### ####"
								placeholder="e.g. 071 234 5678"
								customInput={TextField}
								autoFocus
								margin="dense"
								required
								error={this.state.requiredInputs.phoneNumber.error}
								helperText={this.state.requiredInputs.phoneNumber.message}
								name="phoneNumber"
								label="Phone Number"
								type="text"
								fullWidth
								onChange={this.handleInputChange}
								defaultValue={formatToLocalNumber(userObj.user.phoneNumber)}
							/>
							<TextField
								autoFocus
								margin="dense"
								name="emailAddress"
								label="Email Address"
								type="email"
								fullWidth
								onChange={this.handleInputChange}
								defaultValue={userObj.user.emailAddress}
							/>
							<TextField
								autoFocus
								required
								error={this.state.requiredInputs.role.error}
								helperText={this.state.requiredInputs.role.message}
								margin="dense"
								name="role"
								label="Role"
								type="text"
								aria-readonly
								variant="filled"
								fullWidth
								defaultValue={userObj.user.role}
							/>
							<FormControlLabel
								color="default"
								style={this.state.tabName === DashboardTabs.Operators ? {} : { display: 'none' }}
								size="small"
								control={
									<Checkbox
										checked={userObj.isAlsoDriver}
										name="isAlsoDriver"
										onChange={this.handleInputChange}
									/>
								}
								label="Is Also Driver"
							/>
							<Card
								variant="outlined"
								style={{
									display: (
										this.isDateValid(userObj.prdpExpiryDate) &&
										this.userIsAllowedToEditPrDPRenewal() && 
										this.shouldDisplayPrDPRenewalFields(userObj.user.role, userObj.isAlsoDriver) && 
										this.isUpdatingUser()) ? "" : "none",
									padding: '5px 10px'
								}}
							>

									<TextField
										autoFocus
										margin="dense"
										name="prdpExpiryDate"
										label="PrDP Expiry Date"
										type="date"
										fullWidth
										value={userObj.prdpExpiryDate}
										disabled={true}
										InputLabelProps={{ shrink: true }}
									/>
									<TextField
										autoFocus
										margin="dense"
										name="prdpRenewalDate"
										label="PrDP Renewal Date"
										type="date"
										fullWidth
										onChange={this.handlePrDpInputChange}
										value={this.state?.userObj?.prdpRenewalDate}
										InputLabelProps={{ shrink: true }}
										error={this.state?.managePrDPRenewalObj?.hasError}
										helperText={this.state?.managePrDPRenewalObj?.hasError ? this.state?.managePrDPRenewalObj?.errorMessage : null}
									/>
									<RadioGroup
										defaultValue="PrDP Renewal before or after Expiry Date"
										value={this.beforeAfterValue()}
										row
										onChange={this.handlePrDpRadiobuttonChange}
										name="prdpRenewedBeforeExpiryDate"
									>
										<FormControlLabel
											key="before"
											color="default"
											size="small"
											value={"before"}
											control={<Radio color="default" size="small" style={this.styles.radioButton} />}
											label="PrDp Renewed before Expiry"
											disabled={this.state?.managePrDPRenewalObj?.disableBeforeAfter || this.state?.managePrDPRenewalObj?.disableAll}
										/>
										<FormControlLabel
											key="after"
											color="default"
											size="small"
											value={"after"}
											control={<Radio color="default" size="small" style={this.styles.radioButton} />}
											label="PrDp Renewed after Expiry"
											disabled={this.state?.managePrDPRenewalObj?.disableAll}
										/>
									</RadioGroup>
									<FormLabel>Status:</FormLabel>
									<RadioGroup
										defaultValue="PrDP Renewal before or after Expiry Date"
										value={this.approvedDeclinedValue()}
										row
										onChange={this.handlePrDpRadiobuttonChange}
										name="prdpRenewalApproved"
									>
										<FormControlLabel
											key="Declined"
											color="default"
											size="small"
											value={"declined"}
											control={<Radio color="default" size="small" style={this.styles.radioButton} />}
											label="Declined"
											disabled={this.state?.managePrDPRenewalObj?.disableAll}
										/>
										<FormControlLabel
											key="Approved"
											color="default"
											size="small"
											value={"approved"}
											control={<Radio color="default" size="small" style={this.styles.radioButton} />}
											label="Approved"
											disabled={this.state?.managePrDPRenewalObj?.disableAll}
										/>
									</RadioGroup>
							</Card>
							<Autocomplete
								name="parentId"
								options={this.state.dropdownValues}
								required
								fullWidth
								defaultValue={this.state.dropdownValues?.filter(op => op.id === userObj.parentId)[0] ?? {}}
								getOptionLabel={(option) => {
									if (option.name) {
										return option.name;
									}
									else if (option.firstName && option.lastName) {
										return option.firstName + ' ' + option.lastName;
									}
									return '';
								}}
								onChange={(event, newVal) => this.handleParentDropdownChange(event, newVal)}
								style={{ display: this.props.hasParent ? '' : 'none' }}
								renderInput={(params) =>
									<TextField
										label={this.getParentLabel(this.props.tabName)}
										error={this.state.requiredInputs.parentId?.error ?? false}
										helperText={this.state.requiredInputs.parentId?.message ?? ''}
										{...params}
									/>}
							/>
						</form>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => { this.props.closeDialog(false, '', alertType.success) }} color="primary">
							Cancel
						</Button>
						<Button type="button" onClick={this.upsertUser} color="primary" disabled={this.state.shouldDisableButton}>
							{action}
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}