import React, { Component } from 'react';
import { Roles, DashboardTabs } from '../../constants/RoleScreenConstants';
import MUIDataTable from "mui-datatables";
import userService from "../../services/UserService";
import NotificationService from "../../services/NotificationService";
import { TextField, Divider} from '@material-ui/core';
import CustomToolbar from "../CustomToolbar";
import { GetData, processCsvDownload, canEdit } from '../../common/GridHelpers';
import Button from '@material-ui/core/Button';
import AlertNotification, { alertMessages, alertType } from '../AlertNotification';
import '../../styles/grid.css';
import { trackPromise } from 'react-promise-tracker';
import { Areas } from '../../constants/areas';
import { Spinner } from '../Spinner';
import { UserDialog } from "../forms/UserDialog";
import { CompanyDialog } from "../forms/CompanyDialog";
import { VehicleDialog } from "../forms/VehicleDialog";
import { VerificationDetailDialog } from "../forms/VerificationDetailDialog";
import { NotificationColumns } from '../grids/NotificationsColumns';
import { setDialogDetail, setDialogUser, setDialogVehicle, setDialogCompany } from '../../common/FormHelpers';
import notificationService from '../../services/NotificationService';

export class NotificationGrid extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            data: [],
            user: {},
            endUserId: "",
            lastRowExpanded: "",
            selectedRowIndex: "",
            inputLength: "",
            userNotificationsData: [],
            openDialog: false,
            columns: [],
            sendMessageDisplay: true,
            notificationsDialog: false,
            backdropshadow: false,
            rowToEditId: '',
            message: "",
            phoneNumber: '',
            notification: {
                show: false,
                message: '',
                severity: alertType.success
            }
        }
        this.onInputchange = this.onInputchange.bind(this);

        this._isMounted = false;
    }

    onInputchange(event) {
        this.setState({inputLength: this.state.message.length});  
        this.setState({
          [event.target.name]: event.target.value
        });
      }

    defaultColumnOptions = {
        filter: true,
        sort: true,
        display: "excluded",
        selectableRows: false
    }

    tableOptions = {
        filter: true,
        downloadOptions: {
            filename: this.props.tabName + ".csv" ?? "GridDownload.csv",
            filterOptions: {
                useDisplayedRowsOnly: true
            }
        },
        pagination: true,
        responsive: '',
        selectableRows: false,
        expandableRows: false,
        rowsPerPage: 5,
        expandableRowsOnClick: true,
        tableBodyHeight: '50%',
        onDownload: (buildHead, buildBody, columns, data) => {
            let formattedData = [];
            let index = 0;

            data.forEach(element => {
                let row = element?.data.map(rowValue => {
                    if (typeof rowValue === 'object' && rowValue !== null) {
                        return processCsvDownload(this.props.tabName, rowValue)
                    }

                    return rowValue;
                });

                formattedData.push({
                    index: index,
                    data: row
                });

                index++;
            });

            return "\uFEFF" + buildHead(columns) + buildBody(formattedData);
        },
        onRowsDelete: (rowsDeleted, data) => {
            userService.DeleteAsync(data)
                .then(res => {
                    if (res.ok) {
                        this.setState({ data: res });
                        return <AlertNotification alertMessage={res.data?.message ?? alertMessages.deleteSuccess} severity={alertType.success} />;
                    }

                    return <AlertNotification alertMessage={res.data?.message ?? alertMessages.deleteError} severity={alertType.error} />;
                })
                .catch(error => {
                    return <AlertNotification alertMessage={error.data?.message ?? alertMessages.deleteError} severity={alertType.error} />;
                });
        },
        onRowClick: (rowData, rowMeta) => {

            this.setState({openDialog: true});
            this.toggleBackDropShadow();
            const currentNotifications = this.state.userNotificationsData.slice()

            NotificationService.GetNotificationsByEndUserID(this.state.data[rowMeta['rowIndex']]['userId']).then(notifications=>{  
                currentNotifications[rowMeta['rowIndex']] = notifications?.data;
                this.setState({phoneNumber: this.state.data[rowMeta['rowIndex']]['phoneNumber']});
                this.setState({endUserId:  this.state.data[rowMeta['rowIndex']]['userId']});
                this.setState({userNotificationsData: currentNotifications});
                this.setState({selectedRowIndex: rowMeta['rowIndex']});    
            });

        },
        customToolbar: () => {
            return (<CustomToolbar canEdit={canEdit} role={this.props.user.role} tabName={this.props.tabName} dialog={(tabName) => this.loadDialog(tabName)} openDialog={this.openDialog} closeDialog={this.closeDialog} />);
        }
    };

    closeDialog = (showAlert, message, severity, keepOpen) => {
        if (this._isMounted) {
            let notification = {
                show: showAlert,
                message: message,
                severity: severity
            };

            if (keepOpen) {
                if (this._isMounted) {
                    this.setState({ open: true, notification: notification });
                    return;
                }
            }

            trackPromise(
                GetData(this.props.tabName)
                    .then(response => {
                        if (response?.success && this._isMounted) {
                            this.setState({ open: false, notification: notification, data: response?.data ?? [], rowToEditId: '' });
                        }
                        else {
                            this.showAlert(true, response.message ?? alertMessages.dataLoadError, alertType.error);
                        }
                    })
                    .catch((error) => {
                        this.showAlert(true, alertMessages.dataLoadError, alertType.error);
                    }),
                Areas.userGrid
            );
        }
    }

    openDialog = () => {
        if (this._isMounted) {
            this.setState({ open: true });
        }
    }

    toggleBackDropShadow =()=>{
        if(this.state.backdropshadow)
        {
            this.setState({backdropshadow: false});
        } else {
            
            this.setState({backdropshadow: true});
        }
    }
    
    sendMessage = () => {

        if (this.state.sendMessageDisplay)
        {
            this.setState({sendMessageDisplay: false});
            this.setState({openDialog: false});

        } else {
            this.setState({openDialog: true});
            this.setState({sendMessageDisplay: true});
        }
 
        var data = {
            "Message" : this.state.message,
            "PhoneNumber" : this.state.phoneNumber,
            "InitiatedUserId":this.state.endUserId,
            "EndUserId":this.state.endUserId
        }

        notificationService.sendNotificationsAsync(data)
        .then(res => {
            if (res.success) {
                this.closeDialog();
                this.showAlert(true, alertMessages.messageSent, alertType.success);
            }
        })
        .catch(error => {
            this.showAlert(true, alertMessages.messageError, alertType.error);
        });
 
    };
    
    closeDialog = () => {
        this.setState({openDialog: false});
        this.toggleBackDropShadow();
    };

    toggleSendMessageDialog=()=>{
        if (this.state.sendMessageDisplay)
        {
            this.setState({notificationsDialog: true});
            this.setState({sendMessageDisplay: false});
            this.setState({openDialog: false});
        } else {
            this.setState({openDialog: true});
            this.setState({notificationsDialog: false});
            this.setState({sendMessageDisplay: true});
        }
    }

    loadDialog = (tabName) => {
        let row;

        if (this.state.data != null && this.state.data.length !== 0) {
            row = this.state.data.filter(x => x.id === this.state.rowToEditId)[0];
        }

        switch (tabName) {
            case DashboardTabs.ServiceProviderVerificationDetails:
                return (<VerificationDetailDialog row={setDialogDetail(row)} open={this.state.open} tabName={this.props.tabName} openDialog={this.openDialog} closeDialog={this.closeDialog} user={this.props.user} />);
            case DashboardTabs.UtsUsers:
                return (<UserDialog row={setDialogUser(row, Roles.Uts)} open={this.state.open} tabName={this.props.tabName} openDialog={this.openDialog} closeDialog={this.closeDialog} hasParent={false} role={Roles.Uts} user={this.props.user} />);
            case DashboardTabs.RegionalCompanies:
                return (<CompanyDialog row={setDialogCompany(row)} open={this.state.open} tabName={this.props.tabName} openDialog={this.openDialog} closeDialog={this.closeDialog} user={this.props.user} />);
            case DashboardTabs.RegionalCompanyUsers:
                return (<UserDialog row={setDialogUser(row, Roles.RegionalCompany)} open={this.state.open} tabName={this.props.tabName} openDialog={this.openDialog} closeDialog={this.closeDialog} hasParent={true} role={Roles.RegionalCompany} user={this.props.user} />);
            case DashboardTabs.Operators:
                return (<UserDialog row={setDialogUser(row, Roles.Operator)} open={this.state.open} tabName={this.props.tabName} openDialog={this.openDialog} closeDialog={this.closeDialog} hasParent={true} role={Roles.Operator} user={this.props.user} />);
            case DashboardTabs.Drivers:
                return (<UserDialog row={setDialogUser(row, Roles.Driver)} open={this.state.open} tabName={this.props.tabName} openDialog={this.openDialog} closeDialog={this.closeDialog} hasParent={true} role={Roles.Driver} user={this.props.user} />);
            case DashboardTabs.Vehicles:
                return (<VehicleDialog row={setDialogVehicle(row)} open={this.state.open} tabName={this.props.tabName} openDialog={this.openDialog} closeDialog={this.closeDialog} role={Roles.Vehicle} user={this.props.user} />);
            default:
                break;
        }
    }

    showAlert = (show, message, severity) => {
        let notification = this.state.notification;
        notification = {
            show: show,
            message: message,
            severity: severity
        };
        this.setState({ notification: notification });
    }

    componentDidMount() {
        this._isMounted = true;
        trackPromise(
            GetData(this.props.tabName)
                .then(response => {
                    if (!response?.success) {

                        this.showAlert(true, response.message ?? alertMessages.dataLoadError, alertType.error);
                    }
                    
                    let columns = this.props.columns;

                    if (!canEdit(this.props.tabName, this.props.user?.role) && columns) {  

                        columns.splice(columns.length - 1);
                    }

                    if (this._isMounted) {
                        this.setState({ columns: columns, data: response.data ?? [] });
                    }
                })
                .catch((error) => {
                    this.showAlert(true, alertMessages.dataLoadError, alertType.error);
                }),
            Areas.userGrid
        );
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
 
    render() {
        return (
            <div>
                <div className={this.state.backdropshadow ? "dialog-shadow" : null}>
                </div>
                <div className={this.state.notificationsDialog ? "notifications-dialog-table" : null}>
                <div className={this.state.sendMessageDisplay ? "send-message-form" : null}>
                     <TextField
                        autoFocus
                        required
                        margin="dense"
                        name="message"
                        label="Message"
                        type="text"
                        fullWidth
                        inputProps={{maxLength : 161}}
                        multiline
                        value={this.state.message}
                        onChange={this.onInputchange}
                        rows={18}
                    /> 
                    <div> limit: {this.state.inputLength} / 160 </div>
                    <Button className="message-button send-message-button" onClick={() => this.toggleSendMessageDialog()}> close </Button>
                    <Button className="message-button send-message-button" onClick={() => this.sendMessage()}> send message </Button>     
                </div>
                    <dialog open={this.state.openDialog} className="dialog-box">
                        <MUIDataTable
                            data= {this.state.userNotificationsData[this.state.selectedRowIndex]}
                            columns = {NotificationColumns}
                            options = {this.defaultColumnOptions}
                            className="h-100 notification-container"
                        />
                        <Divider variant="middle"/>
                        <div className="message-actions">
                            <Button className="notification-send-message-button" onClick={() => this.toggleSendMessageDialog()}> send message</Button>
                            <Button  className="notification-cancel-message-button" onClick={() => this.closeDialog()}  justify="flex-end"> close </Button>
                        </div>
                    </dialog>
                </div>
                <div className="h-100">
                    <Spinner area={Areas.userGrid} className="h-100 container-fluid" innerClassName="col h-50 text-center" style={{ position: 'absolute' }} />
                    <div>
                        {this.loadDialog(this.props.tabName)}
                    </div>
                    <MUIDataTable
                        data={this.state.data}
                        columns={this.state.columns}
                        options={this.tableOptions}
                        className="h-100"
                    />
                    <AlertNotification
                        open={this.state.notification.show}
                        alertMessage={this.state.notification.message}
                        severity={this.state.notification.severity}
                        showAlert={this.showAlert}
                    />
                </div>
            </div>
        );
    }
}