import React from 'react';
import moment from 'moment';
import '../../styles/grid.css';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import { green, red } from '@material-ui/core/colors';
import { formatToLocalNumber } from '../../common/FormHelpers';
import { NotificationColumns } from '../../constants/RoleScreenConstants';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.gray,
      color: 'rgba(255,255,255, 0.9)',
      boxShadow: theme.shadows[1],
      fontSize: 15,
    },
  }))(Tooltip)

export const DriverNotificationColumns = [{
    label: NotificationColumns.userFirstName.label,
    name: NotificationColumns.userFirstName.name
},
{
    label: NotificationColumns.userLastName.label,
    name: NotificationColumns.userLastName.name
},
{
    label: NotificationColumns.userIdNumber.label,
    name: NotificationColumns.userIdNumber.name,
    options: {
        display: true
    }
},
{
    label: NotificationColumns.userEmailAddress.label,
    name: NotificationColumns.userEmailAddress.name,
    options: {
        display: true
    }
},
{
    label: NotificationColumns.userPhoneNumber.label,
    name: NotificationColumns.userPhoneNumber.name,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            if (value) {
                return formatToLocalNumber(value);
            }
            return '';
        }
    }
},
{
    label: NotificationColumns.userRole.label,
    name: NotificationColumns.userRole.name
},
{
    label: NotificationColumns.notificationDateSent.label,
    name: NotificationColumns.notificationDateSent.name,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            if (value) {
                return moment(value).format('DD MMM YYYY');
            }
            return '';
        }
    }
} ,
{
    label: NotificationColumns.notificationMessage.label,
    name: NotificationColumns.notificationMessage.name,
    options: {
        display: true
    }
},
{
    label: NotificationColumns.id.label,
    name: NotificationColumns.id.name,
    options: {
        display: false
    }
} ];