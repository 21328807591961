import { getFromApi } from './ApiService';
import { Endpoints } from '../services/ApiServiceConstants';

export class IdentityServerService {
    async GetDriverTsAndCsAsync() {
        try {
            let url = `${Endpoints.IdentityServer.GetDriverTsAndCs}`;
            return await getFromApi(url, null, true);
        }
        catch (error) {
            console.log('Error received while running IdentityServerService.GetDriverTsAndCsAsync', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution IdentityServerService.GetDriverTsAndCsAsync');
        }
    }

    async GetOperatorTsAndCsAsync() {
        try {
            let url = `${Endpoints.IdentityServer.GetOperatorTsAndCs}`;
            return await getFromApi(url, null, true);
        }
        catch (error) {
            console.log('Error received while running IdentityServerService.GetOperatorTsAndCsAsync', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution IdentityServerService.GetOperatorTsAndCsAsync');
        }
    }

    ErrorResponse(error){
        return {
            statusText: error.response?.statusText ?? 'Bad Request',
            status: error.response?.status ?? 400,
            success: false,
            message: error.response?.data?.message?.join(', ') ?? error?.message ?? "An error occurred while trying to complete the process, please contact your administrator"
        };
    }
}

const idServerService = new IdentityServerService();

export default idServerService;