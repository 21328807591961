import { getFromApi, postToApi } from './ApiService';
import { Endpoints } from '../services/ApiServiceConstants';
import authService from '../components/api-authorization/AuthorizeService';

export class BankingService {

    async GetByUserIdAsync(userId) {
        try {
            console.log('Start execution BankingService.GetByUserIdAsync');
            
            let url = `${Endpoints.BankingDetails.GetByUserId}?userId=${userId ?? ''}`;
            let token = await this.GetAccessToken();
            return await getFromApi(url, token);
        }
        catch (error) {
            console.log('Error received while running BankingService.GetByUserIdAsync', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution BankingService.GetByUserIdAsync');
        }
    }

    async GetByRegionalCompanyUserIdAsync(userId) {
        try {
            console.log('Start execution BankingService.GetByRegionalCompanyUserIdAsync');
            
            let url = `${Endpoints.BankingDetails.GetByRegionalCompanyUserId}?userId=${userId ?? ''}`;
            let token = await this.GetAccessToken();
            return await getFromApi(url, token);
        }
        catch (error) {
            console.log('Error received while running BankingService.GetByRegionalCompanyUserIdAsync', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution BankingService.GetByRegionalCompanyUserIdAsync');
        }
    }

    async GetUtsAsync() {
        try {
            console.log('Start execution BankingService.GetUtsAsync');
            
            let url = `${Endpoints.BankingDetails.GetUts}`;
            let token = await this.GetAccessToken();
            return await getFromApi(url, token);
        }
        catch (error) {
            console.log('Error received while running BankingService.GetUtsAsync', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution BankingService.GetUtsAsync');
        }
    }

    async GetBanks() {
        try {
            console.log('Start execution BankingService.GetBanks');
            
            let url = `${Endpoints.BankingDetails.GetBanks}`;
            let token = await this.GetAccessToken();
            return await getFromApi(url, token);
        }
        catch (error) {
            console.log('Error received while running BankingService.GetBanks', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution BankingService.GetBanks');
        }
    }

    async GetBankAccountTypes() {
        try {
            console.log('Start execution BankingService.GetBankAccountTypes');
            
            let url = `${Endpoints.BankingDetails.GetBankAccountTypes}`;
            let token = await this.GetAccessToken();
            return await getFromApi(url, token);
        }
        catch (error) {
            console.log('Error received while running BankingService.GetBankAccountTypes', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution BankingService.GetBankAccountTypes');
        }
    }

    async GetAsync() {
        try {
            console.log('Start execution BankingService.GetAsync');

            let url = `${Endpoints.BankingDetails.GetAll}`;
            let token = await this.GetAccessToken();
            return await getFromApi(url, token);
        }
        catch (error) {
            console.log('Error received while running BankingService.GetAsync', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution BankingService.GetAsync');
        }
    }

    async CreateAsync(data) {
        try {
            console.log('Start execution BankingService.CreateAsync');

            let url = `${Endpoints.BankingDetails.Create}`;
            let token = await this.GetAccessToken();
            return await postToApi(url, data, token);
        }
        catch (error) {
            console.log('Error received while running BankingService.CreateAsync', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution BankingService.CreateAsync');
        }
    }

    async UpdateAsync(data) {
        try {
            console.log('Start execution BankingService.CreateAsync');

            let url = `${Endpoints.BankingDetails.Update}`;
            let token = await this.GetAccessToken();
            return await postToApi(url, data, token);
        }
        catch (error) {
            console.log('Error received while running BankingService.CreateAsync', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution BankingService.CreateAsync');
        }
    }

    async UploadAsync(file) {
        try {
            console.log('Start execution BankingService.UploadAsync');

            let url = `${Endpoints.BankingDetails.Upload}`;
            let token = await this.GetAccessToken();
            let contentType = "multipart/form-data";
            let formData = new FormData();
            formData.append("file", file);

            return await postToApi(url, formData, token, contentType);
        }
        catch (error) {
            console.log('Error received while running BankingService.UploadAsync', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution BankingService.UploadAsync');
        }
    }

    async GetAccessToken() {
        return await authService.getAccessToken();
    }

    ErrorResponse(error){
        return {
            statusText: error.response?.statusText ?? 'Bad Request',
            status: error.response?.status ?? 400,
            success: false,
            message: error.response?.data?.message?.join(', ') ?? error?.message ?? "An error occurred while trying to complete the process, please contact your administrator"
        };
    }
}

const bankingService = new BankingService();

export default bankingService;