import React, { Component } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, MenuItem } from '@material-ui/core';
import vehicleService from "../../services/VehicleService";
import { getParentDropdownValues, handleVehicleChange, isFormValid, sortData } from '../../common/FormHelpers';
import { alertMessages, alertType } from '../AlertNotification';
import { trackPromise } from 'react-promise-tracker';
import { Areas } from '../../constants/areas';
import { Spinner } from '../Spinner';

export class VehicleDialog extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            action: '',
            open: this.props.open,
            dropdownValues: [],
            tabName: '',
            requiredInputs: {
                vinNumber: {
                    error: false,
                    message: ''
                },
                licencePlateNumber: {
                    error: false,
                    message: ''
                },
                operatorId: {
                    error: false,
                    message: 'Select Operator'
                }
            },
            vehicle: {
                id: '',
                licencePlateNumber: '',
                vinNumber: '',
                operatorId: '',
            },
            shouldDisableButton: false
        }
    }

    addVehicle = () => {
        let { status, inputFields } = isFormValid(this.state.vehicle, this.state.requiredInputs);

        if (status) {
            let vehicle = this.state.vehicle;
            this.setState({shouldDisableButton: true});

            if (this.state.action === 'Create') {
                vehicle.id = undefined;

                trackPromise(
                    vehicleService.CreateAsync(vehicle)
                        .then(response => {
                            this.setState({ shouldDisableButton: false });

                            if (response?.success) {
                                this.props.closeDialog(true, response?.message ?? alertMessages.saveSuccess, alertType.success);

                                return;
                            }

                            this.props.closeDialog(true, response?.message ?? alertMessages.saveError, alertType.warning);
                        })
                        .catch(error => {
                            this.setState({ shouldDisableButton: false });

                            this.props.closeDialog(true, error.response?.message?.join(', ') ?? alertMessages.saveError, alertType.error, true);
                        }),
                    Areas.dialog
                );
            }
            else {
            }
        }
        else {
            this.setState({ requiredInputs: inputFields });
        }
    }

    handleParentDropdownChange = (event, newVal) => {
        let vehicle = this.state.vehicle;     
        vehicle.operatorId = newVal.id ?? '';
        this.setState({ vehicle: vehicle });
    }

    handleInputChange = (event) => {
        let vehicle = handleVehicleChange(event, this.state);
        this.setState({ vehicle: vehicle });
    }

    componentDidMount() {
        this._isMounted = true;

        trackPromise(
            getParentDropdownValues(this.props.tabName, this.props.user)
                .then(response => {
                    if (response?.success) {
                        return this.setState({ dropdownValues: response.data?.sort(sortData) ?? []});
                    }

                    this.props.closeDialog(true, response?.message ?? alertMessages.saveError, alertType.error, true);
                })
                .catch(error => {
                    this.props.closeDialog(true, alertMessages.saveError, alertType.error, true);
                }),
            Areas.dialog
        );
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSelectedRow = () => {
        this.setState({ vehicle: this.props.row.vehicle, action: this.props.row.action });
    }

    render() {
        const { vehicle, action } = this.props.row;

        return (
            <div>
                <Dialog open={this.props.open} onEnter={this.setSelectedRow}>
                    <DialogTitle id="form-dialog-title">{action} {this.props.role}</DialogTitle>
                    <DialogContent>
                        <div className="row justify-content-around">
                            <Spinner area={Areas.dialog} className="h-50 container-fluid" innerClassName="col h-100 text-center" style={{ position: 'absolute' }} />
                        </div>
                        <form>
                            <TextField
                                autoFocus
                                required
                                error={this.state.requiredInputs.licencePlateNumber.error}
                                helperText={this.state.requiredInputs.licencePlateNumber.message}
                                margin="dense"
                                id="licencePlateNumber"
                                label="Licence Plate Number"
                                type="text"
                                fullWidth
                                onChange={this.handleInputChange}
                                defaultValue={vehicle.licencePlateNumber}
                                onInput={(e) => {
                                    if (e.target.value) {
                                      let inputValue = e.target.value.replace(" ", "").toUpperCase();

                                      e.target.value = inputValue;
                                    }
                                  }}
                            />
                            <TextField
                                autoFocus
                                required
                                error={this.state.requiredInputs.vinNumber.error}
                                helperText={this.state.requiredInputs.vinNumber.message}
                                margin="dense"
                                id="vinNumber"
                                label="VIN Number"
                                type="text"
                                fullWidth
                                onChange={this.handleInputChange}
                                defaultValue={vehicle.vinNumber}
                                onInput={(e) => {
                                    if (e.target.value) {
                                      let inputValue = e.target.value.replace(" ", "").toUpperCase();

                                      e.target.value = inputValue;
                                    }
                                  }}
                            />
                            <Autocomplete
                                error={this.state.requiredInputs.operatorId.error}
                                helperText={this.state.requiredInputs.operatorId.message}
                                options={this.state.dropdownValues}
                                required
                                fullWidth
                                defaultValue={this.state.dropdownValues?.filter(op => op.id === vehicle.operatorId)[0] ?? {}}
                                getOptionLabel={(option) => {
                                if (option.firstName && option.lastName){
                                    return option.firstName + ' ' + option.lastName;
                                }
                                return '';
                                }}
                                onChange={(event, newVal) => this.handleParentDropdownChange(event, newVal)}
                                renderInput={(params) => 
                                <TextField
                                    id="operatorId"
                                    name="operatorId"
                                    {...params}
                                    label="Operator"
                                />}
                            />
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { this.props.closeDialog(false, '', alertType.success) }} color="primary">
                            Cancel
                    </Button>
                        <Button type="button" onClick={this.addVehicle} color="primary" disabled={this.state.shouldDisableButton}>
                            {action}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}