import React, { Component } from 'react';
import { DriverDetail } from '../forms/DriverDetail';
import { Overview } from '../Overview';
import { EntityGrid } from '../grids/EntityGrid';
import { TabBar } from "../TabBar";
import '../../styles/dashboard.css';
import '../../styles/NavMenu.css';
import { Roles, DashboardTabs } from '../../constants/RoleScreenConstants';
import { DriverColumns } from '../grids/DriverColumns';
import { DriverNotificationColumns } from '../grids/DriverNotificationColumns';
import {NotificationGrid} from '../grids/NotificationsGrid';
import authService from '../api-authorization/AuthorizeService';
import userService from '../../services/UserService';

export class DriverPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loggedInEntityType: "",
            user: {},
            tabVisibility: {
                showOverview: false,
                showUtsUsers: false,
                showBankingDetails: false,
                showDriverDetails: false,
                showRegionalCompanies: false,
                showRegionalCompanyUsers: false,
                showOperators: false,
                showDrivers: false,
                showVehicles: false,
                showRoutes: false,
                showNotifications: false
            },
            tabVisibilityLoaded: false,
            dashboardstats: {
                drivers: {
                    registered: 0,
                    approved: 0
                },
                operators: {
                    registered: 0,
                    approved: 0
                },
                vehicles: {
                    registered: 0,
                    approved: 0
                },
                routes: {
                    registered: 0,
                    approved: 0
                }
            }
        }
    }

    manageTabVisibility() {
        let tabsToSee = this.state.tabVisibility;

        switch (this.state.loggedInEntityType) {
            case Roles.BlueDotAdmin:
                tabsToSee.showOverview = true;
                tabsToSee.showDrivers = true;
                tabsToSee.showNotifications = true;
                this.setState({ tabVisibility: tabsToSee });
                break;
            case Roles.Uts:
                tabsToSee.showOverview = true;
                this.setState({ tabVisibility: tabsToSee, tabVisibilityLoaded: true });
                break;
            case Roles.RegionalCompany:
                tabsToSee.showOverview = true;
                tabsToSee.showDrivers = true;
                this.setState({ tabVisibility: tabsToSee, tabVisibilityLoaded: true });
                break;
            case Roles.Operator:
                tabsToSee.showOverview = true;
                tabsToSee.showDrivers = true;
                this.setState({ tabVisibility: tabsToSee, tabVisibilityLoaded: true });
                break;
            case Roles.Driver:
                tabsToSee.showDriverDetails = true;
                this.setState({ tabVisibility: tabsToSee, tabVisibilityLoaded: true });
                break;
            default:
                break;
        };
    }

    componentDidMount() {
        authService.getUser()
            .then(userProfile => {
                userService.GetUserByIdAsync(userProfile?.sub)
                    .then(response => {
                        if(response?.success){
                            if (response?.data) {
                                let user = response?.data;
                                let role = user?.role ?? userProfile?.role;
                                this.setState({ loggedInEntityType: role, user: user });
                                this.manageTabVisibility();
                            }
                        }
                    });
            });
        }
        
        tabBarChildren() {
            let childrenTabs = [];
            let tabCounter = 0;
            
            if (this.state.tabVisibility.showOverview) {
                childrenTabs.push(
                    <div index={tabCounter} label={DashboardTabs.Overview} key={DashboardTabs.Overview}>
                    <Overview loggedInEntity={DashboardTabs.Overview} dashboardstats={this.state.dashboardstats} />
                </div>
            );
            
            tabCounter++;
        }
        
        if (this.state.tabVisibility.showDriverDetails) {
            childrenTabs.push(
                <div index={tabCounter} label={DashboardTabs.DriverDetails} key={DashboardTabs.DriverDetails}>
                    <DriverDetail />
                </div>
            );
            
            tabCounter++;
        }
        
        if (this.state.tabVisibility.showDrivers) {
            childrenTabs.push(
                <div index={tabCounter} label={DashboardTabs.Drivers} key={DashboardTabs.Drivers}>
                    <EntityGrid user={this.state.user} columns={DriverColumns} tabName={DashboardTabs.Drivers} />
                </div>
            );
            
            tabCounter++;
        }
        
        //if (this.state.tabVisibility.showNotifications) {
        //    childrenTabs.push(
        //        <div index={tabCounter} label={DashboardTabs.DriverNotifications} key={DashboardTabs.DriverNotifications}>
        //            <NotificationGrid user={this.state.user} columns={DriverNotificationColumns} tabName={DashboardTabs.DriverNotifications} />
        //        </div>
        //    );

        //    tabCounter++;
        //}

        if (childrenTabs.length !== 0) {
            return (<TabBar>{childrenTabs}</TabBar>)
        }
    }


    render() {
        return (
            <div className="h-100">
                { this.tabBarChildren()}
            </div>
        );
    }
}