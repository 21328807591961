import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
//import { Tab } from './Tab';
import '../styles/tabs.css';

export class TabBar extends Component {
    static propTypes = {
        children: PropTypes.instanceOf(Array).isRequired,
    }

    constructor(props) {
        super(props);

        this.state = {
            activeTab: this.props.children[0].props.index
        };
    }

    onClickTabItem = (event, tabIndex) => {
        this.setState({ activeTab: tabIndex });
    }

    render() {
        const {
            onClickTabItem,
            props: {
                children,
            },
            state: {
                activeTab,
            }
        } = this;

        return (
            <div className="h-100">
                <div style={{ height: '10%' }} className="p-3">
                    <AppBar position="static" color="transparent" elevation={0}>
                        <Tabs
                            value={activeTab}
                            onChange={onClickTabItem}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                        >
                            {children.map((child) => {
                                if (child != null) {
                                    const { label, index } = child.props;

                                    return (
                                        <Tab
                                            className="pb-4"
                                            key={index}
                                            label={label}
                                        />
                                    );
                                }
                            })}
                        </Tabs>
                    </AppBar>
                </div>
                {children.map((child) => {
                    if (child != null && child.props.index !== activeTab) {
                        return undefined;
                    }
                    return <div
                        role="tabpanel"
                        key={child.props.index}
                        hidden={activeTab !== child.props.index}
                        style={{ height: '90%' }}
                    >
                        {child.props.children}
                    </div>;
                })}
            </div>
        );
    }
}