import { getFromApi, postToApi } from './ApiService';
import { Endpoints } from '../services/ApiServiceConstants';
import authService from '../components/api-authorization/AuthorizeService';
import { Roles } from '../constants/RoleScreenConstants';

export class VehicleService {

    async GetAsync(operatorId, vehicleId) {
        try {
            let url = `${Endpoints.Vehicle.Get}?`;

            if(vehicleId != null) {
                url += "vehicleId=" + vehicleId + '&'
            }

            if(operatorId != null) {
                url += "operatorId=" + operatorId
            }

            let token = await this.GetAccessToken();
            return await getFromApi(url, token);
        }
        catch (error) {
            console.log('Error received while running VehicleService.GetAsync', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution VehicleService.GetAsync');
        }
    }

    async GetAllWithNatisInfoAsync() {
        try {
            console.log('Start execution VehicleService.GetAllWithNatisInfoAsync');

            let url = `${Endpoints.Vehicle.GetWithNatisInfo}`;
            let token = await this.GetAccessToken();
            return await getFromApi(url, token);
        }
        catch (error) {
            console.log('Error received while running VehicleService.GetAllWithNatisInfoAsync', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution VehicleService.GetAllWithNatisInfoAsync');
        }
    }

    async GetAllAsync() {
        try {
            let url = `${Endpoints.Vehicle.Get}`;
            let token = await this.GetAccessToken();
            return await getFromApi(url, token);
        }
        catch (error) {
            console.log('Error received while running VehicleService.GetAsync', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution VehicleService.GetAsync');
        }
    }

    async GetTotalsAsync(operatorId, isEligible, isDeleted) {
        try {
            console.log('Start execution VehicleService.GetTotalsAsync');

            let opId = operatorId ?? '';
            let eligibility = isEligible ?? '';
            let deleted = isDeleted ?? '';
            let url = `${Endpoints.Vehicle.GetTotals}?operatorId=${opId}&isEligible=${eligibility}&isDeleted=${deleted}`;
            let token = await this.GetAccessToken();
            return await getFromApi(url, token);
        }
        catch (error) {
            console.log('Error received while running VehicleService.GetTotalsAsync', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution VehicleService.GetTotalsAsync');
        }
    }

    async CreateAsync(data){
        try{
            let url = `${Endpoints.Vehicle.Create}`;
            let token = await this.GetAccessToken();
            return await postToApi(url, data, token, null);
        }
        catch(error) {
            console.log('Error received while running VehicleService.CreateAsync', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution VehicleService.CreateAsync');
        }
    }

    async UpdateVehicleEligibility(vinNumber) {
        try {           
            console.log('Start execution VehicleService.UpdateVehicleEligibility');
            vinNumber =`"${vinNumber}"`
            let url = `${Endpoints.Vehicle.UpdateVehicleEligibility}`;
            let token = await this.GetAccessToken();

            return await postToApi(url,vinNumber,token ,null);
        }
        catch (error) {
            console.log('Error received while running VehicleService.UpdateVehicleEligibility', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution VehicleService.UpdateVehicleEligibility');
        }
    }

    async UpdateVehicleData(userRole, userIdentification){
        try {           
            console.log('Start execution VehicleService.UpdateVehicleData');
            let url = `${Endpoints.Vehicle.UpdateVehicleData}`;
            let token = await this.GetAccessToken();

            if(userRole === Roles.Operator){
                url = `${url}?operatorIdNumber=${userIdentification}`;
            }

            return await getFromApi(url, token);
        }
        catch (error) {
            console.log('Error received while running VehicleService.UpdateVehicleData', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution VehicleService.UpdateVehicleData');
        }
    }
    
    async GetAccessToken() {
        return await authService.getAccessToken();
    }

    ErrorResponse(error){
        return {
            statusText: error.response?.statusText ?? 'Bad Request',
            status: error.response?.status ?? 400,
            success: false,
            message: error.response?.data?.message?.join(', ') ?? error?.message ?? "An error occurred while trying to complete the process, please contact your administrator"
        };
    }
}

const vehicleService = new VehicleService();

export default vehicleService;