import bankingService from "../services/BankingService";
import driverService from "../services/DriverService";
import operatorService from "../services/OperatorService";
import regionalCompanyService from "../services/RegionalCompanyService";
import serviceProviderVerificationDetailService from "../services/ServiceProviderVerificationDetailService";
import userService from "../services/UserService";
import vehicleService from "../services/VehicleService";
import { Roles, DashboardTabs } from '../constants/RoleScreenConstants';
import utsService from "../services/UtsService";

export async function GetData(tabName, loggedInUserRole) {
	switch (tabName) {
		case DashboardTabs.BankingDetails:
			return bankingService.GetAsync()
				.then(response => {
					if (response.success) {
						response.data = mapToBankingGrid(response.data) ?? [];
					}

					return response;
				});
		case DashboardTabs.ServiceProviderVerificationDetails:
			return serviceProviderVerificationDetailService.GetAsync()
				.then(response => {
					if (response.success) {
						response.data = mapToVerificationDetailGrid(response.data) ?? [];
					}

					return response;
				});
		case DashboardTabs.UtsUsers:
			return userService.GetUsersByRoleAsync(Roles.Uts)
				.then(response => {
					return response;
				});
		case DashboardTabs.RegionalCompanies:
			return regionalCompanyService.GetAllAsync()
				.then(response => {
					return response;
				});
		case DashboardTabs.RegionalCompanyUsers:
			return regionalCompanyService.GetUsersAsync(null, null, loggedInUserRole)
				.then(response => {
					if (response.success) {
						response.data = mapToUserGrid(response.data) ?? [];
					}

					return response;
				});
		case DashboardTabs.Operators:
			return operatorService.GetAllAsync(loggedInUserRole)
				.then(response => {
					if (response.success) {
						response.data = mapToUserGrid(response.data) ?? [];
					}

					return response;
				});
		case DashboardTabs.Drivers:
			return driverService.GetAllWithNatisInfoAsync(loggedInUserRole)
				.then(response => {
					if (response.success) {
						response.data = mapToUserGrid(response.data) ?? [];
					}

					return response;
				});
		case DashboardTabs.Vehicles:
			return vehicleService.GetAllWithNatisInfoAsync()
				.then(response => {
					if (response.success) {
						response.data = mapToVehicleGrid(response.data) ?? [];
					}

					return response;
				});
		case DashboardTabs.DriverNotifications:

			return driverService.GetAllNotificationsAsync()
				.then(response => {
					if (response.success) {
						response.data = mapToNotificationGrid(response.data) ?? [];
					}

					return response;
				});
		case DashboardTabs.OperatorNotifications:

			return operatorService.GetAllNotificationsAsync()
				.then(response => {
					if (response.success) {
						response.data = mapToNotificationGrid(response.data) ?? [];
					}
					return response;
				});
		case DashboardTabs.UtsNotifications:

			return utsService.GetAllNotificationsAsync()
				.then(response => {
					if (response.success) {
						response.data = mapToNotificationGrid(response.data) ?? [];
					}
					return response;
				});
		case DashboardTabs.RegionalCompanyNotifications:

			return regionalCompanyService.GetAllNotificationsAsync()
				.then(response => {
					if (response.success) {
						response.data = mapToNotificationGrid(response.data) ?? [];
					}
					return response;
				});
		default:
			return [];
	}
}

export function processCsvDownload(tabName, rowValue) {
	switch (tabName) {
		case DashboardTabs.ServiceProviderVerificationDetails:
			return rowValue;
		case DashboardTabs.Operators:
			if (rowValue.status !== undefined) {
				return processEligibilityStatus(rowValue);
			}
			else if (rowValue.tagNumber !== undefined || rowValue.trainingCompletionDate !== undefined) {
				return processTrainingStatus(rowValue);
			}

			return '';
		case DashboardTabs.Drivers:
			if (rowValue.status !== undefined) {
				return processEligibilityStatus(rowValue);
			}
			else if (rowValue.tagNumber !== undefined || rowValue.trainingCompletionDate !== undefined) {
				return processTrainingStatus(rowValue);
			}

			return '';
		case DashboardTabs.Vehicles:
			if (rowValue.status !== undefined) {
				return processEligibilityStatus(rowValue);
			}
			else if (rowValue.completionStatus !== undefined) {
				return processInstallationStatus(rowValue);
			}

			return '';
		default:
			if (rowValue.status !== undefined) {
				return processEligibilityStatus(rowValue);
			}

			return '';
	}
}

function processEligibilityStatus(rowValue) {
	if (rowValue.status === true) {
		return "Eligible";
	}
	else if (rowValue.status === false) {
		if (rowValue.ineligibilityReasons && rowValue.ineligibilityReasons.length > 0) {
			return "Ineligible: " + rowValue.ineligibilityReasons?.join(', ');
		}
		return "Ineligible";
	}
	else {
		return rowValue.ineligibilityReasons?.join(', ') ?? 'Pending Eligibility Check';
	}
}

export async function updateOperatorEligibility(rowValue) {
	if (rowValue !== null) {
		return operatorService.UpdateOperatorEligibility(rowValue);
	}
}

export async function updateVehicleEligibility(rowValue) {
	if (rowValue !== null) {
		return vehicleService.UpdateVehicleEligibility(rowValue);
	}
}

export async function updateDriverEligibility(rowValue) {
	if (rowValue !== null) {
		return driverService.UpdateDriverEligibility(rowValue);
	}
}

function processTrainingStatus(rowValue) {
	if (rowValue.tagNumber && !rowValue.trainingStatus) {
		return rowValue.tagNumber;
	}
	else if (rowValue.trainingStatus) {
		return rowValue.trainingStatus;
	}
	else {
		return '';
	}
}

function processInstallationStatus(rowValue) {
	if (rowValue.completionStatus) {
		return 'Installation complete';
	}
	else if (rowValue.isDecommissioningDone) {
		return 'Vehicle Decommissioned';
	}
	else if (!rowValue.completionStatus) {
		return 'Installation not yet complete';
	}
	else {
		return '';
	}
}

function mapToNotificationGrid(data) {
	return data?.map(entityData => {
		return mapOperatorNotificationsToUserNotifications(entityData);
	});
}

function mapNotificationsToUser(entityData) {
	let notifications = {};

	notifications.dateAdded = entityData.dateAdded;
	notifications.dateModified = entityData.dateModified;
	notifications.dateSent = entityData.dateSent;
	notifications.Email = entityData.email;
	notifications.endUserId = entityData.endUserId;
	notifications.initiatedUserId = entityData.initiatedUserId;
	notifications.isDeleted = entityData.isDeleted;
	notifications.Message = entityData.message;
	notifications.phoneNumber = entityData.phoneNumber;
	notifications.viaEmailAddress = entityData.viaEmailAddress;
	notifications.viaPhoneNumber = entityData.viaPhoneNumber;
	notifications.dateAdded = entityData.dateAdded;

	return notifications;
}

function mapOperatorNotificationsToUserNotifications(data) {

	let notifications = {};

	notifications.notificationDateAdded = data?.notification?.dateAdded;
	notifications.notificationDateModified = data?.notification?.dateModified;
	notifications.notificationDateSent = data?.notification?.dateSent;
	notifications.notificationEmail = data?.notification?.email;
	notifications.notificationEndUserId = data?.notification?.endUserId;
	notifications.notificationId = data?.notification?.isDeleted;
	notifications.notificationInitiatedUserId = data?.notification?.initiatedUserId;
	notifications.notificationIsDeleted = data?.notification?.isDeleted;
	notifications.notificationMessage = data?.notification?.message;
	notifications.notificationPhoneNumber = data?.notification?.phoneNumber;
	notifications.notificationViaEmailAddress = data?.notification?.viaEmailAddress;
	notifications.notificationViaPhoneNumber = data?.notification?.viaPhoneNumber;
	notifications.userBusinessRegistrationNumber = data?.user?.businessRegistrationNumber;
	notifications.userDateAdded = data?.user?.dateAdded;
	notifications.userDateModified = data?.user?.dateModified;
	notifications.userEmailAddress = data?.user?.emailAddress;
	notifications.userFirstName = data?.user?.firstName;
	notifications.userId = data?.user?.id;
	notifications.userIdNumber = data?.user?.idNumber;
	notifications.userIsDeleted = data?.user?.isDeleted;
	notifications.userLastName = data?.user?.lastName;
	notifications.userPhoneNumber = data?.user?.phoneNumber;
	notifications.userRole = data?.user?.role;
	notifications.userTrafficRegisterNumber = data?.user?.trafficRegisterNumber;

	return notifications;
}

function mapToUserGrid(data) {
	return data?.map(entityData => {

		let user = {};
		user.id = entityData.user.id;
		user.firstName = entityData.user.firstName;
		user.lastName = entityData.user.lastName;
		user.phoneNumber = entityData.user.phoneNumber;
		user.emailAddress = entityData.user.emailAddress;
		user.role = entityData.user.role;
		user.idNumber = entityData.user.idNumber;
		user.trafficRegisterNumber = entityData.user.trafficRegisterNumber;
		user.businessRegistrationNumber = entityData.user.businessRegistrationNumber;
		user.eligibility = {
			status: entityData.operator?.isEligible ?? entityData.driver?.isEligible ?? null,
			dateOfEligibility: entityData.operator?.dateOfEligibility ?? entityData.driver?.dateOfEligibility ?? null,
			dateOfIneligibility: entityData.operator?.dateOfIneligibility ?? entityData.driver?.dateOfIneligibility ?? null,
			ineligibilityReasons: entityData.operator?.ineligibilityReasons ?? entityData.driver?.ineligibilityReasons ?? null,
			prDPRenewalInProgress: entityData.driver?.prdpRenewalInProgress ?? entityData.operator?.prdpRenewalInProgress ?? false,
			prDPRenewalApproved: entityData.driver?.prdpRenewalApproved ?? entityData.operator?.prdpRenewalApproved ?? null,
		};
		user.isAlsoDriver = entityData.operator?.isAlsoDriver ?? false
		user.eligibilityAsDriver = {
			status: entityData.operator?.isEligibleAsDriver ?? null,
			ineligibilityReasons: entityData.operator?.ineligibilityReasonsAsDriver ?? null
		};
		user.dateAdded = entityData.user.dateAdded;
		user.dateModified = entityData.user.dateModified;
		user.parentId = entityData.regionalCompany?.id ?? entityData.operator?.regionalCompany.id ?? entityData.driver?.operatorUserId ?? '';
		user.regionalCompany = entityData.regionalCompany ?? entityData.operator?.regionalCompany ?? {};
		user.operator = {
			operatorFullName: entityData.driver?.operatorFirstName ? entityData.driver?.operatorFirstName.concat(' ', entityData.driver?.operatorLastName ?? '') : '',
			operatorFirstName: entityData.driver?.operatorFirstName ?? '',
			operatorLastName: entityData.driver?.operatorLastName ?? '',
			operatorPhoneNumber: entityData.driver?.operatorPhoneNumber ?? ''
		};
		user.trainingStatus = {
			hasCompletedTraining: (entityData.driver?.trainingCompletionDate || entityData.operator?.trainingCompletionDate) ? true : false,
			trainingStatus: (entityData.driver?.trainingCompletionDate || entityData.operator?.trainingCompletionDate) ? 'Training complete' : 'Training not yet complete',
			tagNumber: entityData.driver?.tagNumber ?? entityData.operator?.tagNumber ?? '',
			trainingCompletionDate: entityData.driver?.trainingCompletionDate ?? entityData.operator?.trainingCompletionDate ?? null
		};
		user.hasAcceptedTsAndCs = entityData.driver?.hasAcceptedTsAndCs ?? entityData.operator?.hasAcceptedTsAndCs ?? entityData.hasAcceptedTsAndCs ?? false;
		user.hasCreatedPassword = entityData.driver?.hasCreatedPassword ?? entityData.operator?.hasCreatedPassword ?? entityData.hasCreatedPassword ?? false;
		user.prDPExpiryDate = entityData.driver?.natisResponse?.response?.prDP?.expiryDate ?? entityData.operator?.natisResponse?.response?.prDP?.expiryDate ?? '';
		user.prDPRenewalDate = entityData.driver?.prdpRenewalDate ?? entityData.operator?.prdpRenewalDate ?? '';
		user.prDPRenewalInProgress = entityData.driver?.prdpRenewalInProgress ?? entityData.operator?.prdpRenewalInProgress ?? false;
		user.prDPRenewalApproved = entityData.driver?.prdpRenewalApproved ?? entityData.operator?.prdpRenewalApproved ?? null;
		user.prDPRenewedBeforeExpiryDate = entityData.driver?.prdpRenewedBeforeExpiryDate ?? entityData.operator?.prdpRenewedBeforeExpiryDate ?? null;
		return user;
	});
}

function mapToVehicleGrid(data) {
	return data?.map(entityData => {
		let vehicle = {};
		vehicle.id = entityData.Id;
		vehicle.licencePlateNumber = entityData.licencePlateNumber;
		vehicle.vin = entityData.vin;
		vehicle.eligibility = {
			status: entityData.isEligible ?? null,
			dateOfEligibility: entityData.dateOfEligibility ?? null,
			dateOfIneligibility: entityData.dateOfIneligibility ?? null,
			ineligibilityReasons: entityData.ineligibilityReasons ?? null
		};
		vehicle.installationStatus = {
			completionStatus: entityData.fleetNumber ? true : false,
			isDecommissioningDone: entityData.isDecommissioningDone ?? false,
			fleetNumber: entityData.fleetNumber
		};
		vehicle.operator = {
			operatorFullName: entityData.operatorFirstName ? entityData.operatorFirstName.concat(' ', entityData.operatorLastName ?? '') : '',
			operatorFirstName: entityData.operatorFirstName ?? '',
			operatorLastName: entityData.operatorLastName ?? '',
			operatorPhoneNumber: entityData.operatorPhoneNumber ?? ''
		};
		vehicle.dateAdded = entityData.dateAdded;
		vehicle.dateModified = entityData.dateModified;
		vehicle.vehicleLicenceExpiryDate = entityData.natisResponse?.sapVehicleQueryResponse[0]?.vehicle?.licenceExpiryDate ?? '';

		return vehicle;
	});
}

function mapToVerificationDetailGrid(data) {
	return data?.map(entityData => {
		let row = {};
		row.id = entityData.id;
		row.maaaNumber = entityData.maaaNumber;
		row.entityReference = {
			entityReferenceNumber: entityData.entityReferenceNumber,
			entityReferenceType: entityData.entityReferenceType
		};
		row.phoneNumber = entityData.user?.phoneNumber ?? entityData.regionalCompany?.phoneNumber;
		row.emailAddress = entityData.user?.emailAddress ?? entityData.regionalCompany?.emailAddress;
		row.entity = {
			user: entityData.user,
			regionalCompany: entityData.regionalCompany
		}

		row.bankAccount = entityData.bankAccount;

		return row;
	});
}

function mapToBankingGrid(data) {
	return data?.map(entityData => {
		let row = {};

		row.idNumber = entityData.idNumber;
		row.bankAccount = {
			bankName: entityData.bank?.name,
			bankAccountType: entityData.bankAccountType?.description,
			bankAccountNumber: entityData.accountNumber,
			bankAccountHolder: entityData.accountHolder,
			branchCode: entityData.branchCode
		};
		row.dateAdded = entityData.dateAdded;
		row.dateModified = entityData.dateModified;
		row.vatNumber = entityData.vatNumber;

		return row;
	});
}

export function canViewTsAndCs(userRole, tab) {
	var tsAndCsGrids = [DashboardTabs.Drivers, DashboardTabs.Operators, DashboardTabs.RegionalCompanyUsers];

	if (userRole === Roles.BlueDotAdmin && tsAndCsGrids.indexOf(tab) !== -1) {
		return true;
	}

	return false;
}

export function updateVehicleData(userRole, userIdentifier) {
	return vehicleService.UpdateVehicleData(userRole, userIdentifier)
		.then(response => {
			return response;
		});
}

export function canEdit(tabName, userRole, button) {
	switch (userRole) {
		case Roles.BlueDotAdmin:
			if ((tabName !== DashboardTabs.BankingDetails && button === "upload") || (tabName !== DashboardTabs.Vehicles && button === "import") || (tabName === DashboardTabs.Vehicles && button === "add")) {
				return false;
			}

			return true;
		case Roles.Uts:
			if (button === "import" || button === "upload" || tabName === DashboardTabs.ServiceProviderVerificationDetails || tabName === DashboardTabs.Vehicles || tabName === DashboardTabs.Drivers || tabName === DashboardTabs.Operators) {
				return false;
			}

			return true;
		case Roles.RegionalCompany:
			if (button === "import" || button === "upload" || tabName === DashboardTabs.ServiceProviderVerificationDetails || tabName === DashboardTabs.Vehicles || tabName === DashboardTabs.Drivers) {
				return false;
			}

			return true;
		case Roles.Operator:
			if ((tabName === DashboardTabs.Drivers && (button !== "add" && button !== "edit")) || (tabName === DashboardTabs.Vehicles && button !== "import")) {
				return false;
			}
			return true;
		default:
			if (button === "import" || button === "upload" || tabName === DashboardTabs.ServiceProviderVerificationDetails || tabName === DashboardTabs.Vehicles) {
				return false;
			}

			return true;
	}
}