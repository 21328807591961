import React, { Component } from 'react';
import { NavbarToggler, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import '../styles/NavMenu.css';
import profileIcon from '../images/profileIcon.png';
import utsSelectedIcon from '../images/uts-solid.png';
import utsUnselectedIcon from '../images/uts.png';
import regionalCompanySelectedIcon from '../images/regionalCompanySelected.svg';
import regionalCompanyUnselectedIcon from '../images/regionalCompanyUnselected.svg';
import operatorUnselectedIcon from '../images/operatorUnselected.svg';
import operatorSelectedIcon from '../images/operatorSelected.svg';
import driverUnselectedIcon from '../images/driverUnselected.svg';
import driverSelectedIcon from '../images/driverSelected.svg';
import vehicleUnselectedIcon from '../images/vehicleUnselected.svg';
import vehicleSelectedIcon from '../images/vehicleSelected.png';
import verificationDetailUnselectedIcon from '../images/moneyUnselected.svg';
import verificationDetailSelectedIcon from '../images/moneySelected.svg';
import logoutIcon from '../images/152535.svg';
import { ApplicationPaths, ResourceActions } from './api-authorization/ApiAuthorizationConstants';
import { Roles, RolePagePermissions } from '../constants/RoleScreenConstants';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  shouldHideNavLink = (role, page) => {
    switch (role) {
      case Roles.Admin:
        return !RolePagePermissions.Admin[page];
      case Roles.BlueDotAdmin:
        return !RolePagePermissions.BlueDotAdmin[page];
      case Roles.Uts:
        return !RolePagePermissions.Uts[page];
      case Roles.RegionalCompany:
        return !RolePagePermissions.RegionalCompany[page];
      case Roles.Operator:
        return !RolePagePermissions.Operator[page];
      case Roles.Driver:
        return !RolePagePermissions.Driver[page];
    }
  }

  render() {
    return (
      <div className="h-100">
        <div className="row h-100 justify-content-around py-3">
          <div className="col-md-12 col text-center">
            <NavbarToggler onClick={this.props.toggleProfile} className="text-dark text-center p-0">
              <img src={profileIcon} className="w-100" style={{maxWidth: '50px'}} />
            </NavbarToggler>
          </div>
          <div className="col-md-12 col text-center" hidden={this.shouldHideNavLink(this.props.role, ResourceActions.Uts)}>
            <NavLink tag={Link} className="text-dark text-center p-0" to={ApplicationPaths.Uts}>
              <img src={this.props.currentPage === ResourceActions.Uts ? utsSelectedIcon : utsUnselectedIcon} className="w-100" style={{maxWidth: '50px'}} />
            </NavLink>
          </div>
          <div className="col-md-12 col text-center" hidden={this.shouldHideNavLink(this.props.role, ResourceActions.RegionalCompany)}>
            <NavLink tag={Link} className="text-dark text-center p-0" to={ApplicationPaths.RegionalCompany}>
              <img src={this.props.currentPage === ResourceActions.RegionalCompany ? regionalCompanySelectedIcon : regionalCompanyUnselectedIcon} className="w-100"style={{maxWidth: '50px'}} />
            </NavLink>
          </div>
          <div className="col-md-12 col text-center" hidden={this.shouldHideNavLink(this.props.role, ResourceActions.Operator)}>
            <NavLink tag={Link} className="text-dark text-center p-0" to={ApplicationPaths.Operator}>
              <img src={this.props.currentPage === ResourceActions.Operator ? operatorSelectedIcon : operatorUnselectedIcon} className="w-100"style={{maxWidth: '50px'}} />
            </NavLink>
          </div>
          <div className="col-md-12 col text-center" hidden={this.shouldHideNavLink(this.props.role, ResourceActions.Driver)}>
            <NavLink tag={Link} className="text-dark text-center p-0" to={ApplicationPaths.Driver}>
              <img src={this.props.currentPage === ResourceActions.Driver ? driverSelectedIcon : driverUnselectedIcon} className="w-100"style={{maxWidth: '50px'}} />
            </NavLink>
          </div>
          <div className="col-md-12 col text-center" hidden={this.shouldHideNavLink(this.props.role, ResourceActions.Vehicle)}>
            <NavLink tag={Link} className="text-dark text-center p-0" to={ApplicationPaths.Vehicle}>
              <img src={this.props.currentPage === ResourceActions.Vehicle ? vehicleSelectedIcon : vehicleUnselectedIcon} className="w-100"style={{maxWidth: '50px'}} />
            </NavLink>
          </div>
          <div className="col-md-12 col text-center" hidden={this.shouldHideNavLink(this.props.role, ResourceActions.ServiceProviderVerificationDetail)}>
            <NavLink tag={Link} className="text-dark text-center p-0" to={ApplicationPaths.ServiceProviderVerificationDetail}>
              <img src={this.props.currentPage === ResourceActions.ServiceProviderVerificationDetail ? verificationDetailSelectedIcon : verificationDetailUnselectedIcon} className="w-100"style={{maxWidth: '50px'}} />
            </NavLink>
          </div>
          <div className="col-md-12 col text-center">
            <NavLink tag={Link} className="text-dark text-center p-0" to={ApplicationPaths.LogOut}>
              <img src={logoutIcon} className="w-100"style={{maxWidth: '50px'}} />
            </NavLink>
          </div>
        </div>
      </div>
    );
  }
}
