import React, { Component } from 'react';
import { NavMenu } from './NavMenu';
import { Collapse } from 'reactstrap';
import { UserProfile } from './forms/UserProfile';
import '../styles/dashboard.css';
import '../styles/NavMenu.css';
import authService from '../components/api-authorization/AuthorizeService';
import userService from '../services/UserService';
import { trackPromise } from 'react-promise-tracker';
import { Areas } from '../constants/areas';

export class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            profileIsOpen: true,
            loggedInEntityType: "",
            user: {}
        }

        this.styles = {
            userProfilePage: {
                marginLeft: 104,
                paddingLeft: 0,
                paddingRight: 0
            }
        };
    }

    toggleProfile = () => {
        this.setState({
            profileIsOpen: !this.state.profileIsOpen
        });
    }

    componentDidMount() {
        authService.getUser()
            .then(userProfile => {
                trackPromise(
                    userService.GetUserByIdAsync(userProfile?.sub)
                        .then(response => response.data)
                        .then(user => {
                            if (user) {
                                let first = user;
                                let role = first?.role ?? userProfile?.role;
                                this.setState({ loggedInEntityType: role, user: first });
                            }
                        }),
                    Areas.userProfile
                );
            });
    }

    render() {
        return (
            <div className="container-fluid h-100">
                <div className="row h-100">
                    <div className="col-sm-1 h-100 border-right bg-light">
                        <NavMenu role={this.state.loggedInEntityType} currentPage={this.props.currentPage} toggleProfile={this.toggleProfile} />
                    </div>
                    <Collapse isOpen={this.state.profileIsOpen} navbar className="col-sm-2 h-100 border-right bg-light shadow">
                        <UserProfile user={this.state.user} className="row" />
                    </Collapse>
                    <div className="col-sm-9 h-100 p-0">
                        <div className="container-fluid h-100">
                            <div className="row h-100 justify-content-around">
                                <div className="col-md-11 h-100">
                                    {this.props.children}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}