import React from 'react';
import moment from 'moment';
import '../../styles/grid.css';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ClearIcon from '@material-ui/icons/Clear';
import { withStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import { green, red } from '@material-ui/core/colors';
import { Columns } from '../../constants/RoleScreenConstants';
import Tooltip from '@material-ui/core/Tooltip';
import { Button } from '@material-ui/core';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.gray,
        color: 'rgba(255,255,255, 0.9)',
        boxShadow: theme.shadows[1],
        fontSize: 15,
    },
}))(Tooltip)

export const VehicleColumns = [{
    label: Columns.LicencePlateNumber.label,
    name: Columns.LicencePlateNumber.name
},
{
    label: Columns.VinNumber.label,
    name: Columns.VinNumber.name
},
{
    label: Columns.Status.label,
    name: Columns.Status.name,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            if (value.status === true) {
                return <LightTooltip title="Eligible">
                    <CheckIcon style={{ color: green[500] }} />
                </LightTooltip>;
            }
            else {
                return <LightTooltip title={value.ineligibilityReasons?.join(', ') ?? ''}>
                    <AccessTimeIcon />
                </LightTooltip>;
            }
        }
    }
},
{
    label: Columns.VehicleLicenceExpiryDate.label,
    name: Columns.VehicleLicenceExpiryDate.name,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            if (value) {
                return moment(value).format('DD MMM YYYY');
            }
            return '';
        }
    }
},
{
    label: Columns.InstallationStatus.label,
    name: Columns.InstallationStatus.name,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            if (value.completionStatus) {
                return <LightTooltip title="Installation complete">
                    <CheckIcon style={{ color: green[500] }} />
                </LightTooltip>;
            }
            else if (value.isDecommissioningDone) {
                return <LightTooltip title="Vehicle Decommissioned">
                    <ClearIcon style={{ color: red[500] }} />
                </LightTooltip>;
            }
            else {
                return <LightTooltip title="Installation not yet complete">
                    <ClearIcon style={{ color: red[500] }} />
                </LightTooltip>;
            }
        }
    }
},
{
    label: Columns.FleetNumber.label,
    name: Columns.FleetNumber.name,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            return value.fleetNumber ?? '';
        }
    }
},
{
    label: Columns.OperatorFullName.label,
    name: Columns.OperatorFullName.name,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            return value?.operatorFullName ?? '';
        }
    }
},
{
    label: Columns.OperatorPhoneNumber.label,
    name: Columns.OperatorPhoneNumber.name,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            return value?.operatorPhoneNumber ?? '';
        },
        display: false
    }
},
{
    label: Columns.DateAdded.label,
    name: Columns.DateAdded.name,
    type: Columns.DateAdded.type,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            if (value) {
                return moment(value).format('DD MMM YYYY');
            }
            return '';
        }
    }
},
{
    label: Columns.UpdateEligibility.label,
    name: "id",
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            return <Button name='updateVehicleEligibility' >
                {'Update'}
            </Button>;
        },
        download: false
    }
},
{
    label: Columns.DateModified.label,
    name: Columns.DateModified.name,
    type: Columns.DateModified.dateTime,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            if (value) {
                return moment(value).format('DD MMM YYYY');
            }
            return '';
        },
        download: false
    }
}];