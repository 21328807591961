import React, { Component } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressCard } from '@fortawesome/free-regular-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import driverService from '../../services/DriverService';
import { Areas } from '../../constants/areas';
import moment from 'moment';

export class DriverDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            driverDetails:
            {
                user: {
                    firstName: '',
                    lastName: '',
                    idNumber: '',
                    trafficRegisterNumber: '',
                    phoneNumber: '',
                    emailAddress: ''
                },
                driver: {
                    isEligible: false,
                    ineligibilityReasons: [],
                    dateOfEligibility: null,
                    dateOfIneligibility: null
                }
            },
            idNumberField : {
                label: 'SA ID Number',
                placeholder: 'e.g. 8123456789123',
                type: 'number',
                id: 'idNumber'
            }
        }
    }

    componentDidMount() {
        trackPromise(
            driverService.GetAllAsync()
                .then(response => {
                    if (response.success !== true || response.data.success === false) {

                    } else {
                        if (response.data != null && response.data.length !== 0) {
                            let driverDetails = this.state.driverDetails;
                            let idNumberField = this.state.idNumberField;

                            driverDetails.user.firstName = response.data[0].user.firstName;
                            driverDetails.user.lastName = response.data[0].user.lastName;
                            driverDetails.user.idNumber = response.data[0].user.idNumber;
                            driverDetails.user.trafficRegisterNumber = response.data[0].user.trafficRegisterNumber;
                            driverDetails.user.emailAddress = response.data[0].user.emailAddress;
                            driverDetails.user.phoneNumber = response.data[0].user.phoneNumber;

                            driverDetails.driver.isEligible = response.data[0].driver.isEligible;
                            driverDetails.driver.ineligibilityReasons = response.data[0].driver.ineligibilityReasons;
                            driverDetails.driver.dateOfEligibility = response.data[0].driver.dateOfEligibility;
                            driverDetails.driver.dateOfIneligibility = response.data[0].driver.dateOfIneligibility;

                            if(driverDetails.user.trafficRegisterNumber && driverDetails.user.trafficRegisterNumber !== ''){
                                idNumberField = {
                                    label: "Traffic Register Number",
                                    placeholder: "e.g. 12345678W1234",
                                    type: "text",
                                    id: "trafficRegisterNumber"
                                }
                            }

                            this.setState({ driverDetails: driverDetails, idNumberField: idNumberField });
                        }
                    }
                })
                .catch(error => { }),
            Areas.driverDetail
        );

    }

    render() {
        return (
            <div className="container-fluid p-0 h-100">
                <div className="row card-border-radius bg-white">
                    <div className="col-md-6">
                        <div className="row py-5 justify-content-around">
                            <div className="col-sm-8">
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    name="name"
                                    label="First Name"
                                    type="text"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FontAwesomeIcon icon={faAddressCard} />
                                            </InputAdornment>
                                        ),
                                        readOnly: true
                                    }}
                                    value={this.state.driverDetails.user.firstName}
                                    className="col"
                                />
                            </div>
                        </div>
                        <div className="row py-5 justify-content-around">
                            <div className="col-sm-8">
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    name="lastName"
                                    label="Last Name"
                                    type="text"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FontAwesomeIcon icon={faAddressCard} />
                                            </InputAdornment>
                                        ),
                                        readOnly: true
                                    }}
                                    value={this.state.driverDetails.user.lastName}
                                    className="col"
                                />
                            </div>
                        </div>
                        <div className="row py-5 justify-content-around">
                            <div className="col-sm-8">
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    name={this.state.idNumberField.id}
                                    label={this.state.idNumberField.label}
                                    type={this.state.idNumberField.type}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FontAwesomeIcon icon={faAddressCard} />
                                            </InputAdornment>
                                        ),
                                        readOnly: true
                                    }}
                                    value={this.state.driverDetails.user?.trafficRegisterNumber && this.state.driverDetails.user?.trafficRegisterNumber !== '' ? this.state.driverDetails.user?.trafficRegisterNumber : this.state.driverDetails.user?.idNumber}
                                    className="col"
                                />
                            </div>
                        </div>
                        <div className="row py-5 justify-content-around">
                            <div className="col-sm-8">
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    name="phoneNumber"
                                    label="Contact Number"
                                    type="text"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FontAwesomeIcon icon={faPhone} />
                                            </InputAdornment>
                                        ),
                                        readOnly: true
                                    }}
                                    value={this.state.driverDetails.user.phoneNumber}
                                    className="col"
                                />
                            </div>
                        </div>
                        <div className="row py-5 justify-content-around">
                            <div className="col-sm-8">
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    name="emailAddress"
                                    label="Email Address"
                                    type="text"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FontAwesomeIcon icon={faAddressCard} />
                                            </InputAdornment>
                                        ),
                                        readOnly: true
                                    }}
                                    value={this.state.driverDetails.user.emailAddress}
                                    className="col"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row py-5 justify-content-around">
                            <div className="col-sm-8">
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    name="isEligible"
                                    label="Is Blue Dot Approved"
                                    type="text"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FontAwesomeIcon icon={faAddressCard} />
                                            </InputAdornment>
                                        ),
                                        readOnly: true
                                    }}
                                    value={this.state.driverDetails.driver.isEligible ? 'Yes' : 'No'}
                                    className="col"
                                />
                            </div>
                        </div>
                        <div className="row py-5 justify-content-around">
                            <div className="col-sm-8">
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    name="dateOfEligibility"
                                    label="Date of Approval"
                                    type="text"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FontAwesomeIcon icon={faAddressCard} />
                                            </InputAdornment>
                                        ),
                                        readOnly: true
                                    }}
                                    value={this.state.driverDetails.driver.dateOfEligibility && this.state.driverDetails.driver.dateOfEligibility !== ''  ? moment(this.state.driverDetails.driver.dateOfEligibility)?.format('DD MMM YYYY') : ''}
                                    className="col"
                                />
                            </div>
                        </div>
                        <div className="row py-5 justify-content-around">
                            <div className="col-sm-8">
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    name="dateOfIneligibility"
                                    label="Date of Disapproval"
                                    type="text"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FontAwesomeIcon icon={faAddressCard} />
                                            </InputAdornment>
                                        ),
                                        readOnly: true
                                    }}
                                    value={this.state.driverDetails.driver.dateOfIneligibility && this.state.driverDetails.driver.dateOfIneligibility !== '' ? moment(this.state.driverDetails.driver.dateOfIneligibility).format('DD MMM YYYY') : ''}
                                    className="col"
                                />
                            </div>
                        </div>
                        <div className="row py-5 justify-content-around">
                            <div className="col-sm-8">
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    name="ineligibilityReasons"
                                    label="Disapproval Reasons"
                                    type="text"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FontAwesomeIcon icon={faAddressCard} />
                                            </InputAdornment>
                                        ),
                                        readOnly: true
                                    }}
                                    value={this.state.driverDetails.driver.ineligibilityReasons?.join('. ')}
                                    className="col"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}