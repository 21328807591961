import '../../styles/grid.css';
import { formatToLocalNumber } from '../../common/FormHelpers';
import { Columns } from '../../constants/RoleScreenConstants';
import moment from 'moment';
import { Button } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import { green, red } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.gray,
      color: 'rgba(255,255,255, 0.9)',
      boxShadow: theme.shadows[1],
      fontSize: 15,
    },
  }))(Tooltip)

export const RegionalCompanyUserColumns = [{
    label: Columns.FirstName.label,
    name: Columns.FirstName.name,
    options:{
        setCellProps: () => ({style: {whiteSpace:"pre"}})
    }
},
{
    label: Columns.LastName.label,
    name: Columns.LastName.name,
    options:{
        setCellProps: () => ({style: {whiteSpace:"pre"}})
    }
},
{
    label: Columns.PhoneNumber.label,
    name: Columns.PhoneNumber.name,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            if (value) {
                return formatToLocalNumber(value);
            }
            return '';
        },
        setCellProps: () => ({style: {whiteSpace:"pre"}})
    }
},
{
    label: Columns.IdNumber.label,
    name: Columns.IdNumber.name,
    options: {
        display: false
    }
},
{
    label: Columns.EmailAddress.label,
    name: Columns.EmailAddress.name,
    options:{
        setCellProps: () => ({style: {whiteSpace:"pre"}})
    }
},
{
    label: Columns.ParentCompany.label,
    name: Columns.ParentCompany.name,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            return value?.name ?? '';
        },
        setCellProps: () => ({style: {whiteSpace:"pre"}})
    }
},
{
    label: Columns.DateAdded.label,
    name: Columns.DateAdded.name,
    type: Columns.DateAdded.type,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            if (value) {
                return moment(value).format('DD MMM YYYY');
            }
            return '';
        },
        setCellProps: () => ({style: {whiteSpace:"pre"}})
    }
},
{
    label: Columns.DateModified.label,
    name: Columns.DateModified.name,
    type: Columns.DateModified.type,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            if (value) {
                return moment(value).format('DD MMM YYYY');
            }
            return '';
        },
        setCellProps: () => ({style: {whiteSpace:"pre"}})
    }
},
{
    label: Columns.HasAcceptedTermsAndConditions.label,
    name: Columns.HasAcceptedTermsAndConditions.name,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            if (value) {          
                return <LightTooltip>
                            <CheckIcon style={{ color: green[500] }} />
                        </LightTooltip>;
            }
            else {
                return <LightTooltip>
                            <ClearIcon style={{ color: red[500] }} />
                        </LightTooltip>;
            }
        }
    }
},
{
    label: Columns.EditButton.label,
    name: Columns.EditButton.name,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            return <Button value={value}>{'Edit'}</Button>;
        },
        download: false
    }
}];