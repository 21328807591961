import React from "react";
import AddIcon from "@material-ui/icons/Add";
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { withStyles } from "@material-ui/core/styles";
import { Tooltip, IconButton } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-regular-svg-icons';

const defaultToolbarStyles = {
    iconButton: {
    },
};

class CustomToolbar extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            tabName: ''
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({ tabName: this.props.tabName });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    addButton = (classes) => {
        if(this.props.canEdit(this.props.tabName, this.props.role, "add")){
            return (<IconButton className={classes.iconButton} onClick={this.props.openDialog}>
                        <AddIcon className={classes.AddIcon} />
                    </IconButton>);
        }
        return <div></div>;
    }

    uploadButton = () => {
        if(this.props.canEdit(this.props.tabName, this.props.role, "upload")){
            return (<IconButton onClick={this.props.openDialog}>                   
                        <FontAwesomeIcon icon={faFileExcel} />
                    </IconButton>);
        }
        return <div></div>;
    }

    importButton = () => {
        if(this.props.canEdit(this.props.tabName, this.props.role, "import")){
            return (<IconButton disabled={this.props.isImportButtonDisabled} onClick={this.props.updateGrid}>                   
                        <ImportExportIcon />
                    </IconButton>);
        }
        return <div></div>;
    }

    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                <Tooltip title={"add"}>
                    {this.addButton(classes)}     
                </Tooltip>
                <Tooltip title={"upload"}>
                    {this.uploadButton()}
                </Tooltip>
                <Tooltip title={"import"}>
                    {this.importButton()}
                </Tooltip>
            </React.Fragment>
        );
    }
}

export default withStyles(defaultToolbarStyles, { name: "CustomToolbar" })(CustomToolbar);