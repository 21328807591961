import React, { Component } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import serviceProviderVerificationDetailService from "../../services/ServiceProviderVerificationDetailService";
import { getParentDropdownValues, handleRadioButtonChange, handleChange, isFormValid } from '../../common/FormHelpers';
import { alertMessages, alertType } from '../AlertNotification';
import { trackPromise } from 'react-promise-tracker';
import { Areas } from '../../constants/areas';
import { Spinner } from '../Spinner';

export class VerificationDetailDialog extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      open: this.props.open ?? false,
      notification: {
        show: false,
        message: '',
        severity: ''
      },
      dropdownValues: [],
      tabName: this.props.tabName,
      requiredInputs: {
        maaaNumber: {
          error: false,
          message: ''
        },
        entityReferenceNumber: {
          error: false,
          message: ''
        },
        entityReferenceType: {
          error: false,
          message: ''
        }
      },
      verificationDetail: {
        id: '',
        maaaNumber: '',
        entityReferenceNumber: '',
        entityReferenceType: ''
      },
      user: {
        id: '',
        name: '',
        lastName: '',
        idNumber: '',
        phoneNumber: '',
        emailAddress: '',
        role: ''
      },
      regionalCompany: {
          name: '',
          businessRegistrationNumber: '',
          controllingRegion: ''
      },
      shouldDisableButton: false
    }

    this.handleInputChange = this.handleInputChange.bind(this);
    this.upsertDetails = this.upsertDetails.bind(this);
    this.handleRadioButtonChange = this.handleRadioButtonChange.bind(this);
  }

  styles = {
      radioButton: {
        color: "blue",
          "&$checked": {
            color: "red"
        }
      }
  };

  handleInputChange(event) {
    let verificationDetail = handleChange(event, this.state);
    this.setState({ verificationDetail: verificationDetail });
  }

  handleRadioButtonChange(event) {
    let selected = handleRadioButtonChange(event.target.name, event.target.value);

    let verificationDetail = this.state.verificationDetail;
    verificationDetail.entityReferenceType = selected.entityReferenceType;

    this.setState({ verificationDetail: verificationDetail });
  }

  upsertDetails() {
    let { status, inputFields } = isFormValid(this.state.verificationDetail, this.state.requiredInputs);

    if (status) {
      this.setState({shouldDisableButton: true});
      let verificationDetail = this.state.verificationDetail;

      if (this.props.row.action === 'Create') {
        verificationDetail.id = undefined;

        trackPromise(
            serviceProviderVerificationDetailService.CreateAsync(verificationDetail)
            .then(response => {
              this.setState({shouldDisableButton: false});

              if (response?.success) {
                this.props.closeDialog(true, response?.message ?? alertMessages.saveSuccess, alertType.success);

                return;
              }

              this.props.closeDialog(true, response?.message ?? alertMessages.saveError, alertType.error, true);
            })
            .catch(error => {
              this.setState({shouldDisableButton: false});
              this.props.closeDialog(true, alertMessages.saveError, alertType.error, true);
            }),
          Areas.dialog
        );
      }
      else {
        trackPromise(
            serviceProviderVerificationDetailService.UpdateAsync(verificationDetail)
            .then(response => {
              this.setState({shouldDisableButton: false});

              if (response?.success) {
                this.props.closeDialog(true, response?.message ?? alertMessages.updateSuccess, alertType.success);

                return;
              }

              this.props.closeDialog(true, response?.message ?? alertMessages.updateError, alertType.error, true);
            })
            .catch(error => {
              this.setState({shouldDisableButton: false});
              this.props.closeDialog(true, alertMessages.updateError, alertType.error, true);
            }),
          Areas.dialog
        );
      }
    }
    else {
      this.setState({ requiredInputs: inputFields });
    }
  }

  setSelectedRow = () => {
    let verificationDetail = {};

    if(this.props.row){
        verificationDetail.id = this.props.row?.id;
        verificationDetail.maaaNumber = this.props.row?.maaaNumber;
        verificationDetail.entityReferenceNumber = this.props.row?.entityReference?.entityReferenceNumber;
        verificationDetail.entityReferenceType = this.props.row?.entityReference?.entityReferenceType;
    }
    else{
        verificationDetail.maaaNumber = '';
        verificationDetail.entityReferenceNumber = '';
        verificationDetail.entityReferenceType = '';
    }
      
    this.setState({ regionalCompany: this.props.row?.entity?.regionalCompany, user: this.props.row?.entity?.user, action: this.props.row?.action, verificationDetail: verificationDetail });
  }

  componentDidMount() {
    this._isMounted = true;
    
    trackPromise(
        getParentDropdownValues(this.props.tabName)
            .then(response => {
            if (response?.success) {
                return this.setState({ dropdownValues: response.data });
            }
            else if(response?.success === false){
                this.props.closeDialog(true, response.message ?? alertMessages.saveError, alertType.error, true);
            }
            })
            .catch(error => {
            this.props.closeDialog(true, alertMessages.saveError, alertType.error, true);
            }),
    Areas.dialog
    );
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { requiredInputs, shouldDisableButton } = this.state;
    const { action, maaaNumber,  entityReference} = this.props.row;

    return (
      <div>
        <Dialog open={this.props.open} onEnter={this.setSelectedRow}>
          <DialogTitle id="form-dialog-title">{action}</DialogTitle>
          <DialogContent>
            <div className="row justify-content-around">
              <Spinner area={Areas.dialog} className="h-50 container-fluid" innerClassName="col h-100 text-center" style={{ position: 'absolute' }} />
            </div>
            <form>
              <TextField
                autoFocus
                required
                error={requiredInputs.maaaNumber.error}
                helperText={requiredInputs.maaaNumber.message}
                margin="dense"
                name="maaaNumber"
                label="MAAA Number"
                type="text"
                fullWidth
                defaultValue={maaaNumber ?? ''}
                onChange={this.handleInputChange}
              />
              <TextField
                autoFocus
                required
                error={requiredInputs.entityReferenceNumber.error}
                helperText={requiredInputs.entityReferenceNumber.message}
                margin="dense"
                name="entityReferenceNumber"
                label="Service Provider Reference Number"
                type="text"
                variant={action === "Create" ? "standard" : "filled"}
                InputProps={{
                    readOnly: action === "Create" ? false : true,
                  }}
                fullWidth
                onChange={this.handleInputChange}
                defaultValue={entityReference?.entityReferenceNumber ?? ''}
              />
              <RadioGroup
                value={this.state.verificationDetail.entityReferenceType}
                name="entityReferenceType"
                row
                onChange={this.handleRadioButtonChange}
              >
                <FormControlLabel
                  key="IdNumber"
                  color="default"
                  size="small"
                  value="IdNumber"
                  control={<Radio color="default" size="small" style={this.styles.radioButton}/>}
                  label="ID Number"
                />
                <FormControlLabel
                  key="CompanyRegistrationNumber"
                  value="CompanyRegistrationNumber"
                  color="default"
                  size="small"
                  control={<Radio color="default" size="small" style={this.styles.radioButton}/>}
                  label="Company Registration Number"
                />
              </RadioGroup>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {this.props.closeDialog(false, '', alertType.success) }} color="primary">
              Cancel
          </Button>
            <Button type="button" onClick={this.upsertDetails} color="primary" disabled={shouldDisableButton}>
              {action}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}