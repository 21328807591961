import { getFromApi, postToApi } from './ApiService';
import { Endpoints } from '../services/ApiServiceConstants';
import authService from '../components/api-authorization/AuthorizeService';

export class ServiceProviderVerificationDetailService {

    async GetAsync() {
        try {
            console.log('Start execution ServiceProviderVerificationDetailService.GetAsync');
            
            let url = `${Endpoints.ServiceProviderVerificationDetail.Get}`;
            let token = await this.GetAccessToken();
            return await getFromApi(url, token);
        }
        catch (error) {
            console.log('Error received while running ServiceProviderVerificationDetailService.GetAsync', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution ServiceProviderVerificationDetailService.GetAsync');
        }
    }

    async GetByEntityReferenceAsync(entityReferenceType, entityReferenceNumber) {
        try {
            console.log('Start execution ServiceProviderVerificationDetailService.GetByEntityReferenceAsync');
            
            let url = `${Endpoints.ServiceProviderVerificationDetail.GetByEntityReference}?entityReferenceType=${entityReferenceType ?? ''}&entityReferenceNumber=${entityReferenceNumber ?? ''}`;
            let token = await this.GetAccessToken();
            return await getFromApi(url, token);
        }
        catch (error) {
            console.log('Error received while running ServiceProviderVerificationDetailService.GetByEntityReferenceAsync', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution ServiceProviderVerificationDetailService.GetByEntityReferenceAsync');
        }
    }

    async CreateAsync(data) {
        try {
            console.log('Start execution ServiceProviderVerificationDetailService.CreateAsync');

            let url = `${Endpoints.ServiceProviderVerificationDetail.Create}`;
            let token = await this.GetAccessToken();
            return await postToApi(url, data, token);
        }
        catch (error) {
            console.log('Error received while running ServiceProviderVerificationDetailService.CreateAsync', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution ServiceProviderVerificationDetailService.CreateAsync');
        }
    }

    async UpdateAsync(data) {
        try {
            console.log('Start execution ServiceProviderVerificationDetailService.UpdateAsync');

            let url = `${Endpoints.ServiceProviderVerificationDetail.Update}`;
            let token = await this.GetAccessToken();
            return await postToApi(url, data, token);
        }
        catch (error) {
            console.log('Error received while running ServiceProviderVerificationDetailService.UpdateAsync', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution ServiceProviderVerificationDetailService.UpdateAsync');
        }
    }

    async DeleteAsync(verificationDetailId) {
        try {
            console.log('Start execution ServiceProviderVerificationDetailService.DeleteAsync');
            
            let url = `${Endpoints.ServiceProviderVerificationDetail.Delete}?verificationDetailId=${verificationDetailId ?? ''}`;
            let token = await this.GetAccessToken();
            return await postToApi(url, null, token);
        }
        catch (error) {
            console.log('Error received while running ServiceProviderVerificationDetailService.DeleteAsync', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution ServiceProviderVerificationDetailService.DeleteAsync');
        }
    }

    async GetAccessToken() {
        return await authService.getAccessToken();
    }

    ErrorResponse(error){
        return {
            statusText: error.response?.statusText ?? 'Bad Request',
            status: error.response?.status ?? 400,
            success: false,
            message: error.response?.data?.message?.join(', ') ?? error?.message ?? "An error occurred while trying to complete the process, please contact your administrator"
        };
    }
}

const serviceProviderVerificationDetailService = new ServiceProviderVerificationDetailService();

export default serviceProviderVerificationDetailService;