import { getFromApi, postToApi } from './ApiService';
import { Endpoints } from './ApiServiceConstants';
import authService from '../components/api-authorization/AuthorizeService';

export class NotificationService {
    async GetNotifications() {
        try {
            let url = `${Endpoints.Notifications.Get}`;
            let token = await this.GetAccessToken();
            return await getFromApi(url, token);
        }
        catch (error) {
            console.log('Error received while running NotificationService.GetNotifications', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution NotificationService.GetNotifications');
        }
    }

    async sendNotificationsAsync(message) {
        try {
            let url = `${Endpoints.Notifications.Create}`;
            let token = await this.GetAccessToken();
            
            return await postToApi(url, message, token);
        }
        catch (error) {
            console.log('Error received while running NotificationService.sendNotificationsAsync', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution NotificationService.sendNotificationsAsync');
        }
    }

    async GetNotificationsByEndUserID(endUserId) {
        try { 
            let url = `${Endpoints.Notifications.GetNotificationsById}?endUserId=${endUserId}`;
            let token = await this.GetAccessToken();

            return await getFromApi(url, token);
        }
        catch (error) {
            console.log('Error received while running NotificationService.GetNotifications', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution NotificationService.GetNotifications');
        }
    }

    async GetAccessToken() {
        return await authService.getAccessToken();
    }

    ErrorResponse(error){
        return {
            statusText: error.response?.statusText ?? 'Bad Request',
            status: error.response?.status ?? 400,
            success: false,
            message: error.response?.data?.message?.join(', ') ?? error?.message ?? "An error occurred while trying to complete the process, please contact your administrator"
        };
    }
}

const notificationService = new NotificationService();

export default notificationService;