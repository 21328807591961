import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { Layout } from './components/Layout';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import ResourceRoutes from './components/ResourceRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import { Spinner } from './components/Spinner';
import { getFile } from './common/FormHelpers';

import './styles/custom.css';
import { Roles } from './constants/RoleScreenConstants';

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <Switch>
                    <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
                    <Route path={ApplicationPaths.DriverTsAndCs} render={() => getFile(Roles.Driver)} />
                    <Route path={ApplicationPaths.OperatorTsAndCs} render={() => getFile(Roles.Operator)} />
                    <AuthorizeRoute path='/' component={ResourceRoutes} />
                </Switch>
                <Spinner />
            </Layout>
        );
    }    
}