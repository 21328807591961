import React, { Component } from 'react';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import { handleChangePassword } from '../../common/FormHelpers';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Grid, TextField } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

export class CreatePasswordPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      confirmPassword: "",
      showPassword: false,
      showConfirmPassword: false,
      requiredInputs: {
        password: {
          error: false,
          message: '',
          value: ''
        },
        confirmPassword: {
          error: false,
          message: '',
          value: ''
        }
      }
    };

    this.handleChange = this.handleChange.bind(this);
  }

  styles = {
    root: {
      background: "rgba(196.00000351667404, 196.00000351667404, 196.00000351667404, 1)",
      minWidth: 105
    },
    input: {
      color: "white"
    }
  };

  handleChange(event) {
    let requiredInputs = handleChangePassword(event, this.state.requiredInputs);
    let name = event.target.name;
    this.setState({ [name]: event.target.value, requiredInputs: requiredInputs });
  }

  handleClickShowPassword = (event) => {
    if (event.target.name === "password") {
      this.setState({ showPassword: true })
    }
    else {
      this.setState({ showConfirmPassword: true })
    }
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  render() {
    const { requiredInputs, showPassword, showConfirmPassword } = this.state;

    return (
      <Grid
        container
        justify="center"
        direction="column"
        alignItems="center">
        <span className="text-left col-sm-12 text-white">
          <ul>
            <li>Password must be <b>8 characters </b> long.</li>
            <li>Password must contain at least <b>1 uppercase</b> letter e.g. AaBbCc</li>
            <li>Password must contain at least <b>1 number</b> e.g. 123456</li>
            <li>Password must contain at least <b>1 special character</b> e.g. #$%^*!</li>
          </ul>
        </span>
        <br/>
        <TextField
          autoFocus
          required
          error={requiredInputs.password.error}
          helperText={requiredInputs.password.message}
          margin="normal"
          name="password"
          label="Password"
          type="password"
          variant="filled"
          fullWidth
          style={this.styles.root}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ListAltOutlinedIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={this.handleClickShowPassword}
                  onMouseDown={this.handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
          onChange={this.handleChange}
        />
        <TextField
          autoFocus
          required
          error={requiredInputs.confirmPassword.error}
          helperText={requiredInputs.confirmPassword.message}
          margin="normal"
          name="confirmPassword"
          label="Confirm Password"
          type="password"
          variant="filled"
          fullWidth
          style={this.styles.root}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ListAltOutlinedIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={this.handleClickShowPassword}
                  onMouseDown={this.handleMouseDownPassword}
                >
                  {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
          onChange={this.handleChange}
        />
      </Grid>
    );
  }
}