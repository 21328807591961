import moment from 'moment';
import '../../styles/grid.css';
import { formatToLocalNumber } from '../../common/FormHelpers';
import { NotificationColumns } from '../../constants/RoleScreenConstants';
import { Button } from '@material-ui/core';

export const UtsNotificationColumns = [{
    label: NotificationColumns.userFirstName.label,
    name: NotificationColumns.userFirstName.name
},
{
    label: NotificationColumns.userLastName.label,
    name: NotificationColumns.userLastName.name
},
{
    label: NotificationColumns.userIdNumber.label,
    name: NotificationColumns.userIdNumber.name,
    options: {
        display: true
    }
},
{
    label: NotificationColumns.userEmailAddress.label,
    name: NotificationColumns.userEmailAddress.name,
    options: {
        display: true
    }
},
{
    label: NotificationColumns.userPhoneNumber.label,
    name: NotificationColumns.userPhoneNumber.name,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            if (value) {
                return formatToLocalNumber(value);
            }
            return '';
        }
    }
},
{
    label: NotificationColumns.userRole.label,
    name: NotificationColumns.userRole.name
},

{
    label: NotificationColumns.notificationMessage.label,
    name: NotificationColumns.notificationMessage.name,
    options: {
        display: true
    }
},
{
    label: NotificationColumns.id.label,
    name: NotificationColumns.id.name,
    options: {
        display: false
    }
}];