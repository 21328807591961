import React, { Component } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressCard } from '@fortawesome/free-regular-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import regionalCompanyService from '../../services/RegionalCompanyService';
import { Areas } from '../../constants/areas';

export class RegionalCompanyDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            companyDetails:
            {
                registeredName: "",
                tradeName: "",
                regNumber: "",
                contactNumber: "",
                emailAddress: "",
                noOfAssociations: "",
                controllingRegion: "",
                registeredAddress: ""
            }
        }
    }

    componentDidMount() {
        trackPromise(
            regionalCompanyService.GetAllAsync()
                .then(response => {
                    if (response.statusText !== 'OK' || response.data.success === false) {

                    } else {
                        if (response.data != null && response.data.length !== 0) {
                            let companyDetails = this.state.companyDetails;

                            companyDetails.registeredName = response.data[0].name;
                            companyDetails.emailAddress = response.data[0].emailAddress;
                            companyDetails.contactNumber = response.data[0].phoneNumber;
                            companyDetails.regNumber = response.data[0].businessRegistrationNumber;
                            companyDetails.controllingRegion = response.data[0].controllingRegion;
                            this.setState({ companyDetails: companyDetails });
                        }
                    }
                })
                .catch(error => { }),
            Areas.regionalCompanyDetail
        );

    }

    render() {
        return (
            <div className="container-fluid p-0 h-100">
                <div className="row card-border-radius bg-white">
                    <div className="col-md-6">
                        <div className="row py-5 justify-content-around">
                            <div className="col-sm-8">
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    name="name"
                                    label="Registered Name"
                                    type="text"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FontAwesomeIcon icon={faAddressCard} />
                                            </InputAdornment>
                                        ),
                                        readOnly: true
                                    }}
                                    value={this.state.companyDetails.registeredName}
                                    className="col"
                                />
                            </div>
                        </div>
                        <div className="row py-5 justify-content-around">
                            <div className="col-sm-8">
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    name="tradeName"
                                    label="Trade Name"
                                    type="text"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FontAwesomeIcon icon={faAddressCard} />
                                            </InputAdornment>
                                        ),
                                        readOnly: true
                                    }}
                                    value={this.state.companyDetails.tradeName}
                                    className="col"
                                />
                            </div>
                        </div>
                        <div className="row py-5 justify-content-around">
                            <div className="col-sm-8">
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    name="registrationNumber"
                                    label="Registration Number"
                                    type="text"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FontAwesomeIcon icon={faAddressCard} />
                                            </InputAdornment>
                                        ),
                                        readOnly: true
                                    }}
                                    value={this.state.companyDetails.regNumber}
                                    className="col"
                                />
                            </div>
                        </div>
                        <div className="row py-5 justify-content-around">
                            <div className="col-sm-8">
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    name="contactNumber"
                                    label="Contact Number"
                                    type="text"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FontAwesomeIcon icon={faPhone} />
                                            </InputAdornment>
                                        ),
                                        readOnly: true
                                    }}
                                    value={this.state.companyDetails.contactNumber}
                                    className="col"
                                />
                            </div>
                        </div>
                        <div className="row py-5 justify-content-around">
                            <div className="col-sm-8">
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    name="emailAddress"
                                    label="Email Address"
                                    type="text"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FontAwesomeIcon icon={faAddressCard} />
                                            </InputAdornment>
                                        ),
                                        readOnly: true
                                    }}
                                    value={this.state.companyDetails.emailAddress}
                                    className="col"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row py-5 justify-content-around">
                            <div className="col-sm-8">
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    name="numberOfAssociations"
                                    label="Number of Associations"
                                    type="text"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FontAwesomeIcon icon={faAddressCard} />
                                            </InputAdornment>
                                        ),
                                        readOnly: true
                                    }}
                                    value={this.state.companyDetails.noOfAssociations}
                                    className="col"
                                />
                            </div>
                        </div>
                        <div className="row py-5 justify-content-around">
                            <div className="col-sm-8">
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    name="controllingRegion"
                                    label="Controlling Region"
                                    type="text"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FontAwesomeIcon icon={faAddressCard} />
                                            </InputAdornment>
                                        ),
                                        readOnly: true
                                    }}
                                    value={this.state.companyDetails.controllingRegion}
                                    className="col"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}