import { getFromApi, postToApi } from './ApiService';
import { Endpoints } from '../services/ApiServiceConstants';
import authService from '../components/api-authorization/AuthorizeService';
import { Roles } from '../constants/RoleScreenConstants';

export class RegionalCompanyService {

    async GetAsync(regionalCompanyId, intiatedUserId) {
        try {
            console.log('Start execution RegionalCompanyService.GetAsync');

            let url = `${Endpoints.RegionalCompany.Get}?`;

            if(regionalCompanyId != null) {
                url += "regionalCompanyId=" + regionalCompanyId + '&'
            }

            if(intiatedUserId != null) {
                url += "intiatedUserId=" + intiatedUserId
            }

            let token = await this.GetAccessToken();
            return await getFromApi(url, token);
        }
        catch (error) {
            console.log('Error received while running RegionalCompanyService.GetAsync', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution RegionalCompanyService.GetAsync');
        }
    }

    async GetAllAsync() {
        try {
            console.log('Start execution RegionalCompanyService.GetAllAsync');

            let url = `${Endpoints.RegionalCompany.Get}`;
            let token = await this.GetAccessToken();
            return await getFromApi(url, token);
        }
        catch (error) {
            console.log('Error received while running RegionalCompanyService.GetAllAsync', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution RegionalCompanyService.GetAllAsync');
        }
    }

    async GetUsersAsync(regionalCompanyId, regionalCompanyUserId, loggedInUserRole) {
        try {
            console.log('Start execution RegionalCompanyService.GetUsersAsync');
            
            let url = `${Endpoints.RegionalCompany.GetUsers}?regionalCompanyId=${regionalCompanyId ?? ''}&regionalCompanyUserId=${regionalCompanyUserId ?? ''}`;

            if(loggedInUserRole === Roles.BlueDotAdmin){
                url = url + '&getTsAndCsInfo=true';
            }
            
            let token = await this.GetAccessToken();
            return await getFromApi(url, token);
        }
        catch (error) {
            console.log('Error received while running RegionalCompanyService.GetUsersAsync', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution RegionalCompanyService.GetUsersAsync');
        }
    }

    async CreateAsync(data) {
        try {
            console.log('Start execution RegionalCompanyService.CreateAsync');

            let url = `${Endpoints.RegionalCompany.Create}`;
            let token = await this.GetAccessToken();
            return await postToApi(url, data, token, null);
        }
        catch (error) {
            console.log(`Error received while running RegionalCompanyService.CreateAsync`, error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution RegionalCompanyService.CreateAsync');
        }
    }

    async GetRegionalCompanyUserTotalsAsync() {
        try {
            console.log('Start execution RegionalCompanyService.GetRegionalCompanyUserTotalsAsync');

            let url = `${Endpoints.RegionalCompany.GetRegionalCompanyUserTotalsAsync}`;
            let token = await this.GetAccessToken();
            return await getFromApi(url, token);
        }
        catch (error) {
            console.log(`Error received while running RegionalCompanyService.GetRegionalCompanyUserTotalsAsync`, error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution RegionalCompanyService.GetRegionalCompanyUserTotalsAsync');
        }
    }

    async GetRegionalCompanyTotalsAsync() {
        try {
            console.log('Start execution RegionalCompanyService.GetRegionalCompanyTotalsAsync');

            let url = `${Endpoints.RegionalCompany.GetRegionalCompanyUserTotalsAsync}`;
            let token = await this.GetAccessToken();
            return await getFromApi(url, token);
        }
        catch (error) {
            console.log(`Error received while running RegionalCompanyService.GetRegionalCompanyTotalsAsync`, error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution RegionalCompanyService.GetRegionalCompanyTotalsAsync');
        }
    }

    async UpdateAsync(data){
        try{
            console.log('Start execution RegionalCompanyService.UpdateAsync');

            let url = `${Endpoints.RegionalCompany.Update}`;
            let token = await this.GetAccessToken();
            return await postToApi(url, data, token, null);
        }
        catch(error) {
            console.log('Error received while running RegionalCompanyService.UpdateAsync', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution RegionalCompanyService.UpdateAsync');
        }
    }

    async DeleteAsync(id){
        try{

        }
        catch(error) {
            console.log('Error received while running RegionalCompanyService.DeleteAsync', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution RegionalCompanyService.DeleteAsync');
        }
    }

    async GetAccessToken() {
        return await authService.getAccessToken();
    }

    async GetAllNotificationsAsync() {
        try {
            console.log('Start execution RegionalCompanyService.GetAllNotificationsAsync');

            let url = `${Endpoints.Notifications.GetRegionalCompanyUsersNotifications}`;
            let token = await this.GetAccessToken();
            return await getFromApi(url, token);
        }
        catch (error) {
            console.log('Error received while running RegionalCompanyService.GetAllNotificationsAsync', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution RegionalCompanyService.GetAllNotificationsAsync');
        }
    }

    ErrorResponse(error){
        return {
            statusText: error.response?.statusText ?? 'Bad Request',
            status: error.response?.status ?? 400,
            success: false,
            message: error.response?.data?.message?.join(', ') ?? error?.message ?? "An error occurred while trying to complete the process, please contact your administrator"
        };
    }
}

const regionalCompanyService = new RegionalCompanyService();

export default regionalCompanyService;