import React, { Component } from 'react';
import { Roles, DashboardTabs, Columns } from '../../constants/RoleScreenConstants';
import MUIDataTable from "mui-datatables";
import userService from "../../services/UserService";
import CustomToolbar from "../CustomToolbar";
import { canViewTsAndCs, canEdit, GetData, processCsvDownload, updateOperatorEligibility, updateVehicleEligibility, updateDriverEligibility, updateVehicleData } from '../../common/GridHelpers';
import AlertNotification, { alertMessages, alertType } from '../AlertNotification';
import '../../styles/grid.css';
import { trackPromise } from 'react-promise-tracker';
import { Areas } from '../../constants/areas';
import { Spinner } from '../Spinner';
import { UserDialog } from "../forms/UserDialog";
import { CompanyDialog } from "../forms/CompanyDialog";
import { VerificationDetailDialog } from "../forms/VerificationDetailDialog";
import { setDialogDetail, setDialogUser, setDialogCompany, sortData } from '../../common/FormHelpers';
import { UploadDialog } from '../forms/UploadDialog';

export class EntityGrid extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            data: [],
            columns: [],
            rowToEditId: '',
            rowToUpdateId: '',
            notification: {
                show: false,
                message: '',
                severity: alertType.success
            },
            isImportButtonDisabled: false
        }

        this._isMounted = false;
    }

    defaultColumnOptions = {
        filter: true,
        sort: true,
        display: "excluded",
        selectableRows: true
    }

    tableOptions = {
        filter: true,
        downloadOptions: {
            filename: this.props.tabName + ".csv" ?? "GridDownload.csv",
            filterOptions: {
                useDisplayedRowsOnly: true
            }
        },
        pagination: true,
        responsive: 'vertical',
        selectableRows: 'multiple',
        rowsPerPage: 10,
        tableBodyHeight: '80%',
        onDownload: (buildHead, buildBody, columns, data) => {
            let formattedData = [];
            let index = 0;

            data.forEach(element => {
                let row = element?.data.map(rowValue => {
                    if (typeof rowValue === 'object' && rowValue !== null) {
                        return processCsvDownload(this.props.tabName, rowValue)
                    }

                    return rowValue;
                });

                formattedData.push({
                    index: index,
                    data: row
                });

                index++;
            });

            return "\uFEFF" + buildHead(columns) + buildBody(formattedData);
        },
        onRowsDelete: (rowsDeleted, data) => {
            userService.DeleteAsync(data)
                .then(res => {
                    if (res.ok) {
                        this.setState({ data: res });
                        return <AlertNotification alertMessage={res.data?.message ?? alertMessages.deleteSuccess} severity={alertType.success} />;
                    }

                    return <AlertNotification alertMessage={res.data?.message ?? alertMessages.deleteError} severity={alertType.error} />;
                })
                .catch(error => {
                    return <AlertNotification alertMessage={error.data?.message ?? alertMessages.deleteError} severity={alertType.error} />;
                });
        },
        onRowClick: (rowData, rowMeta, e) => {
            let isEditButton = e.target.textContent?.toLowerCase() === "edit";
            let isUpdateButton = e.target.parentElement.name?.toLowerCase().trim() === "updateoperator";
            let isUpdateVehicleButton = e.target.parentElement.name?.toLowerCase().trim() === "updatevehicleeligibility";
            let isDriverUpdateButton = e.target.parentElement.name?.toLowerCase().trim() === "updatedrivereligibility";
       
            if (this._isMounted && isEditButton) {
                this.setState({ open: true, rowToEditId: rowData[rowData.length - 1].props?.value });
            }

            if (this._isMounted && isUpdateButton) {
                this.updateOperator(rowData[rowData.length - 1].props?.value);
            }

            if (this._isMounted && isUpdateVehicleButton) {
                this.updateVehicle(rowData[1]);
            }

            if (this._isMounted && isDriverUpdateButton) {
                let identifier = rowData[3] !== '' ? rowData[3] : rowData[4];
                this.updateDriver(identifier);
            }
        },
        customToolbar: () => {
            return (<CustomToolbar canEdit={canEdit} 
                                role={this.props.user.role} 
                                tabName={this.props.tabName} 
                                dialog={(tabName) => this.loadDialog(tabName)} 
                                openDialog={this.openDialog} 
                                closeDialog={this.closeDialog}
                                updateGrid={this.updateVehicleGrid}
                                isImportButtonDisabled={this.state.isImportButtonDisabled}/>);
        }
    };

    closeDialog = (showAlert, message, severity, keepOpen) => {
        if (this._isMounted) {
            let notification = {
                show: showAlert,
                message: message,
                severity: severity
            };

            if (keepOpen) {
                if (this._isMounted) {
                    this.setState({ open: true, notification: notification });
                    return;
                }
            }

            trackPromise(
                GetData(this.props.tabName, this.props.user?.role)
                    .then(response => {
                        if (response?.success && this._isMounted) {
                            this.setState({ open: false, notification: notification, data: response?.data?.sort(sortData) ?? [], rowToEditId: '' });
                        }
                        else {
                            this.showAlert(true, response.message ?? alertMessages.dataLoadError, alertType.error);
                        }
                    })
                    .catch((error) => {
                        this.showAlert(true, alertMessages.dataLoadError, alertType.error);
                    }),
                Areas.userGrid
            );
        }
    }

    openDialog = () => {
        if (this._isMounted) {
            this.setState({ open: true });
        }
    }

    loadDialog = (tabName) => {
        let row;

        if (this.state.data != null && this.state.data.length !== 0) {
            row = this.state.data.filter(x => x.id === this.state.rowToEditId)[0];
        }

        switch (tabName) {
            case DashboardTabs.BankingDetails:
                return (<UploadDialog open={this.state.open} tabName={this.props.tabName} openDialog={this.openDialog} closeDialog={this.closeDialog} />);
            case DashboardTabs.ServiceProviderVerificationDetails:
                return (<VerificationDetailDialog row={setDialogDetail(row)} open={this.state.open} tabName={this.props.tabName} openDialog={this.openDialog} closeDialog={this.closeDialog} user={this.props.user} />);
            case DashboardTabs.UtsUsers:
                return (<UserDialog row={setDialogUser(row, Roles.Uts)} open={this.state.open} tabName={this.props.tabName} openDialog={this.openDialog} closeDialog={this.closeDialog} hasParent={false} role={Roles.Uts} user={this.props.user} />);
            case DashboardTabs.RegionalCompanies:
                return (<CompanyDialog row={setDialogCompany(row)} open={this.state.open} tabName={this.props.tabName} openDialog={this.openDialog} closeDialog={this.closeDialog} user={this.props.user} />);
            case DashboardTabs.RegionalCompanyUsers:
                return (<UserDialog row={setDialogUser(row, Roles.RegionalCompany)} open={this.state.open} tabName={this.props.tabName} openDialog={this.openDialog} closeDialog={this.closeDialog} hasParent={true} role={Roles.RegionalCompany} user={this.props.user} />);
            case DashboardTabs.Operators:
                return (<UserDialog row={setDialogUser(row, Roles.Operator)} open={this.state.open} tabName={this.props.tabName} openDialog={this.openDialog} closeDialog={this.closeDialog} hasParent={true} role={Roles.Operator} user={this.props.user} />);
            case DashboardTabs.Drivers:
                return (<UserDialog row={setDialogUser(row, Roles.Driver)} open={this.state.open} tabName={this.props.tabName} openDialog={this.openDialog} closeDialog={this.closeDialog} hasParent={true} role={Roles.Driver} user={this.props.user} />);
            default:
                break;
        }
    }

    updateOperator = (userId) => {
        trackPromise(
            updateOperatorEligibility(userId)
                .then(response => {
                    if (response?.success) {
                        if (response?.data) {
                            this.updateGrid();
                        }
                        this.showAlert(true, alertMessages.updateSuccess, alertType.success);                  
                    }else{
                        this.showAlert(true, alertMessages.updateError, alertType.updateError);
                    }
                }),
            Areas.userGrid)
    }

    updateVehicle = (vinNumber) => {
        trackPromise(
            updateVehicleEligibility(vinNumber)
                .then(response => {
                    if (response?.success) {
                        if (response?.data) {
                            this.updateGrid();
                        }
                        this.showAlert(true, alertMessages.updateSuccess, alertType.success);
                    }else{
                        this.showAlert(true, alertMessages.updateError, alertType.updateError);
                    }
                }),
            Areas.userGrid)
    }

    updateDriver= (idNumber) => {
        trackPromise(
            updateDriverEligibility(idNumber)
                .then(response => {
                    if (response?.success) {
                        if (response?.data) {
                            this.updateGrid();
                        }
                        this.showAlert(true, alertMessages.updateSuccess, alertType.success);
                    }else{
                        this.showAlert(true, alertMessages.updateError, alertType.updateError);
                    }
                }),
            Areas.userGrid)
    }

    showAlert = (show, message, severity) => {
        let notification = this.state.notification;
        notification = {
            show: show,
            message: message,
            severity: severity
        };

        this.setState({ notification: notification });
    }

    updateGrid() {
        return GetData(this.props.tabName, this.props.user?.role)
            .then(response => {
                if (!response?.success) {
                    this.showAlert(true, response.message ?? alertMessages.dataLoadError, alertType.error);
                }

                let columns = this.props.columns;

                if (!canEdit(this.props.tabName, this.props.user?.role, "edit") && columns) {
                    columns = columns.filter(col => col.label !== Columns.EditButton.label);
                }

                if (!canViewTsAndCs(this.props.user?.role, this.props.tabName)) {
                    columns = columns.filter(col => col.name !== Columns.HasAcceptedTermsAndConditions.name && col.name !== Columns.HasCreatedPassword.name);
                }

                if (this._isMounted) {
                    this.setState({ columns: columns, data: response.data?.sort(sortData) ?? [] });
                }
            })
            .catch((error) => {
                this.showAlert(true, alertMessages.dataLoadError, alertType.error);
            });
    }

    updateVehicleGrid = () => {
        if (this._isMounted) {
            this.setState({isImportButtonDisabled : true});
            let userIdentier = this.props.user?.idNumber !== '' && this.props.user?.idNumber !== null ? this.props.user?.idNumber : this.props.user?.businessRegistrationNumber;

                trackPromise(
                    updateVehicleData(this.props.user?.role, userIdentier)
                        .then(response => {
                            if (response?.success) {
                                this.showAlert(true, response?.message, alertType.success);
                                this.updateGrid();
                            }else{
                                this.showAlert(true, alertMessages.updateError, alertType.error);
                            }

                            this.setState({isImportButtonDisabled : false});
                        }),
                    Areas.userGrid);
        }
    }

    componentDidMount() {
        this._isMounted = true;

        trackPromise(
            this.updateGrid(),
            Areas.userGrid
        );
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return (
            <div className="h-100">
                <Spinner area={Areas.userGrid} className="h-100 container-fluid" innerClassName="col h-50 text-center" style={{ position: 'absolute' }} />
                <div>
                    {this.loadDialog(this.props.tabName)}
                </div>
                <MUIDataTable
                    data={this.state.data}
                    columns={this.state.columns}
                    options={this.tableOptions}
                    className="h-100"
                />
                <AlertNotification
                    open={this.state.notification.show}
                    alertMessage={this.state.notification.message}
                    severity={this.state.notification.severity}
                    showAlert={this.showAlert}
                />
            </div>
        );
    }
}