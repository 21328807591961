import React from 'react';
import moment from 'moment';
import '../../styles/grid.css';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import { green, red } from '@material-ui/core/colors';
import { formatToLocalNumber } from '../../common/FormHelpers';
import { Columns } from '../../constants/RoleScreenConstants';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.gray,
      color: 'rgba(255,255,255, 0.9)',
      boxShadow: theme.shadows[1],
      fontSize: 15,
    },
  }))(Tooltip)

export const NotificationColumns = [{

    label: Columns.message.label,
    name: Columns.message.name,
    options: {
        display: true
    }
},
{
    label: Columns.PhoneNumber.label,
    name: Columns.PhoneNumber.name,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            if (value) {
                return formatToLocalNumber(value);
            }
            return '';
        }
    }
},
{
    label: Columns.EmailAddress.label,
    name: Columns.EmailAddress.name,
    options: {
        display: true
    }
},
{
    label: Columns.OperatorPhoneNumber.label,
    name: Columns.OperatorPhoneNumber.name,
    // options: {
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //         return value?.operatorPhoneNumber ?? '';
    //     },
    //     display: false
    // }
},
{
    label: Columns.DateAdded.label,
    name: Columns.DateAdded.name,
    type: Columns.DateAdded.type,
     options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            if (value) {
                return moment(value).format('DD MMM YYYY');
            }
            return '';
        }
    }
}
];