import React, { Component, Fragment } from 'react';
import { Route } from 'react-router';
import { Logout } from './Logout'
import { ApplicationPaths, LoginActions, LogoutActions } from './ApiAuthorizationConstants';
import { BaseAuthPage } from '../pages/BaseAuthPage';

export default class ApiAuthorizationRoutes extends Component {

    render() {
        return (
            <Fragment>
                <Route path={ApplicationPaths.LoginCallback} render={() => loginAction(LoginActions.LoginCallback)} />  
                <Route path={ApplicationPaths.Login} render={() => loginAction(LoginActions.Login)} />  
                <Route path={ApplicationPaths.LoginFailed} render={() => loginAction(LoginActions.LoginFailed)} />
                <Route path={ApplicationPaths.Profile} render={() => loginAction(LoginActions.Profile)} />
                <Route path={ApplicationPaths.Register} render={() => loginAction(LoginActions.Register)} />
                <Route path={ApplicationPaths.OTP} render={() => loginAction(LoginActions.OTP)} />
                <Route path={ApplicationPaths.CreatePassword} render={() => loginAction(LoginActions.CreatePassword)} />
                <Route path={ApplicationPaths.LogOut} render={() => logoutAction(LogoutActions.Logout)} />
                <Route path={ApplicationPaths.LogOutCallback} render={() => logoutAction(LogoutActions.LogoutCallback)} />
                <Route path={ApplicationPaths.LoggedOut} render={() => logoutAction(LogoutActions.LoggedOut)} />
            </Fragment>);
    }
}

export function loginAction(name) {
    return (<BaseAuthPage action={name} />);
}

function logoutAction(name) {
    return (<Logout action={name}></Logout>);
}
