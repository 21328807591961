import React, { Component } from 'react';
import { TextField } from '@material-ui/core';
import avatarImg from "../../images/profileIcon.png";
import { Spinner } from '../Spinner'
import { Areas } from '../../constants/areas'
import { formatToLocalNumber, getWelcomeText } from '../../common/FormHelpers';
import utsSelectedIcon from '../../images/uts-solid.png'
import regionalCompanySelectedIcon from '../../images/regionalCompanySelected.svg'
import operatorSelectedIcon from '../../images/operatorSelected.svg'
import driverSelectedIcon from '../../images/driverSelected.svg'
import { Roles } from '../../constants/RoleScreenConstants';

export class UserProfile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: '',
            firstName: '',
            lastName: '',
            idNumber: '',
            phoneNumber: '',
            emailAddress: '',
            requiredInputs: {
                firstName: {
                    error: false,
                    message: ''
                },
                lastName: {
                    error: false,
                    message: ''
                },
                idNumber: {
                    error: false,
                    message: ''
                },
                phoneNumber: {
                    error: false,
                    message: ''
                },
                role: {
                    error: false,
                    message: ''
                },
                parentId: {
                    error: false,
                    message: ''
                }
            }
        }

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        let name = event.target.name;
        this.setState({ [name]: event.target.value });
    }

    componentDidMount() {
        this._isMounted = true;
    }

    getProfilePictureForRole() {
        switch (this.props.user?.role) {
            case Roles.Uts:
                return utsSelectedIcon;
            case Roles.RegionalCompany:
                return regionalCompanySelectedIcon;
            case Roles.Operator:
                return operatorSelectedIcon;
            case Roles.Driver:
                return driverSelectedIcon;
            default:
                return avatarImg;
        }
    }

    render() {
        return (
            <div className={this.props.className}>
                <form className="container-fluid h-100">
                    <div className="row">
                        <Spinner area={Areas.userProfile} className="h-50 container-fluid" innerClassName="col h-50 text-center" style={{ position: 'absolute' }} />
                    </div>
                    <div className="row py-5">
                        <div className="col text-center">
                            <img src={this.getProfilePictureForRole()} className="w-100" style={{ maxWidth: '100px' }} />
                        </div>
                    </div>
                    <div className="row pb-5">
                        <div className="col text-center">
                            <h2>{this.props.user?.firstName} {this.props.user?.lastName}</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="row">
                                <div className="col">
                                    <TextField
                                        autoFocus
                                        required
                                        error={this.state.requiredInputs.firstName.error}
                                        helperText={this.state.requiredInputs.firstName.message}
                                        margin="dense"
                                        name="firstName"
                                        label="First Name"
                                        type="text"
                                        fullWidth
                                        onChange={this.handleChange}
                                        value={this.props.user?.firstName ?? ''}
                                        className="col"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <TextField
                                        autoFocus
                                        required
                                        error={this.state.requiredInputs.lastName.error}
                                        helperText={this.state.requiredInputs.lastName.message}
                                        margin="dense"
                                        name="lastName"
                                        label="Last Name"
                                        type="text"
                                        fullWidth
                                        onChange={this.handleChange}
                                        value={this.props.user?.lastName ?? ''}
                                        className="col"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        required
                                        name={this.props.user?.trafficRegisterNumber && this.props.user?.trafficRegisterNumber !== '' ? 'trafficRegisterNumber' : this.props.user?.idNumber && this.props.user?.idNumber !== '' ? 'idNumber' : 'businessRegistrationNumber'}
                                        label={this.props.user?.trafficRegisterNumber && this.props.user?.trafficRegisterNumber !== '' ? 'Traffic Register Number' : this.props.user?.idNumber && this.props.user?.idNumber !== '' ? 'ID Number' : 'Company Registration Number'}
                                        aria-readonly
                                        variant="filled"
                                        type="text"
                                        fullWidth
                                        onChange={this.handleChange}
                                        defaultValue=' '
                                        value={this.props.user?.trafficRegisterNumber && this.props.user?.trafficRegisterNumber !== '' ? this.props.user?.trafficRegisterNumber : this.props.user?.idNumber && this.props.user?.idNumber !== '' ? this.props.user?.idNumber : this.props.user?.businessRegistrationNumber}
                                        className="col"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        required
                                        error={this.state.requiredInputs.phoneNumber.error}
                                        helperText={this.state.requiredInputs.phoneNumber.message}
                                        name="phoneNumber"
                                        label="Phone Number"
                                        type="text"
                                        fullWidth
                                        onChange={this.handleChange}
                                        value={formatToLocalNumber(this.props.user?.phoneNumber ?? '')}
                                        className="col"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        name="emailAddress"
                                        label="Email Address"
                                        type="email"
                                        fullWidth
                                        onChange={this.handleChange}
                                        value={this.props.user?.emailAddress ?? ''}
                                        className="col"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <TextField
                                        autoFocus
                                        required
                                        error={this.state.requiredInputs.role.error}
                                        helperText={this.state.requiredInputs.role.message}
                                        margin="dense"
                                        name="role"
                                        label="Role"
                                        type="text"
                                        aria-readonly={true}
                                        variant="filled"
                                        fullWidth
                                        value={this.props.user?.role ?? ''}
                                        className="col"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col user-profile-details-overflow">
                                    {this.props.user?.role === Roles.Operator ? 
                                     getWelcomeText() : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}