import { getFromApi, postToApi } from './ApiService';
import { Endpoints } from '../services/ApiServiceConstants';
import authService from '../components/api-authorization/AuthorizeService';
import { Roles } from '../constants/RoleScreenConstants';

export class OperatorService {

    async GetAsync(regionalCompanyId, operatorId, loggedInUserRole) {
        try {
            console.log('Start execution OperatorService.GetAsync');
            
            let url = `${Endpoints.Operator.Get}?`;

            if(regionalCompanyId != null) {
                url += "regionalCompanyId=" + regionalCompanyId + '&'
            }

            if(operatorId != null) {
                url += "userId=" + operatorId
            }

            if(loggedInUserRole === Roles.BlueDotAdmin){
                url = url + '&getTsAndCsInfo=true';
            }

            let token = await this.GetAccessToken();
            return await getFromApi(url, token);
        }
        catch (error) {
            console.log('Error received while running OperatorService.GetAsync', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution OperatorService.GetAsync');
        }
    }

    async GetAllAsync(loggedInUserRole) {
        try {
            console.log('Start execution OperatorService.GetAllAsync');

            let url = `${Endpoints.Operator.Get}`;

            if(loggedInUserRole === Roles.BlueDotAdmin){
                url = url + '?getTsAndCsInfo=true&getNatisInfo=true';
            }

            let token = await this.GetAccessToken();
            return await getFromApi(url, token);
        }
        catch (error) {
            console.log('Error received while running OperatorService.GetAllAsync', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution OperatorService.GetAllAsync');
        }
    }

    async UpdateOperatorEligibility(opertatorId) {
        try {           
            console.log('Start execution OperatorService.UpdateOperatorEligibility');

            opertatorId =`"${opertatorId}"`;
            let url = `${Endpoints.Operator.UpdateOperatorEligibility}`;
            let token = await this.GetAccessToken();

            return await postToApi(url,opertatorId,token ,null);
        }
        catch (error) {
            console.log('Error received while running OperatorService.UpdateOperatorEligibility', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution OperatorService.UpdateOperatorEligibility');
        }
    }

    async GetAllNotificationsAsync() {
        try {
            console.log('Start execution OperatorService.GetAllNotificationsAsync');

            let url = `${Endpoints.Notifications.GetOperatorNotifications}`;
            let token = await this.GetAccessToken();
            return await getFromApi(url, token);
        }
        catch (error) {
            console.log('Error received while running OperatorService.GetAllNotificationsAsync', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution OperatorService.GetAllNotificationsAsync');
        }
    }

    async GetTotalsAsync(regionalCompanyId, isEligibile, isDeleted) {
        try {
            console.log('Start execution OperatorService.GetTotalsAsync');
            
            let url = `${Endpoints.Operator.GetTotals}?regionalCompanyId=${regionalCompanyId ?? ''}&isEligible=${isEligibile ?? ''}&isDeleted=${isDeleted ?? ''}`;
            let token = await this.GetAccessToken();
            return await getFromApi(url, token);
        }
        catch (error) {
            console.log('Error received while running OperatorService.GetTotalsAsync', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution OperatorService.GetTotalsAsync');
        }
    }

    async GetAccessToken(){
        return await authService.getAccessToken();
    }

    ErrorResponse(error){
        return {
            statusText: error.response?.statusText ?? 'Bad Request',
            status: error.response?.status ?? 400,
            success: false,
            message: error.response?.data?.message?.join(', ') ?? error?.message ?? "An error occurred while trying to complete the process, please contact your administrator"
        };
    }
}

const operatorService = new OperatorService();

export default operatorService;