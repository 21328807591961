import axios from 'axios';

async function getFromApi(url, token, isFileDownload) {
    try {
        const config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }

        if(isFileDownload){
            config.responseType = 'blob';
        }
        
        const response = await axios.get(url, config);

        return apiResponse(response);
    }
    catch (error) {
        console.log("API Get error: ", error);
        apiResponse(error);
    }
}

async function postToApi(url, data, token, contentType) {
    try {
        const response = await axios.post(url, data, { 
            headers : {
                'Content-Type': contentType ?? 'application/json',
                'Authorization': 'Bearer ' + token
            }
        });
       
        return apiResponse(response);
    }
    catch (error) {
        console.log("API Post error: ", error);
        return apiResponse(error);
    }
}

async function putToApi(url, data, token) {
    try{
        const response = await axios.put(url, data, { 
            headers : {
                'Authorization': 'Bearer ' + token
            }
        });

        return apiResponse(response);
    }
    catch(error){
        console.log("API Post error: ", error);
        return apiResponse(error);
    }
}

function apiResponse(httpResponse){
    return {
        success: (httpResponse?.status && httpResponse?.status >= 400) || (httpResponse?.response?.status && httpResponse?.response?.status >= 400) ? false : true,
        statusText: (httpResponse?.statusText || httpResponse?.response?.statusText) ?? 'Bad Request',
        status: (httpResponse?.status || httpResponse?.response?.status) ?? 400,
        data: httpResponse?.data ?? httpResponse?.response?.data ?? [],
        message: httpResponse?.data?.message?.join(', ') ?? httpResponse?.response?.data?.message?.join(', ') ?? httpResponse?.response?.message ?? "An error occurred while trying to complete the process, please contact your administrator"
    };
}

export {
    getFromApi,
    postToApi,
    putToApi
}