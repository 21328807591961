import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Overview } from '../Overview';
import { EntityGrid } from '../grids/EntityGrid';
import { OperatorDetail } from '../forms/OperatorDetail';
import { TabBar } from "../TabBar";
import '../../styles/dashboard.css';
import '../../styles/NavMenu.css';
import { Roles, DashboardTabs } from '../../constants/RoleScreenConstants';
import authService from '../api-authorization/AuthorizeService';
import userService from '../../services/UserService';
import { BankingDetail } from '../forms/BankingDetail';
import { OperatorColumns } from '../grids/OperatorColumns';
import { trackPromise } from 'react-promise-tracker';

export class OperatorPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loggedInEntityType: "",
            user: {},
            tabVisibility: {
                showOverview: false,
                showUtsUsers: false,
                showBankingDetails: false,
                showOperatorDetails: false,
                showRegionalCompanies: false,
                showRegionalCompanyUsers: false,
                showOperators: false,
                showDrivers: false,
                showVehicles: false,
                showRoutes: false,
                showNotifications: false
            },
            tabVisibilityLoaded: false,
            dashboardstats: {
                drivers: {
                    registered: 0,
                    approved: 0
                },
                operators: {
                    registered: 0,
                    approved: 0
                },
                vehicles: {
                    registered: 0,
                    approved: 0
                },
                routes: {
                    registered: 0,
                    approved: 0
                }
            }
        }
    }

    manageTabVisibility() {
        let tabsToSee = this.state.tabVisibility;

        switch (this.state.loggedInEntityType) {
            case Roles.BlueDotAdmin:
                tabsToSee.showOverview = true;
                tabsToSee.showOperators = true;
                tabsToSee.showNotifications = true;
                this.setState({ tabVisibility: tabsToSee, tabVisibilityLoaded: true });
                break;
            case Roles.Uts:
                tabsToSee.showOverview = true;
                this.setState({ tabVisibility: tabsToSee, tabVisibilityLoaded: true });
                break;
            case Roles.RegionalCompany:
                tabsToSee.showOverview = true;
                tabsToSee.showOperators = true;
                this.setState({ tabVisibility: tabsToSee, tabVisibilityLoaded: true });
                break;
            case Roles.Operator:
                tabsToSee.showOperatorDetails = true;
                tabsToSee.showBankingDetails = true;
                tabsToSee.showOverview = true;
                this.setState({ tabVisibility: tabsToSee, tabVisibilityLoaded: true });
                break;
            case Roles.Driver:
                tabsToSee.showOperatorDetails = true;
                this.setState({ tabVisibility: tabsToSee, tabVisibilityLoaded: true });
                break;
            default:
                break;
        };
    }

    componentDidMount() {
        trackPromise(
            authService.getUser()
                .then(userProfile => {
                    userService.GetUserByIdAsync(userProfile?.sub)
                        .then(response => {
                            if(response?.success){
                                if (response?.data) {
                                    let user = response?.data;
                                    let role = user?.role ?? userProfile?.role;
                                    this.setState({ loggedInEntityType: role, user: user });
                                    this.manageTabVisibility();
                                }
                            }
                        }
                    );
                }
            )
         );
    }

    tabBarChildren() {
        let childrenTabs = [];
        let tabCounter = 0;

        if (this.state.tabVisibility.showOverview) {
            childrenTabs.push(
                <div index={tabCounter} label={DashboardTabs.Overview}>
                    <Overview loggedInEntity={DashboardTabs.Overview} dashboardstats={this.state.dashboardstats} />
                </div>
            );

            tabCounter++;
        }

        if (this.state.tabVisibility.showOperatorDetails) {
            childrenTabs.push(
                <div index={tabCounter} label={DashboardTabs.OperatorDetails}>
                    <OperatorDetail />
                </div>
            );

            tabCounter++;
        }

        if (this.state.tabVisibility.showOperators) {
            childrenTabs.push(
                <div index={tabCounter} label={DashboardTabs.Operators} >
                    <EntityGrid user={this.state.user} columns={OperatorColumns} tabName={DashboardTabs.Operators} />
                </div>
            );

            tabCounter++;
        }

        if (this.state.tabVisibility.showBankingDetails) {
            childrenTabs.push(
                <div index={tabCounter} label={DashboardTabs.BankingDetails}>
                    <BankingDetail user={this.state.user} role={this.state.loggedInEntityType} />
                </div>
            );

            tabCounter++;
        }

        //if (this.state.tabVisibility.showNotifications) {
        //    childrenTabs.push(
        //        <div index={tabCounter} label={DashboardTabs.OperatorNotifications} key={DashboardTabs.OperatorNotifications}>
        //            <NotificationGrid user={this.state.user} columns={OperatorNotificationsColumns} tabName={DashboardTabs.OperatorNotifications} />
        //        </div>
        //    );

        //    tabCounter++;
        //}

        if (childrenTabs.length !== 0) {
            return (<TabBar>{childrenTabs}</TabBar>)
        } else if (this.state.tabVisibilityLoaded) {
            switch (this.state.loggedInEntityType) {
                case Roles.Driver:
                    return <Redirect to="/driver" />;
            }
        }
    }


    render() {
        return (
            <div className="h-100">
                { this.tabBarChildren()}
            </div>
        );
    }
}