import '../../styles/grid.css';
import { formatToLocalNumber } from '../../common/FormHelpers';
import { Columns } from '../../constants/RoleScreenConstants';
import { Button } from '@material-ui/core';

export const RegionalCompanyColumns = [{
    label: Columns.CompanyName.label,
    name: Columns.CompanyName.name
},
{
    label: Columns.PhoneNumber.label,
    name: Columns.PhoneNumber.name,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            if (value) {
                return formatToLocalNumber(value);
            }
            return '';
        }
    }
},
{
    label: Columns.EmailAddress.label,
    name: Columns.EmailAddress.name
},
{
    label: Columns.BusinessRegistrationNumber.label,
    name: Columns.BusinessRegistrationNumber.name
},
{
    label: Columns.ControllingRegion.label,
    name: Columns.ControllingRegion.name,
    options: {
        display: false
    }
},
{
    label: Columns.EditButton.label,
    name: Columns.EditButton.name,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            return <Button value={value}>{'Edit'}</Button>;
        },
        download: false
    }
}];