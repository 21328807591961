import { getFromApi, postToApi} from './ApiService';
import { Endpoints } from '../services/ApiServiceConstants';
import authService from '../components/api-authorization/AuthorizeService';
import { Roles } from '../constants/RoleScreenConstants';

export class DriverService {

    async GetAsync(operatorId, driverId, loggedInUserRole) {
        try {
            console.log('Start execution DriverService.GetAsync');
            
            let url = `${Endpoints.Driver.Get}?driverId=${driverId ?? ''}&operatorId=${operatorId ?? ''}`;

            let token = await this.GetAccessToken();
            return await getFromApi(url, token);
        }
        catch (error) {
            console.log('Error received while running DriverService.GetAsync', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution DriverService.GetAsync');
        }
    }

    async GetAllAsync(loggedInUserRole) {
        try {
            console.log('Start execution DriverService.GetAllAsync');

            let url = `${Endpoints.Driver.Get}?getNatisInfo=false`;

            let token = await this.GetAccessToken();
            return await getFromApi(url, token);
        }
        catch (error) {
            console.log('Error received while running DriverService.GetAllAsync', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution DriverService.GetAllAsync');
        }
    }

    async GetAllWithNatisInfoAsync(loggedInUserRole) {
        try {
            console.log('Start execution DriverService.GetAllWithNatisInfoAsync');

            let url = `${Endpoints.Driver.Get}?getNatisInfo=true`;

            let token = await this.GetAccessToken();
            return await getFromApi(url, token);
        }
        catch (error) {
            console.log('Error received while running DriverService.GetAllWithNatisInfoAsync', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution DriverService.GetAllWithNatisInfoAsync');
        }
    }

    async GetAllNotificationsAsync() {
        try {
            console.log('Start execution DriverService.GetAllNotificationsAsync');

            let url = `${Endpoints.Notifications.GetDriverNotifications}`;
            let token = await this.GetAccessToken();
            return await getFromApi(url, token);
        }
        catch (error) {
            console.log('Error received while running DriverService.GetAllNotificationsAsync', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution DriverService.GetAllNotificationsAsync');
        }
    }

    async GetTotalsAsync(operatorId, isEligibile, isDeleted) {
        try {
            console.log('Start execution DriverService.GetTotalsAsync');

            let opId = operatorId ?? '';
            let eligibility = isEligibile ?? '';
            let deleted = isDeleted ?? '';
            let url = `${Endpoints.Driver.GetTotals}?operatorId=${opId}&isEligible=${eligibility}&isDeleted=${deleted}`;
            let token = await this.GetAccessToken();
            return await getFromApi(url, token);
        }
        catch (error) {
            console.log('Error received while running DriverService.GetTotalsAsync', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution DriverService.GetTotalsAsync');
        }
    }

    async UpdateDriverEligibility(idNumber) {
        try {           
            console.log('Start execution DriverService.UpdateDriverEligibility');
            idNumber =`"${idNumber}"`
            let url = `${Endpoints.Driver.UpdateDriverEligibility}`;
            let token = await this.GetAccessToken();

            return await postToApi(url,idNumber,token ,null);
        }
        catch (error) {
            console.log('Error received while running DriverService.UpdateDriverEligibility', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution DriverService.UpdateDriverEligibility');
        }
    }
    async GetAccessToken() {
        return await authService.getAccessToken();
    }

    ErrorResponse(error){
        return {
            statusText: error.response?.statusText ?? 'Bad Request',
            status: error.response?.status ?? 400,
            success: false,
            message: error.response?.data?.message?.join(', ') ?? error?.message ?? "An error occurred while trying to complete the process, please contact your administrator"
        };
    }
}

const driverService = new DriverService();

export default driverService;