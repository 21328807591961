import '../styles/grid.css';

export const Roles = {
    Admin: "Admin",
    BlueDotAdmin: "Blue Dot Admin",
    Driver: "Driver",
    Operator: "Operator",
    RegionalCompany: "Regional Company",
    Uts: "Uts",
    Vehicle: "Vehicle"
};

export const RolePagePermissions = {
    Admin: {
        uts: true,
        regionalcompany: true,
        operator: true,
        driver: true,
        vehicle: true
    },
    BlueDotAdmin: {
        verificationdetail: true,
        uts: true,
        regionalcompany: true,
        operator: true,
        driver: true,
        vehicle: true
    },
    Uts: {
        uts: true,
        regionalcompany: true,
        operator: true,
        driver: true,
        vehicle: true
    },
    RegionalCompany: {
        regionalcompany: true,
        operator: true,
        driver: true,
        vehicle: true
    },
    Operator: {
        regionalcompany: true,
        operator: true,
        driver: true,
        vehicle: true
    },
    Driver: {
        operator: true,
        driver: true
    }
}

export const DashboardTabs = {
    Overview: "Overview",
    UtsUsers: "Uts Users",
    RegionalCompanies: "Regional Companies",
    RegionalCompanyDetails: "Regional Company Details",
    BankingDetails: "Bank Account Details",
    OperatorDetails: "Operator Details",
    RegionalCompanyUsers: "Regional Company Users",
    Drivers: "Drivers",
    DriverDetails: "Driver Details",
    Vehicles: "Vehicles",
    Operators: "Operators",
    Uts: "Uts",
    Routes: "Routes",
    ServiceProviderVerificationDetails: "MAAA Account Details",
    DriverNotifications: "Driver Notifications",
    UtsNotifications: "Uts Notifications",
    OperatorNotifications: "Operator Notifications",
    RegionalCompanyNotifications: "Notifications"
}

export const Columns = {
    FirstName: {
        label:'First Name',
        name: 'firstName'
    },
    LastName: {
        label:'Last Name',
        name: 'lastName'
    },
    EntityName: {
        label:'Participant Name',
        name: 'entity'
    },
    FleetNumber: {
        label: 'Fleet Number',
        name: 'installationStatus'
    },
    ControllingRegion: {
        label:'Controlling Region',
        name: 'controllingRegion'
    },
    IdNumber: {
        label:'ID Number',
        name: 'idNumber'
    },
    InstallationStatus: {
        label:'Installation Status',
        name: 'installationStatus'
    },
    TRN: {
        label:'Traffic Register Number',
        name: 'trafficRegisterNumber'
    },
    OperatorFullName: {
        label:'Operator Full Name',
        name: 'operator'
    },
    OperatorPhoneNumber: {
        label:'Operator Phone Number',
        name: 'operator'
    },
    PhoneNumber: {
        label:'Phone Number',
        name: 'phoneNumber'
    },
    EmailAddress: {
        label:'Email Address',
        name: 'emailAddress'
    },
    TrainingStatus: {
        label: 'Training Status',
        name: 'trainingStatus',
    },
    TrainingCompletionDate: {
        label: 'Training Completion Date',
        name: 'trainingStatus',
        type: 'dateTime'
    },
    TagNumber: {
        label: 'Tag Number',
        name: 'trainingStatus'
    },
    Status: {
        label:'Status',
        name: 'eligibility'
    },
    DateAdded: {
        label:'Date Added',
        name: 'dateAdded',
        type: 'dateTime'
    },
    DateModified: {
        label:'Date Modified',
        name: 'dateModified',
        type: 'dateTime'
    },
    VinNumber: {
        label:'VIN number',
        name: 'vin'
    },
    LicencePlateNumber: {
        label:'Licence Plate Number',
        name: 'licencePlateNumber'
    },
    BusinessRegistrationNumber: {
        label:'Company Registration Number',
        name: 'businessRegistrationNumber'
    },
    CompanyName: {
        label: 'Name',
        name: 'name'
    },
    ParentCompany: {
        label:'Company Name',
        name: 'regionalCompany'
    },
    DateOfEligibility: {
        label:'Date Of Eligibility',
        name: 'eligibility',
        type: 'dateTime'
    },
    DateOfIneligibility: {
        label:'Date Of Ineligibility',
        name: 'eligibility',
        type: 'dateTime'
    },
    IneligibilityReasons: {
        label:'Ineligibility Reasons',
        name: 'eligibility'
    },
    EntityReferenceType: {
        label: 'Reference Type',
        name: 'entityReference'
    },
    EntityReferenceNumber: {
        label: 'Reference Number',
        name: 'entityReference'
    },
    MaaaNumber: {
        label: 'Maaa Number',
        name: 'maaaNumber'
    },
    BankAccountHolder: {
        label: 'Bank Account Holder',
        name: 'bankAccount'
    },
    BankName: {
        label: 'Bank',
        name: 'bankAccount'
    },
    BankAccountType: {
        label: 'Account Type',
        name: 'bankAccount'
    },
    BankAccountNumber: {
        label: 'Account Number',
        name: 'bankAccount'
    },
    BranchCode: {
        label: 'Branch Code',
        name: 'bankAccount'
    },
    VatNumber: {
        label: 'Vat Number',
        name: 'vatNumber'
    },
    message: {
        label: 'Message',
        name: 'message'
    },
    id: {
        label:'user ID',
        name: 'id'
    },
    IsAlsoDriver: {
        label:'Is Also Driver',
        name: 'isAlsoDriver'
    },
    IsEligibleAsDriver: {
        label:'Is Eligible As Driver',
        name: 'eligibilityAsDriver'
    },
     PrDPExpiryDate: {
         label:'PrDP Expiry Date',
         name: 'prDPExpiryDate'
    },
    VehicleLicenceExpiryDate: {
         label:'Licence Disk Expiry Date',
         name: 'vehicleLicenceExpiryDate'
     },
    IneligibilityReasonsAsDriver: {
        label:'Ineligibility Reasons As Driver',
        name: 'eligibilityAsDriver'
    },
    HasAcceptedTermsAndConditions: {
        label:'Has Accepted Ts and Cs',
        name: 'hasAcceptedTsAndCs'
    },
    HasCreatedPassword: {
        label:'Has Created Password',
        name: 'hasCreatedPassword'
    },
    UpdateEligibility: {
        label:'Update Eligibility',
        name: 'updateEligibility'
    },
    EditButton: {
        label:'Action',
        name: 'id'
    }
};


export const NotificationColumns = {
    userFirstName: {
        label:'First Name',
        name: 'userFirstName'
    },
    userLastName: {
        label:'Last Name',
        name: 'userLastName'
    },
    notificationViaPhoneNumber: {
        label:'Via phone number',
        name: 'notificationViaPhoneNumber'
    },
    notificationViaEmailAddress: {
        label:'Via email address',
        name: 'notificationViaEmailAddress'
    },
    notificationPhoneNumber: {
        label:'Sent to',
        name: 'notificationPhoneNumber'
    },
    notificationMessage: {
        label:'Message',
        name: 'notificationMessage'
    },
    notificationEmail: {
        label:'Notification Email',
        name: 'notificationEmail'
    },
    notificationDateSent: {
        label:'Date Sent',
        name: 'notificationDateSent'
    },
    userEmailAddress: {
        label:'User Email Address',
        name: 'userEmailAddress'
    },
    userIdNumber: {
        label:'Id number',
        name: 'userIdNumber'
    },
    userEmailAddress: {
        label:'User Email Address',
        name: 'userEmailAddress'
    },
    userPhoneNumber: {
        label:'User Phone Number',
        name: 'userPhoneNumber'
    },
    userRole: {
        label:'Role',
        name: 'userRole'
    },
    userTrafficRegisterNumber: {
        label:'Traffic register Number',
        name: 'userTrafficRegisterNumber'
    },
    id: {
        label:'User ID',
        name: 'userId'
    }
};

export const MasterDataType = {
    Bank: 'Bank',
    BankAccountType: 'BankAccountType'
}