import React, { Component } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import { uploadData } from '../../common/FormHelpers';
import { alertMessages, alertType } from '../AlertNotification';
import { trackPromise } from 'react-promise-tracker';
import { Areas } from '../../constants/areas';
import { Spinner } from '../Spinner';
import { DashboardTabs } from '../../constants/RoleScreenConstants';

export class UploadDialog extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            file: null,
            shouldDisableButton: false
        }
    }

    uploadFile = () => {
        this.setState({ shouldDisableButton: true });

        trackPromise(
            uploadData(DashboardTabs.BankingDetails, this.state.file)
                .then(response => {
                    this.setState({ shouldDisableButton: false });

                    if (!response?.success) {
                        this.props.closeDialog(true, response.message ?? alertMessages.dataLoadError, alertType.error);
                        return;
                    }

                    this.props.closeDialog(true, alertMessages.saveSuccess, alertType.success);
                })
                .catch((error) => {
                    this.setState({ shouldDisableButton: false });
                    this.props.closeDialog(true, alertMessages.dataLoadError, alertType.error);
                }),
            Areas.userGrid
        );
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return (
            <Dialog open={this.props.open}>
                <DialogTitle id="form-dialog-title">Upload Bank Account Details</DialogTitle>
                <DialogContent>
                    <div className="row justify-content-around">
                        <Spinner area={Areas.dialog} className="h-50 container-fluid" innerClassName="col h-100 text-center" style={{ position: 'absolute' }} />
                    </div>
                    <form>
                        <TextField
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            type="file"
                            onChange={(e) => 
                                this.setState({file: e.target.files[0]})
                            }
                        />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.props.closeDialog(false, '', alertType.success)} color="primary">
                        Cancel
                    </Button>
                    <Button type="button" onClick={this.uploadFile} color="primary" disabled={this.state.shouldDisableButton}>
                        Upload
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}