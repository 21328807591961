export const Areas = {
    userProfile: 'user-profile-area',
    driverStats: 'driver-stats',
    operatorStats: 'operator-stats',
    vehicleStats: 'vehicle-stats',
    regionalCompanyDetail: 'regional-company-detail',
    operatorDetail: 'operator-detail',
    driverDetail: 'driver-detail',
    bankingDetail: 'banking-detail',
    userGrid: 'user-grid',
    dialog: 'dialog',
    auth: 'auth'
  };
  