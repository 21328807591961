import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab/';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';

export default class AlertNotification extends Component {
  constructor(props){
    super(props);
    this.state ={
      open: false
    }
  }

  useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  }));

  setOpen = (viewState) => {
    this.setState({open: viewState});
  };

  handleClose = () => {
    this.props.showAlert(false, '', '');
  };
  
  render() {
    return (
      <div className={this.useStyles.root}>
        <Snackbar 
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={this.props.open} 
        autoHideDuration={6000} 
        onClose={this.handleClose}>
        <Alert
          variant="filled"
          open={this.props.open}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                this.handleClose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          severity={this.props.severity}
        >
          <AlertTitle>{this.GetAlertTitle(this.props.severity)}</AlertTitle>
          {this.props.alertMessage}
        </Alert>
      </Snackbar >
      </div>
    );
  }
  
  GetAlertTitle(severity){
      switch(severity){
          case(alertType.error):
              return "Error";
          case(alertType.warning):
              return "Warning";
          case(alertType.info):
              return "Info";
          case(alertType.success):
              return "Success";
          default:
              return "";
      }
  }
}
  
export const alertType = {
    error: "error",
    warning: "warning",
    info: "info",
    success: "success"
}

export const alertMessages = {
    createPasswordError: "Unable to create password",
    otpVerifyError: "Unable to verify OTP",
    signupError: "Unable to sign up",
    deleteSuccess: "Successfully deleted selected data",
    deleteError:"Unable to delete selected data",
    saveSuccess: "Successfully saved data",
    saveError: "Unable to save data",
    updateSuccess: "Successfully updated data",
    updateError: "Unable to update data",
    dataLoadSuccess: "Successfully loaded data",
    dataLoadError: "Unable to load requested data",
    invalidFormError: "Some of the fields on this form are invalid",
    bankingDetailsError: "Unable to retrieve Banking Details",
    messageSent: "Message was sent successfully",
    messageError: "Message was not sent successfully",
}