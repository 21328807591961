import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Overview } from '../Overview';
import { EntityGrid } from '../grids/EntityGrid';
import { BankingDetail } from '../forms/BankingDetail';
import { TabBar } from "../TabBar";
import '../../styles/dashboard.css';
import '../../styles/NavMenu.css';
import { Roles, DashboardTabs } from '../../constants/RoleScreenConstants';
import authService from '../api-authorization/AuthorizeService';
import userService from '../../services/UserService';
import { UtsColumns  } from '../grids/UtsColumns';
import { UtsNotificationColumns  } from '../grids/UtsNotificationsColumns';
import { NotificationGrid } from '../grids/NotificationsGrid';

export class UtsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loggedInEntityType: "",
            user: {},
            tabVisibility: {
                showOverview: false,
                showUtsUsers: false,
                showBankingDetails: false,
                showRegionalCompanyDetails: false,
                showRegionalCompanies: false,
                showRegionalCompanyUsers: false,
                showOperators: false,
                showDrivers: false,
                showVehicles: false,
                showRoutes: false,
                showNotifications: false
            },
            tabVisibilityLoaded: false,
            dashboardstats: {
                drivers: {
                    registered: 0,
                    approved: 0
                },
                operators: {
                    registered: 0,
                    approved: 0
                },
                vehicles: {
                    registered: 0,
                    approved: 0
                },
                routes: {
                    registered: 0,
                    approved: 0
                }
            }
        }
    }

    manageTabVisibility() {
        let tabsToSee = this.state.tabVisibility;

        switch (this.state.loggedInEntityType) {
            case Roles.BlueDotAdmin:
                tabsToSee.showOverview = true;
                tabsToSee.showUtsUsers = true;
                tabsToSee.showNotifications = true;
                this.setState({ tabVisibility: tabsToSee, tabVisibilityLoaded: true });
                break;
            case Roles.Uts:
                tabsToSee.showOverview = true;
                tabsToSee.showUtsUsers = true;
                tabsToSee.showBankingDetails = true;
                this.setState({ tabVisibility: tabsToSee, tabVisibilityLoaded: true });
                break;
            default:
                this.setState({ tabVisibilityLoaded: true });
                break;
        };
    }

    componentDidMount() {
        authService.getUser()
            .then(userProfile => {
                userService.GetUserByIdAsync(userProfile?.sub)
                    .then(response => {
                        if(response?.success){
                            if (response?.data) {
                                let user = response?.data;
                                let role = user?.role ?? userProfile?.role;
                                this.setState({ loggedInEntityType: role, user: user });
                                this.manageTabVisibility();
                            }
                        }
                    });
            });
    }

    tabBarChildren() {
        let childrenTabs = [];
        let tabCounter = 0;

        if (this.state.tabVisibility.showOverview) {
            childrenTabs.push(
                <div index={tabCounter} label={DashboardTabs.Overview} key={DashboardTabs.Overview}>
                    <Overview loggedInEntity={DashboardTabs.Overview} dashboardstats={this.state.dashboardstats} />
                </div>
            );

            tabCounter++;
        }

        if (this.state.tabVisibility.showUtsUsers) {
            childrenTabs.push(
                <div index={tabCounter} label={DashboardTabs.UtsUsers} key={DashboardTabs.UtsUsers}>
                    <EntityGrid user={this.state.user} columns={UtsColumns} tabName={DashboardTabs.UtsUsers} />
                </div>
            );

            tabCounter++;
        }

        if (this.state.tabVisibility.showBankingDetails) {
            childrenTabs.push(
                <div index={tabCounter} label={DashboardTabs.BankingDetails} key={DashboardTabs.BankingDetails}>
                    <BankingDetail user={this.state.user} role={this.state.loggedInEntityType} />
                </div>
            );

            tabCounter++;
        }

        if (this.state.tabVisibility.showNotifications) {
            childrenTabs.push(
                <div index={tabCounter} label={DashboardTabs.UtsNotifications} key={DashboardTabs.UtsNotifications}>
                    <NotificationGrid user={this.state.user} columns={UtsNotificationColumns} tabName={DashboardTabs.UtsNotifications} />
                </div>
            );

            tabCounter++;
        }

        if (childrenTabs.length !== 0) {
            return (<TabBar>{childrenTabs}</TabBar>)
        } else if (this.state.tabVisibilityLoaded) {
            switch (this.state.loggedInEntityType) {
                case Roles.RegionalCompany:
                    return <Redirect to="/regionalcompany" />;
                case Roles.Operator:
                    return <Redirect to="/operator" />;
                case Roles.Driver:
                    return <Redirect to="/driver" />;
            }
        }
    }

    render() {
        return (
            <div className="h-100">
                { this.tabBarChildren()}
            </div>
        );
    }
}