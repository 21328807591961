import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import gif from "../images/BlueDotLogoRotating.gif";

export const Spinner = (props) => {
  let { promiseInProgress } = usePromiseTracker({ area: props.area, delay: 500 });

  promiseInProgress = props.forceDisplay ?? promiseInProgress;

  return (
    promiseInProgress && (
      <div className={props.className} style={props.style}>
        <div className="row h-100 justify-content-around align-items-center">
          <div className={props.innerClassName}>
            <img className="h-100" src={gif} />
          </div>
        </div>
      </div>
    )
  );
};