import React, { Component } from 'react';
import authService from '../api-authorization/AuthorizeService';
import OtpInput from 'react-otp-input';
import { Grid } from '@material-ui/core';

export class OtpPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            otp: ''
        };
    }

    async resendOtp(user) {
        await authService.firstTimeSignIn(user);
    }

    handleChange = otp => this.setState({ otp });

    styles = {
        root: {
            background: "rgba(196.00000351667404, 196.00000351667404, 196.00000351667404, 1)",
            height: 55,
            width: 55
        },
        input: {
            color: "white"
        }
    };

    render() {
        return (
            <div className="form-group">
                <h2 className="text-white text-center">We’ve sent an OTP to the number {this.props.user.PhoneNumber}</h2>
                <br />
                <Grid
                    container
                    justify="center"
                    direction="column"
                    alignItems="center">
                    <OtpInput
                        value={this.state.otp}
                        onChange={this.handleChange}
                        numInputs={4}
                        separator={<span>-</span>}
                        inputStyle={this.styles.root}
                    />
                </Grid>
                {/* <div className="row auth-page-otp-input-boxes">
            <input name="OtpOne" className="auth-page-otp-input-box col-md-3" style={{ left: 20 }}/>
            <input name="OtpTwo" className="auth-page-otp-input-box col-md-3" style={{ left: 140 }}/>
            <input name="OtpThree" className="auth-page-otp-input-box col-md-3" style={{ left: 260}}/>
            <input name="OtpFour" className="auth-page-otp-input-box col-md-3" style={{ left: 380 }}/>
        </div> */}
                <br />
                <div className="col-sm-12 text-right text-white">
                    <u>
                        <span style={{ cursor: 'pointer' }} onClick={() => this.resendOtp(this.props.user)}>Resend OTP</span>
                    </u>
                </div>
            </div>);
    }
}