import React, { Component } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { TextField , FormControlLabel,Checkbox} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressCard } from '@fortawesome/free-regular-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import {TermsAndConditionsConstants,EnviromentConstants} from '../../services/ApiServiceConstants';
import operatorService from '../../services/OperatorService';
import { Areas } from '../../constants/areas';
import moment from 'moment';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select' ;
export class OperatorDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            baseWebUrl:'',
            operatorDetails:
            {
                user: {
                    firstName: '',
                    lastName: '',
                    businessRegistrationNumber: '',
                    idNumber: '',
                    phoneNumber: '',
                    emailAddress: ''
                },
                operator: {
                    isEligible: false,
                    ineligibilityReasons: [],
                    ineligibilityReasonsAsDriver: [],
                    dateOfEligibility: null,
                    dateOfIneligibility: null,
                    isAlsoDriver: null,
                    isEligibleAsDriver: null
                }
            }
        }
    }

    IneligibilityReasons = () => {
        let operatorObject = this.state.operatorDetails.operator.ineligibilityReasons;

        if (operatorObject != null && operatorObject.length >= 1) {
            return (
                <Select
                    name="ineligibilityReasons"
                    type="text"
                    fullWidth
                    displayEmpty
                    className="col"
                >
                    <InputLabel htmlFor="select">Reason of In-Eligibility as an Operator</InputLabel>
                    {operatorObject?.map((reason) => (
                        <MenuItem key={reason} value={reason}>
                            {reason}
                        </MenuItem>
                    ))}
                </Select>
            );
        };
    }

    IneligibilityReasonsAsDriver = () => {
        let operatorObject = this.state.operatorDetails.operator.ineligibilityReasonsAsDriver;

        if (operatorObject != null && operatorObject.length >= 1) {
            return (
                <Select
                    name="ineligibilityReasonsAsDriver"
                    type="text"
                    fullWidth
                    displayEmpty
                    className="col"
                >
                    <InputLabel htmlFor="select">Reason of not Pre-Aproval as a Bluedot Driver</InputLabel>
                    {operatorObject?.map((reason) => (
                        <MenuItem key={reason} value={reason}>
                            {reason}
                        </MenuItem>
                    ))}
                </Select>
            );
        };
    }

    getBaseUrl()
    {
        this.state.baseWebUrl = "";
        var enviroment = process.env.NODE_ENV;
       
        switch(enviroment) {
            case EnviromentConstants.development:
                this.state.baseWebUrl = TermsAndConditionsConstants.DevBaseUrl;
              break;
            case EnviromentConstants.Staging:
                this.state.baseWebUrl = TermsAndConditionsConstants.StagingBaseUrl;
              break;
              case EnviromentConstants.Production:
                this.state.baseWebUrl = TermsAndConditionsConstants.ProductionBaseUrl;
              break;
            default:
                this.state.baseWebUrl = TermsAndConditionsConstants.ProductionBaseUrl;
          }
    }

    componentDidMount() {
        trackPromise(
            operatorService.GetAllAsync()
                .then(response => {
                    if (response.success === true) {
                        if (response.data != null && response.data.length !== 0) {
                            let operatorDetails = this.state.operatorDetails;

                            operatorDetails.user.firstName = response.data[0].user.firstName;
                            operatorDetails.user.lastName = response.data[0].user.lastName;
                            operatorDetails.user.businessRegistrationNumber = response.data[0].user.businessRegistrationNumber;
                            operatorDetails.user.idNumber = response.data[0].user.idNumber;
                            operatorDetails.user.emailAddress = response.data[0].user.emailAddress;
                            operatorDetails.user.phoneNumber = response.data[0].user.phoneNumber;

                            if (response.data[0].operator.isAlsoDriver) 
                            {
                                operatorDetails.operator.isAlsoDriver = response.data[0].operator.isAlsoDriver;
                            }
                            else
                            {
                                operatorDetails.operator.isAlsoDriver = false;
                            }

                            if (response.data[0].operator.isEligibleAsDriver)
                            {
                                operatorDetails.operator.isEligibleAsDriver = response.data[0].operator.isEligibleAsDriver;
                            }
                            else
                            {
                                operatorDetails.operator.isEligibleAsDriver = false;
                            }

                            if (response.data[0].operator.isAlsoDriver) 
                            {
                                operatorDetails.operator.isAlsoDriver = response.data[0].operator.isAlsoDriver;
                            }
                            else 
                            {
                                operatorDetails.operator.isAlsoDriver = false;
                            }

                            if (response.data[0].operator.isEligibleAsDriver) 
                            {
                                operatorDetails.operator.isEligibleAsDriver = response.data[0].operator.isEligibleAsDriver;
                            }
                            else
                            {
                                operatorDetails.operator.isEligibleAsDriver = false;
                            }

                            operatorDetails.operator.isEligible = response.data[0].operator.isEligible;
                            operatorDetails.operator.ineligibilityReasonsAsDriver = response.data[0].operator.ineligibilityReasonsAsDriver;
                            operatorDetails.operator.ineligibilityReasons = response.data[0].operator.ineligibilityReasons;
                            operatorDetails.operator.dateOfEligibility = response.data[0].operator.dateOfEligibility;
                            operatorDetails.operator.dateOfIneligibility = response.data[0].operator.dateOfIneligibility;

                            this.setState({ operatorDetails: operatorDetails });
                        }
                    }
                })
                .catch(error => { }),
            Areas.operatorDetail
        );

    }

    render() {
        return (
            <div className="container-fluid p-0 h-100">
                <div className="row card-border-radius bg-white">
                    <div className="col-md-6">
                        <div className="row py-5 justify-content-around">
                            <div className="col-sm-8">
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    name="name"
                                    label="First Name"
                                    type="text"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FontAwesomeIcon icon={faAddressCard} />
                                            </InputAdornment>
                                        ),
                                        readOnly: true
                                    }}
                                    value={this.state.operatorDetails.user.firstName}
                                    className="col"
                                />
                            </div>
                        </div>
                        <div className="row py-5 justify-content-around">
                            <div className="col-sm-8">
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    name="lastName"
                                    label="Last Name"
                                    type="text"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FontAwesomeIcon icon={faAddressCard} />
                                            </InputAdornment>
                                        ),
                                        readOnly: true
                                    }}
                                    value={this.state.operatorDetails.user.lastName}
                                    className="col"
                                />
                            </div>
                        </div>
                        <div className="row py-5 justify-content-around">
                            <div className="col-sm-8">
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    name={this.state.operatorDetails.user.idNumber && this.state.operatorDetails.user.idNumber !== '' ? "idNumber" : "businessRegistrationNumber"}
                                    label={this.state.operatorDetails.user.idNumber && this.state.operatorDetails.user.idNumber !== '' ? "ID Number" : "Company Registration Number"}
                                    type="text"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FontAwesomeIcon icon={faAddressCard} />
                                            </InputAdornment>
                                        ),
                                        readOnly: true
                                    }}
                                    value={this.state.operatorDetails.user.idNumber && this.state.operatorDetails.user.idNumber !== '' ? this.state.operatorDetails.user.idNumber : this.state.operatorDetails.user.businessRegistrationNumber}
                                    className="col"
                                />
                            </div>
                        </div>
                        <div className="row py-5 justify-content-around">
                            <div className="col-sm-8">
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    name="phoneNumber"
                                    label="Contact Number"
                                    type="text"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FontAwesomeIcon icon={faPhone} />
                                            </InputAdornment>
                                        ),
                                        readOnly: true
                                    }}
                                    value={this.state.operatorDetails.user.phoneNumber}
                                    className="col"
                                />
                            </div>
                        </div>
                        <div className="row py-5 justify-content-around">
                            <div className="col-sm-8">
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    name="emailAddress"
                                    label="Email Address"
                                    type="text"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FontAwesomeIcon icon={faAddressCard} />
                                            </InputAdornment>
                                        ),
                                        readOnly: true
                                    }}
                                    value={this.state.operatorDetails.user.emailAddress}
                                    className="col"
                                />
                            </div>
                            <div className="col-sm-8">
                                 As an Operator I agree to the <a href={this.state.baseWebUrl + "/operatortsandcs"}> Operator Terms & Conditions </a>  
                                <br></br>
                                As a  Driver I agree to the <a href={this.state.baseWebUrl + "/drivertsandcs"}> Driver Terms & Conditions</a>  
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row py-5 justify-content-around">
                            <div className="col-sm-8">
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    name="isEligible"
                                    label="Is Blue Dot Approved"
                                    type="text"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FontAwesomeIcon icon={faAddressCard} />
                                            </InputAdornment>
                                        ),
                                        readOnly: true
                                    }}
                                    value={this.state.operatorDetails.operator.isEligible ? 'Yes' : 'No'}
                                    className="col"
                                />
                            </div>
                        </div>
                        <div className="row py-5 justify-content-around">
                            <div className="col-sm-8">
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    name="dateOfEligibility"
                                    label="Date of Approval"
                                    type="text"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FontAwesomeIcon icon={faAddressCard} />
                                            </InputAdornment>
                                        ),
                                        readOnly: true
                                    }}
                                    value={this.state.operatorDetails.operator.dateOfEligibility == null ? '' : moment(this.state.operatorDetails.operator.dateOfEligibility).format('DD MMM YYYY')}
                                    className="col"
                                />
                            </div>
                        </div>
                        <div className="row py-5 justify-content-around">
                            <div className="col-sm-8">
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    name="dateOfIneligibility"
                                    label="Date of Disapproval"
                                    type="text"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FontAwesomeIcon icon={faAddressCard} />
                                            </InputAdornment>
                                        ),
                                        readOnly: true
                                    }}
                                    value={this.state.operatorDetails.operator.dateOfIneligibility == null ? '' : moment(this.state.operatorDetails.operator.dateOfIneligibility).format('DD MMM YYYY')}
                                    className="col"
                                />
                            </div>
                        </div>
                        <div className="row py-5 justify-content-around">
                            <div className="col-sm-8">
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    name="isEligibleAsDriver"
                                    label="is also a Driver"
                                    type="text"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FontAwesomeIcon icon={faAddressCard} />
                                            </InputAdornment>
                                        ),
                                        readOnly: true
                                    }}
                                    value={this.state.operatorDetails.operator.isAlsoDriver ? 'Yes' : 'No'}
                                    className="col"
                                />

                            </div>
                        </div>
                        <div className="row py-5 justify-content-around">
                            <div className="col-sm-8">
                                {this.IneligibilityReasons()}
                            </div>
                        </div>
                        <div className="row py-5 justify-content-around">
                            <div className="col-sm-8">
                                {this.IneligibilityReasonsAsDriver()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}