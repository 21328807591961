import React, { Component } from 'react';
// import AlertNotification, { alertMessages, alertType } from './AlertNotification';

export class Layout extends Component {
  static displayName = Layout.name;

  render () {
    return (
      <div className="w-100 h-100" style={{ backgroundColor: '#E5E5E5', overflowY: 'auto' }}>
          {this.props.children}
          {/* <AlertNotification open={this.props.notification.show} alertMessage={this.props.notification.message} severity={this.props.notification.severity} />; */}
      </div>
    );
  }
}
