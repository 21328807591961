import { getFromApi, postToApi } from './ApiService';
import authService from '../components/api-authorization/AuthorizeService';
import { Endpoints } from '../services/ApiServiceConstants';

export class UserService {
    async CreateAsync(data) {
        try {
            console.log('Start execution UserService.CreateAsync');

            let token = await this.GetAccessToken();
            let url = `${Endpoints.User.Create}`;
            return postToApi(url, data, token, null);
        }
        catch (error) {
            console.log('Error received while running UserService.CreateAsync', error);
            
            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution UserService.CreateAsync');
        }
    }

    async GetUserByIdAsync(id) {
        try {
            console.log('Start execution UserService.GetUserByIdAsync');

            let token = await this.GetAccessToken();
            let url = `${Endpoints.User.GetUserById}?id=${id}`;
            return await getFromApi(url, token);
        }
        catch (error) {
            console.log('Error received while running UserService.GetUserByIdAsync', error);
            
            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution UserService.GetUserByIdAsync');
        }
    }

    async GetUserBySearchCriteriaAsync(field) {
        try {
            console.log('Start execution UserService.GetUserBySearchCriteriaAsync');

            let token = await this.GetAccessToken();
            let url = `${Endpoints.User.GetUserBySearchCriteria}?field=${field}`;
            return await getFromApi(url, token);
        }
        catch (error) {
            console.log('Error received while running UserService.GetUserBySearchCriteriaAsync', error);
            
            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution UserService.GetUserBySearchCriteriaAsync');
        }
    }

    async GetUserByUserNameAsync(userName) {
        try {
            console.log('Start execution UserService.GetUserByUserNameAsync');

            let token = await this.GetAccessToken();
            let url = `${Endpoints.User.GetUserByUserName}?userName=${userName}`;
            return await getFromApi(url, token);
        }
        catch (error) {
            console.log('Error received while running UserService.GetUserByUserNameAsync', error);
            
            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution UserService.GetUserByUserNameAsync');
        }
    }

    async GetUsersAsync(id, userName, field, role) {
        try {
            let token = await this.GetAccessToken();
            let url = `${Endpoints.User.GetUsers}?id=${id ?? ''}&userName=${userName ?? ''}&field=${field ?? ''}&role=${role ?? ''}`;
            return await getFromApi(url, token);
        }
        catch (error) {
            console.log('Error received while running UserService.GetUsersAsync', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution UserService.GetUsersAsync');
        }
    }

    async GetUsersByRoleAsync(role) {
        try {
            let token = await this.GetAccessToken();
            let url = `${Endpoints.User.GetUsersByRole}?role=${role}`;
            return await getFromApi(url, token);
        }
        catch (error) {
            console.log('Error received while running UserService.GetUsersAsync', error);
            
            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution UserService.GetUsersAsync');
        }
    }

    async UpdateAsync(data){
        try {
            console.log('Start execution UserService.UpdateAsync');

            let token = await this.GetAccessToken();
            return await postToApi(Endpoints.User.Update, data, token, null);
        }
        catch(error) {
            console.log('Error received while running UserService.UpdateAsync', error);
            
            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution UserService.UpdateAsync');
        }
    }

    async DeleteAsync(data){
        try {
            console.log('Start execution UserService.DeleteAsync');

            let token = await this.GetAccessToken();
            return await postToApi(Endpoints.User.Delete, data, token, null);
        }
        catch(error) {
            console.log('Error received while running UserService.DeleteAsync', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution UserService.DeleteAsync');
        }
    }

    async GetAccessToken(){
        return await authService.getAccessToken();
    }

    ErrorResponse(error){
        return {
            statusText: error.response?.statusText ?? 'Bad Request',
            status: error.response?.status ?? 400,
            success: false,
            message: error.response?.data?.message?.join(', ') ?? error?.message ?? "An error occurred while trying to complete the process, please contact your administrator"
        };
    }
}

const userService = new UserService();

export default userService;