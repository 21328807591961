import React, { Component } from 'react';
import { LoginActions, QueryParameterNames } from '../api-authorization/ApiAuthorizationConstants';
import authService, { AuthenticationResultStatus } from '../api-authorization/AuthorizeService';
import '../../styles/Auth.css';
import { OtpPage } from './OtpPage';
import { CreatePasswordPage } from './CreatePasswordPage';
import { SignupPage } from './SignupPage';
import { ApplicationPaths } from '../api-authorization/ApiAuthorizationConstants';
import AlertNotification, { alertMessages, alertType } from '../AlertNotification';
import { formatNumber } from '../../common/FormHelpers';
import logo from '../../images/dtpwLogo.png';
import { trackPromise } from 'react-promise-tracker';
import { Areas } from '../../constants/areas';
import { Spinner } from '../Spinner';

export class BaseAuthPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            notification: {
                show: false,
                message: '',
                severity: 'success'
            },
            action: LoginActions.Register,
            url: this.props.url,
            user: {},
            userName: "",
            title: "Sign Up",
            buttonTitle: "Sign Up"
        };
    }

    componentDidMount() {
        this.setForm();
    }

    setForm() {
        const action = this.props.action;
        switch (action) {
            case LoginActions.Login:
                this.login(this.getReturnUrl());
                break;
            case LoginActions.OTP:
                this.setState({ title: "OTP", buttonTitle: "Verify" });
                break;
            case LoginActions.CreatePassword:
                this.setState({ title: "Create Password", buttonTitle: "Create" });
                break;
            case LoginActions.LoginCallback:
                this.processLoginCallback();
                break;
            case LoginActions.LoginFailed:
                const params = new URLSearchParams(window.location.search);
                const error = params.get(QueryParameterNames.Message);
                this.setState({ message: error });
                break;
            case LoginActions.Register:
                this.setState({ title: "Sign Up", buttonTitle: "Sign Up" });
                break;
            default:
                throw new Error(`Invalid action '${action}'`);
        }
    }

    async login(returnUrl) {
        const state = { returnUrl };

        trackPromise(
            authService.signIn(state)
                .then(async result => {
                    switch (result.status) {
                        case AuthenticationResultStatus.Redirect:
                            break;
                        case AuthenticationResultStatus.Success:
                            await this.navigateToUrl(returnUrl);
                            break;
                        case AuthenticationResultStatus.Fail:
                            this.setState({ message: result.message });
                            break;
                        default:
                            throw new Error(`Invalid status result ${result.status}.`);
                    }
                }),
            Areas.auth
        );
    }

    async processLoginCallback() {
        const url = window.location.href;
        const result = await authService.completeSignIn(url);
        switch (result.status) {
            case AuthenticationResultStatus.Redirect:
                throw new Error('Should not redirect.');
            case AuthenticationResultStatus.Success:
                await this.navigateToUrl(this.getReturnUrl(result.state));
                break;
            case AuthenticationResultStatus.Fail:
                this.setState({
                    message: result.message
                });
                break;
            default:
                throw new Error(`Invalid authentication result status '${result.status}'.`);
        }
    }

    getReturnUrl(state) {
        const params = new URLSearchParams(window.location.search);
        const fromQuery = params.get(QueryParameterNames.ReturnUrl);
        if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
            // This is an extra check to prevent open redirects.
            throw new Error("Invalid return url. The return url needs to have the same origin as the current page.")
        }
        return (state && state.returnUrl) || fromQuery || `${window.location.origin}/`;
    }

    redirectToRegister() {
        this.redirectToApiAuthorizationPath(`${ApplicationPaths.IdentityRegisterPath}?${QueryParameterNames.ReturnUrl}=${encodeURI(ApplicationPaths.Login)}`);
    }

    redirectToProfile() {
        this.redirectToApiAuthorizationPath(ApplicationPaths.IdentityManagePath);
    }

    redirectToApiAuthorizationPath(apiAuthorizationPath) {
        const redirectUrl = `${window.location.origin}${apiAuthorizationPath}`;
        // It's important that we do a replace here so that when the user hits the back arrow on the
        // browser he gets sent back to where it was on the app instead of to an endpoint on this
        // component.
        window.location.replace(redirectUrl);
    }

    navigateToUrl(url) {
        // It's important that we do a replace here so that we remove the callback uri with the
        // fragment containing the tokens from the browser history.
        window.location.replace(url);
    }

    showAlert = (show, message, severity) => {
        let notification = this.state.notification;
        notification = {
            show: show,
            message: message,
            severity: severity
        };
        this.setState({ notification: notification });
    }

    handleSubmit = async (event) => {
        event.preventDefault();

        let latestUser = {};

        switch (this.state.action) {
            case LoginActions.CreatePassword:
                latestUser = this.state.user;
                latestUser.UserName = this.state.user.UserName;
                latestUser.PhoneNumber = this.state.user.PhoneNumber;
                latestUser.Password = event.target.password.value;
                latestUser.ConfirmPassword = event.target.confirmPassword.value;
                this.setState({ latestUser });

                trackPromise(
                    authService.createPassword(latestUser)
                        .then(async createPasswordResponse => {
                            if (createPasswordResponse?.success) {
                                await authService.signIn();
                            }
                            else {
                                let notification = {
                                    show: true,
                                    message: alertMessages.createPasswordError + `: ${createPasswordResponse?.messsage}`,
                                    severity: alertType.error
                                }

                                this.setState({ notification: notification });
                            }
                        })
                        .catch(error => {
                            let notification = {
                                show: true,
                                message: alertMessages.createPasswordError + `: ${error?.message?.join('. ')}`,
                                severity: alertType.error
                            }

                            this.setState({ notification: notification });
                        }),
                    Areas.auth
                );

                break;
            case LoginActions.Login:
                await authService.signIn();
                break;
            case LoginActions.OTP:
                latestUser = this.state.user;
                latestUser.UserName = this.state.user.UserName;
                latestUser.PhoneNumber = this.state.user.PhoneNumber;
                latestUser.LastSentOtp = event.target[0].value + event.target[1].value + event.target[2].value + event.target[3].value;
                this.setState({ latestUser });

                trackPromise(
                    authService.verifyOtp(latestUser)
                        .then(otpResponse => {
                            if (otpResponse?.success) {
                                this.setState({
                                    action: LoginActions.CreatePassword,
                                    title: "Create Password",
                                    buttonTitle: "Create Password"
                                });
                            }
                            else {
                                let notification = {
                                    show: true,
                                    message: alertMessages.otpVerifyError + `: ${otpResponse?.messsage}`,
                                    severity: alertType.error
                                }

                                this.setState({ notification: notification });
                            }
                        })
                        .catch(error => {
                            let notification = {
                                show: true,
                                message: alertMessages.createPasswordError + `: ${error?.message?.join('. ')}`,
                                severity: alertType.error
                            }

                            this.setState({ notification: notification });
                        }),
                    Areas.auth
                );
                break;
            case LoginActions.Register:
                latestUser = this.state.user;
                latestUser.UserName = event.target.UserName.value;
                latestUser.PhoneNumber = formatNumber(event.target.PhoneNumber.value);
                this.setState({ latestUser });

                trackPromise(
                    authService.firstTimeSignIn(latestUser)
                        .then(signupResponse => {
                            if (signupResponse?.success) {
                                this.setState({
                                    action: LoginActions.OTP,
                                    title: "OTP",
                                    buttonTitle: "Verify"
                                });
                            }
                            else {
                                let notification = {
                                    show: true,
                                    message: alertMessages.signupError + `: ${signupResponse?.message}`,
                                    severity: alertType.error
                                }

                                this.setState({ notification: notification });
                            }
                        })
                        .catch(error => {
                            let notification = {
                                show: true,
                                message: error?.message?.join('. ') ?? alertMessages.signupError,
                                severity: alertType.error
                            }

                            this.setState({ notification: notification });
                        }),
                    Areas.auth
                );
                break;
            default:
                break;
        }
    }

    renderChildForm() {
        switch (this.state.action) {
            case LoginActions.CreatePassword:
                return (<CreatePasswordPage />);
            case LoginActions.OTP:
                return (<OtpPage user={this.state.user} />);
            case LoginActions.Register:
                return (<SignupPage />);
            case LoginActions.Login:
            case LoginActions.LoginCallback:
                return (
                    <Spinner area={Areas.auth} className="h-100 container-fluid" innerClassName="col h-25 text-center" style={{ position: 'absolute', zIndex: 1 }} />);
            default:
                return "";
        }
    }

    render() {
        return (
            <div className="h-100 w-100">
                <div className="auth-page"></div>
                <Spinner area={Areas.auth} className="h-100 container-fluid" innerClassName="col h-25 text-center" style={{ position: 'absolute', zIndex: 1, backgroundColor: 'rgba(0, 0, 0, 0.5)' }} />
                <div className="container h-100">
                    <div className="row align-items-center h-100">
                        <div className="container">
                            <div className="row">
                                <div className="col text-center">
                                    <img src={logo} />
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-sm-3"></div>
                                <div className="col-sm-6">
                                    <div className="text-white text-center">
                                        <h1 style={{ fontSize: 75 + 'px' }}>{this.state.title}</h1>
                                    </div>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <form onSubmit={this.handleSubmit}>
                                        {this.renderChildForm()}
                                        <br />
                                        <div className="form-group row">
                                            <div className="col-sm-12">
                                                <input type="submit" className="auth-page-button btn btn-primary col-sm-12" value={this.state.buttonTitle} />
                                            </div>
                                        </div>
                                    </form>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <div className="text-white text-center">
                                        <label>Copyright 2020</label>
                                    </div>
                                    <AlertNotification 
                                        open={this.state.notification.show} 
                                        alertMessage={this.state.notification.message} 
                                        severity={this.state.notification.severity} 
                                        showAlert={this.showAlert}>
                                    </AlertNotification>
                                </div>
                                <div className="col-sm-3"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}