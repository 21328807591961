import '../../styles/grid.css';
import moment from 'moment';
import { Columns } from '../../constants/RoleScreenConstants';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.gray,
      color: 'rgba(255,255,255, 0.9)',
      boxShadow: theme.shadows[1],
      fontSize: 15,
    },
  }))(Tooltip)

export const BankAccountDetailColumns = [{
    label: Columns.IdNumber.label,
    name: Columns.IdNumber.name,
    options:{
        setCellProps: () => ({style: {whiteSpace:"pre"}})
    }
},
{
    label: Columns.BankName.label,
    name: Columns.BankName.name,
    options:{
        customBodyRender: (value, tableMeta, updateValue) => {
            if (value?.bankName) {
                return value.bankName;
            }
            return '';
        },
        setCellProps: () => ({style: {whiteSpace:"pre"}})
    }
},
{
    label: Columns.BankAccountType.label,
    name: Columns.BankAccountType.name,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            if (value?.bankAccountType) {
                return value.bankAccountType;
            }
            return '';
        },
        setCellProps: () => ({style: {whiteSpace:"pre"}})
    }
},
{
    label: Columns.BankAccountNumber.label,
    name: Columns.BankAccountNumber.name,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            if (value?.bankAccountNumber) {
                return value.bankAccountNumber;
            }
            return '';
        },
        setCellProps: () => ({style: {whiteSpace:"pre"}})
    }
},
{
    label: Columns.BranchCode.label,
    name: Columns.BranchCode.name,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            if (value?.branchCode) {
                return value.branchCode;
            }
            return '';
        },
        setCellProps: () => ({style: {whiteSpace:"pre"}})
    }
},
{
    label: Columns.BankAccountHolder.label,
    name: Columns.BankAccountHolder.name,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            if (value?.bankAccountHolder) {
                return value.bankAccountHolder;
            }
            return '';
        },
        setCellProps: () => ({style: {whiteSpace:"pre"}})
    }
},
{
    label: Columns.VatNumber.label,
    name: Columns.VatNumber.name,
    options: {
        setCellProps: () => ({style: {whiteSpace:"pre"}})
    }
},
{
    label: Columns.DateAdded.label,
    name: Columns.DateAdded.name,
    type: Columns.DateAdded.type,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            if (value) {
                return moment(value).format('DD MMM YYYY');
            }
            return '';
        },
        setCellProps: () => ({style: {whiteSpace:"pre"}})
    }
},
{
    label: Columns.DateModified.label,
    name: Columns.DateModified.name,
    type: Columns.DateModified.type,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            if (value) {
                return moment(value).format('DD MMM YYYY');
            }
            return '';
        },
        setCellProps: () => ({style: {whiteSpace:"pre"}})
    }
}];