import { getFromApi } from './ApiService';
import { Endpoints } from '../services/ApiServiceConstants';
import authService from '../components/api-authorization/AuthorizeService';

export class UtsService {
    async GetUserByIdAsync(id) {
        try {
            let url = `${Endpoints.Uts.GetUserById}?id=${id}`;
            let token = await this.GetAccessToken();
            return await getFromApi(url, token);
        }
        catch (error) {
            console.log('Error received while running UtsService.GetUserByIdAsync', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution UtsService.GetUserByIdAsync');
        }
    }

    async GetUserBySearchCriteriaAsync(field) {
        try {
            let url = `${Endpoints.Uts.GetUserBySearchCriteria}?field=${field}`;
            let token = await this.GetAccessToken();
            return await getFromApi(url, token);
        }
        catch (error) {
            console.log('Error received while running UtsService.GetUserBySearchCriteriaAsync', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution UtsService.GetUserBySearchCriteriaAsync');
        }
    }

    async GetUserByUserNameAsync(userName) {
        try {
            let url = `${Endpoints.Uts.GetUserByUserName}?userName=${userName}`;
            let token = await this.GetAccessToken();
            return await getFromApi(url, token);
        }
        catch (error) {
            console.log('Error received while running UtsService.GetUserByUserNameAsync', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution UtsService.GetUserByUserNameAsync');
        }
    }

    async GetUsersAsync() {
        try {
            let token = await this.GetAccessToken();
            return await getFromApi(Endpoints.Uts.GetUsers, token);
        }
        catch (error) {
            console.log('Error received while running UtsService.GetUsersAsync', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution UtsService.GetUsersAsync');
        }
    }
 
    async GetAllNotificationsAsync() {
        try {
            let token = await this.GetAccessToken();
            return await getFromApi(Endpoints.Notifications.GetUtsUsersNotifications, token);
        }
        catch (error) {
            console.log('Error received while running Notifications.GetUtsUsersNotifications', error);

            return this.ErrorResponse(error);
        }
        finally {
            console.log('End execution Notifications.GetUtsUsersNotifications');
        }
    }

    async GetAccessToken() {
        return await authService.getAccessToken();
    }

    ErrorResponse(error){
        return {
            statusText: error.response?.statusText ?? 'Bad Request',
            status: error.response?.status ?? 400,
            success: false,
            message: error.response?.data?.message?.join(', ') ?? error?.message ?? "An error occurred while trying to complete the process, please contact your administrator"
        };
    }
}

const utsService = new UtsService();

export default utsService;