import moment from 'moment';
import '../../styles/grid.css';
import { formatToLocalNumber } from '../../common/FormHelpers';
import { Columns } from '../../constants/RoleScreenConstants';
import { Button } from '@material-ui/core';

export const UtsColumns = [{
    label: Columns.FirstName.label,
    name: Columns.FirstName.name
},
{
    label: Columns.LastName.label,
    name: Columns.LastName.name
},
{
    label: Columns.IdNumber.label,
    name: Columns.IdNumber.name,
    options: {
        display: false
    }
},
{
    label: Columns.PhoneNumber.label,
    name: Columns.PhoneNumber.name,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            if (value) {
                return formatToLocalNumber(value);
            }
            return '';
        }
    }
},
{
    label: Columns.EmailAddress.label,
    name: Columns.EmailAddress.name
},
{
    label: Columns.DateAdded.label,
    name: Columns.DateAdded.name,
    type: Columns.DateAdded.type,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            if (value) {
                return moment(value).format('DD MMM YYYY');
            }
            return '';
        }
    }
},
{
    label: Columns.DateModified.label,
    name: Columns.DateModified.name,
    type: Columns.DateModified.type,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            if (value) {
                return moment(value).format('DD MMM YYYY');
            }
            return '';
        }
    }
},
{
    label: Columns.EditButton.label,
    name: Columns.EditButton.name,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            return <Button value={value}>{'Edit'}</Button>;
        },
        download: false
    }
}];