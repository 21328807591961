import React, { Component } from 'react';
import { ApplicationPaths } from '../api-authorization/ApiAuthorizationConstants';
import { Link } from 'react-router-dom';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Grid, TextField, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import NumberFormat from 'react-number-format';
import { validateRequiredField, handleRadioButtonChange } from '../../common/FormHelpers';

export class SignupPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idNumber: '',
            phoneNumber: '',
            requiredInputs: {
                UserName: {
                    error: false,
                    message: ''
                },
                PhoneNumber: {
                    error: false,
                    message: ''
                }
            },
            idNumberField : {
                label: 'SA ID Number',
                placeholder: 'e.g. 8123456789123',
                type: 'number',
                id: 'idNumber'
            }
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleRadioButtonChange = this.handleRadioButtonChange.bind(this);
    }

    styles = {
        root: {
            background: "rgba(196.00000351667404, 196.00000351667404, 196.00000351667404, 1)",
            minWidth: 105
        },
        radioButton: {
            color: "#ffffff",
            "&$checked": {
              color: "red"
            }
          },
        label: {
            color: "#ffffff"
        },
        input: {
            color: "white"
        }
    };

    handleChange(event) {
        let requiredInputs = validateRequiredField(event, this.state.requiredInputs);
        let name = event.target.name;
        this.setState({ [name]: event.target.value, requiredInputs: requiredInputs });
    }

    handleRadioButtonChange(event) {
        let selected = handleRadioButtonChange(event.target.value);

        let idNumberField = {
            label: selected.label,
            placeholder: selected.placeholder,
            type: selected.type,
            id: selected.id
        }
        
        this.setState({ idNumberField: idNumberField});
    }

    render() {
        return (
            <Grid
                container
                >
                <NumberFormat
                    format="### ### ####"
                    required
                    error={this.state.requiredInputs.PhoneNumber.error}
                    helperText={this.state.requiredInputs.PhoneNumber.message}
                    margin="normal"
                    name="PhoneNumber"
                    label="Phone Number"
                    type="text"
                    variant="filled"
                    fullWidth
                    placeholder="e.g. 0612345678"
                    customInput={TextField}
                    style={this.styles.root}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <PhoneOutlinedIcon />
                            </InputAdornment>
                        ),
                    }}
                    onChange={this.handleChange}
                />
                <TextField
                    required
                    error={this.state.requiredInputs.UserName.error}
                    helperText={this.state.requiredInputs.UserName.message}
                    margin="normal"
                    name="UserName"
                    label={this.state.idNumberField.label}
                    variant="filled"
                    fullWidth
                    placeholder={this.state.idNumberField.placeholder}
                    type={this.state.idNumberField.type}
                    style={this.styles.root}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <ListAltOutlinedIcon />
                            </InputAdornment>
                        )
                    }}
                    onInput={(e)=>{ 
                        if(e.target.value){
                            e.target.value = e.target.value.toString().slice(0, 13)
                        }
                    }}
                    onChange={this.handleChange}
                />
                <RadioGroup
                value={this.state.idNumberField.id}
                row
                onChange={this.handleRadioButtonChange}
              >
                  <FormControlLabel
                    key="SA ID Number"
                    color="default"
                    style={this.styles.label}
                    value="idNumber"
                    control={<Radio color="default" style={this.styles.radioButton}/>}
                    label="SA ID Number"
                  />
                  <FormControlLabel
                    key="Traffic Register Number"
                    value="trafficRegisterNumber"
                    color="default"
                    style={this.styles.label}
                    control={<Radio color="default" style={this.styles.radioButton}/>}
                    label="Traffic Register Number"
                  />
                  <FormControlLabel
                    key="Business Registration Number"
                    value="businessRegistrationNumber"
                    color="default"
                    style={this.styles.label}
                    control={<Radio color="default" style={this.styles.radioButton}/>}
                    label="Company Registration Number"
                  />
              </RadioGroup>
                <br />
                <span className="text-right col-sm-12 text-white">
                    <u>
                        <Link to={ApplicationPaths.Login} className="text-white">Already signed up? Log in Here</Link>
                    </u>
                </span>
            </Grid>
        );
    }
}