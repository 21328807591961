export const ApplicationName = 'BlueDot Registration Application';

export const UserManagerSettings = {
    ClientId: "registrationApi",
    Scope: "registrationApi MyClientId_api openid roles",
    ResponseType: "id_token token"
}

export const QueryParameterNames = {
  ReturnUrl: 'returnUrl',
  Message: 'message'
};

export const LogoutActions = {
  LogoutCallback: 'logout-callback',
  Logout: 'logout',
  LoggedOut: 'logged-out'
};

export const LoginActions = {
  Login: 'login',
  LoginCallback: 'login-callback',
  LoginFailed: 'login-failed',
  Profile: 'profile',
  Register: 'register',
  OTP: 'otp',
  CreatePassword: 'create-password'
};

export const ResourceActions = {
  Dashboard: 'dashboard',
  Uts: 'uts',
  RegionalCompany: 'regionalcompany',
  ServiceProviderVerificationDetail: 'verificationdetail',
  Operator: 'operator',
  Driver: 'driver',
  Vehicle: 'vehicle',
  DriverTsAndCs: 'drivertsandcs',
  OperatorTsAndCs: 'operatortsandcs'
}

const authPrefix = '/authentication';
const resourcePrefix = '/portal';

export const ApplicationPaths = {
  DefaultLoginRedirectPath: '/',
  ApiAuthorizationClientConfigurationUrl: `/_configuration/${ApplicationName}`,
  ApiAuthorizationPrefix: authPrefix,
  Login: `${authPrefix}/${LoginActions.Login}`,
  OTP: `${authPrefix}/${LoginActions.OTP}`,
  CreatePassword: `${authPrefix}/${LoginActions.CreatePassword}`,
  LoginFailed: `${authPrefix}/${LoginActions.LoginFailed}`,
  LoginCallback: `${authPrefix}/${LoginActions.LoginCallback}`,
  Register: `${authPrefix}/${LoginActions.Register}`,
  Profile: `${authPrefix}/${LoginActions.Profile}`,
  LogOut: `${authPrefix}/${LogoutActions.Logout}`,
  LoggedOut: `${authPrefix}/${LogoutActions.LoggedOut}`,
  LogOutCallback: `${authPrefix}/${LogoutActions.LogoutCallback}`,
  IdentityRegisterPath: '/Identity/Account/Register',
  IdentityManagePath: '/Identity/Account/Manage',
  Dashboard: `${resourcePrefix}/${ResourceActions.Dashboard}`,
  ServiceProviderVerificationDetail: `/${ResourceActions.ServiceProviderVerificationDetail}`,
  Uts: `/${ResourceActions.Uts}`,
  RegionalCompany: `/${ResourceActions.RegionalCompany}`,
  Operator: `/${ResourceActions.Operator}`,
  Driver: `/${ResourceActions.Driver}`,
  Vehicle: `/${ResourceActions.Vehicle}`,
  DriverTsAndCs: `/${ResourceActions.DriverTsAndCs}`,
  OperatorTsAndCs: `/${ResourceActions.OperatorTsAndCs}`
};