import React, { Component } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { TextField, MenuItem } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard } from '@fortawesome/free-regular-svg-icons';
import bankingService from '../../services/BankingService';
import { Areas } from '../../constants/areas';
import { getMasterDataDropdown, setBankingDetailDialog } from '../../common/FormHelpers';
import { alertMessages, alertType } from '../AlertNotification';
import { MasterDataType, Roles } from '../../constants/RoleScreenConstants';

export class BankingDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            notification: {
                show: false,
                message: '',
                severity: alertType.success
            },
            detailsAreDifferent: false,
            requiredInputs: {
                bankId: {
                    error: false,
                    message: ''
                },
                accountNumber: {
                    error: false,
                    message: ''
                },
                branchCode: {
                    error: false,
                    message: ''
                },
                bankAccountTypeId: {
                    error: false,
                    message: ''
                },
                accountHolder: {
                    error: false,
                    message: ''
                }
            },
            originalBankingDetails:
            {
                bankId: "",
                accountNumber: "",
                branchCode: "",
                bankAccountTypeId: "",
                accountHolder: ""
            },
            bankingDetails:
            {
                id: "",
                bankId: "",
                accountNumber: "",
                branchCode: "",
                bankAccountTypeId: "",
                accountHolder: ""
            },
            confirmOpen: false,
            bankDropdownValues: [],
            bankAccountTypeDropdownValues: []
        }
    }

    componentDidMount() {
        this.getDropdownValues(MasterDataType.Bank);
        this.getDropdownValues(MasterDataType.BankAccountType);

        switch (this.props.role) {
            case Roles.Uts:
                trackPromise(
                    bankingService.GetUtsAsync()
                        .then(response => {
                            if(response.status === 204) {
                                return;
                            } else if (!response?.success) {
                                this.showAlert(true, response.message ?? alertMessages.bankingDetailsError, alertType.error);
                            } else {
                                if (response.data != null) {
                                    let bankingDetails = setBankingDetailDialog(response.data, false);
                                    let originalBankingDetails = setBankingDetailDialog(response.data, true);

                                    this.setState({ bankingDetails: bankingDetails, originalBankingDetails: originalBankingDetails });
                                }
                            }
                        })
                        .catch(error => { 
                            this.showAlert(true, alertMessages.bankingDetailsError, alertType.error);
                        }),
                    Areas.bankingDetail
                );
                break;
            case Roles.RegionalCompany:
                trackPromise(
                    bankingService.GetByRegionalCompanyUserIdAsync(this.props.user.id)
                        .then(response => {
                            if(response.status === 204) {
                                return;
                            } else if (!response?.success) {
                                this.showAlert(true, response.message ?? alertMessages.bankingDetailsError, alertType.error);
                            } else {
                                if (response.data != null) {
                                    let bankingDetails = setBankingDetailDialog(response.data, false);
                                    let originalBankingDetails = setBankingDetailDialog(response.data, true);

                                    this.setState({ bankingDetails: bankingDetails, originalBankingDetails: originalBankingDetails});
                                }
                            }
                        })
                        .catch(error => { }),
                    Areas.bankingDetail
                );
                break;
            default:
                trackPromise(
                    bankingService.GetByUserIdAsync(this.props.user.id)
                        .then(response => {
                            if(response.status === 204) {
                                return;
                            } else if (!response?.success) {
                                this.showAlert(true, response.message ?? alertMessages.bankingDetailsError, alertType.error);
                            } else {
                                if (response.data != null) {
                                    let bankingDetails = setBankingDetailDialog(response.data, false);
                                    let originalBankingDetails = setBankingDetailDialog(response.data, true);

                                    this.setState({ bankingDetails: bankingDetails, originalBankingDetails: originalBankingDetails});
                                }
                            }
                        })
                        .catch(error => { }),
                    Areas.bankingDetail
                );
                break;
        };

    }

    getDropdownValues(dropdownType){
        trackPromise(
            getMasterDataDropdown(dropdownType)
                .then(response => {
                  if (response?.success) {
                      if (dropdownType === MasterDataType.Bank){
                        this.setState({ bankDropdownValues: response.data});
                      }
                      else if (dropdownType === MasterDataType.BankAccountType){
                        this.setState({ bankAccountTypeDropdownValues: response.data});
                      }
                  }
  
                  this.props.closeDialog(true, response.message ?? alertMessages.saveError, alertType.error, true);
                  return [];
                })
                .catch(error => {
                  this.props.closeDialog(true, alertMessages.saveError, alertType.error, true);
                  return [];
                }),
          Areas.dialog
        );
    }

    render() {
        return (
            <div className="container-fluid p-0 h-100">
                <div className="container-fluid card-border-radius bg-white">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="row py-4 justify-content-around">
                                <div className="col-sm-8">
                                    <TextField
                                        name="bankId"
                                        aria-readonly
                                        select
                                        error={this.state.requiredInputs.bankId.error}
                                        helperText={this.state.requiredInputs.bankId.message}
                                        label="Bank Name"
                                        value={this.state.bankingDetails.bankId}
                                        onChange={this.handleInputChange}
                                        required
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <FontAwesomeIcon icon={faAddressCard} />
                                                </InputAdornment>
                                            )
                                        }}
                                    >
                                        <MenuItem key='default' value=''>
                                        None
                                        </MenuItem>
                                        {this.state.bankDropdownValues?.map((option) => (
                                        <MenuItem key={option.name} value={option.id}>
                                            {option.name}
                                        </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                            </div>
                            <div className="row py-4 justify-content-around">
                                <div className="col-sm-8">
                                    <TextField
                                        autoFocus
                                        aria-readonly
                                        required
                                        error={this.state.requiredInputs.accountNumber.error}
                                        helperText={this.state.requiredInputs.accountNumber.message}
                                        margin="dense"
                                        name="accountNumber"
                                        label="Account Number"
                                        type="text"
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <FontAwesomeIcon icon={faAddressCard} />
                                                </InputAdornment>
                                            )
                                        }}
                                        value={this.state.bankingDetails.accountNumber}
                                        className="col"
                                        onChange={this.handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className="row py-4 justify-content-around">
                                <div className="col-sm-8">
                                    <TextField
                                        autoFocus
                                        aria-readonly
                                        required
                                        error={this.state.requiredInputs.branchCode.error}
                                        helperText={this.state.requiredInputs.branchCode.message}
                                        margin="dense"
                                        name="branchCode"
                                        label="Branch Code"
                                        type="text"
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <FontAwesomeIcon icon={faAddressCard} />
                                                </InputAdornment>
                                            )
                                        }}
                                        value={this.state.bankingDetails.branchCode}
                                        className="col"
                                        onChange={this.handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className="row py-4 justify-content-around">
                                <div className="col-sm-8">
                                    <TextField
                                        autoFocus
                                        required
                                        aria-readonly
                                        select
                                        error={this.state.requiredInputs.bankAccountTypeId.error}
                                        helperText={this.state.requiredInputs.bankAccountTypeId.message}
                                        margin="dense"
                                        name="bankAccountTypeId"
                                        label="Account Type"
                                        type="text"
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <FontAwesomeIcon icon={faAddressCard} />
                                                </InputAdornment>
                                            )
                                        }}
                                        value={this.state.bankingDetails.bankAccountTypeId}
                                        className="col"
                                        onChange={this.handleInputChange}
                                    >
                                        <MenuItem key='default' value=''>
                                        None
                                        </MenuItem>
                                        {this.state.bankAccountTypeDropdownValues?.map((option) => (
                                        <MenuItem key={option.description} value={option.id}>
                                            {option.description}
                                        </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                            </div>
                            <div className="row py-4 justify-content-around">
                                <div className="col-sm-8">
                                    <TextField
                                        autoFocus
                                        required
                                        aria-readonly
                                        error={this.state.requiredInputs.accountHolder.error}
                                        helperText={this.state.requiredInputs.accountHolder.message}
                                        margin="dense"
                                        name="accountHolder"
                                        label="Account Holder"
                                        type="text"
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <FontAwesomeIcon icon={faAddressCard} />
                                                </InputAdornment>
                                            )
                                        }}
                                        value={this.state.bankingDetails.accountHolder}
                                        className="col"
                                        onChange={this.handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className="row py-4 justify-content-around">
                                <div className="col-sm-8">
                                    <TextField
                                        autoFocus
                                        aria-readonly
                                        margin="dense"
                                        name="taxNumber"
                                        label="Tax Number"
                                        type="text"
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <FontAwesomeIcon icon={faAddressCard} />
                                                </InputAdornment>
                                            )
                                        }}
                                        value={this.state.bankingDetails.taxNumber}
                                        className="col"
                                        onChange={this.handleInputChange}
                                    />
                                </div>
                            </div><div className="row py-4 justify-content-around">
                                <div className="col-sm-8">
                                    <i>For purposes of accurate verification any changes to your bank details must be done through the CSD.</i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}