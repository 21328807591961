import React, { Component, Fragment } from 'react';
import { Route, Switch } from 'react-router';
import { Dashboard } from './Dashboard';
import { OperatorPage } from './pages/OperatorPage';
import { UtsPage } from './pages/UtsPage';
import { RegionalCompanyPage } from './pages/RegionalCompanyPage';
import { ServiceProviderVerificationDetailPage } from './pages/ServiceProviderVerificationDetailPage';
import { DriverPage } from './pages/DriverPage';
import { VehiclePage } from './pages/VehiclePage';

export default class ResourceRoutes extends Component {

    render() {
        return (
            <Fragment>
                <Switch>
                    <Route exact path="/" render={() => dashboardContent('uts')} />
                    <Route path={`/:contentPage`} render={props => dashboardContent(props.match.params.contentPage)} />
                </Switch>
            </Fragment>);
    }
}

export function dashboardContent(contentPage) {
    let contentPageLowerCase = contentPage.toLowerCase();

    if (contentPageLowerCase.startsWith('verificationdetail')) {
        return (<Dashboard currentPage={contentPageLowerCase}><ServiceProviderVerificationDetailPage /></Dashboard>);
    }
    else if (contentPageLowerCase.startsWith('uts')) {
        return (<Dashboard currentPage={contentPageLowerCase}><UtsPage /></Dashboard>);
    } else if (contentPageLowerCase.startsWith('regionalcompany')) {
        return (<Dashboard currentPage={contentPageLowerCase}><RegionalCompanyPage /></Dashboard>);
    } else if (contentPageLowerCase.startsWith('operator')) {
        return (<Dashboard currentPage={contentPageLowerCase}><OperatorPage /></Dashboard>);
    } else if (contentPageLowerCase.startsWith('driver')) {
        return (<Dashboard currentPage={contentPageLowerCase}><DriverPage /></Dashboard>);
    } else if (contentPageLowerCase.startsWith('vehicle')) {
        return (<Dashboard currentPage={contentPageLowerCase}><VehiclePage /></Dashboard>);
    }
}