import React, { Component } from 'react';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants';
import '../styles/NavMenu.css';
import driverService from '../services/DriverService';
import operatorService from '../services/OperatorService';
import vehicleService from '../services/VehicleService';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DriverSelectedIcon from '../images/driverSelected.svg';
import OperatorSelectedIcon from '../images/operatorSelected.svg';
import VehicleSelectedIcon from '../images/vehicleSelected.png';
import TickIcon from '../images/tick.svg';
import CrossIcon from '../images/cross.svg';
import { Spinner } from './Spinner';
import { Areas } from '../constants/areas';
import { trackPromise } from 'react-promise-tracker';

export class Overview extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            drivers: {
                registered: 0,
                approved: 0
            },
            operators: {
                registered: 0,
                approved: 0
            },
            vehicles: {
                registered: 0,
                approved: 0
            },
            routes: {
                registered: 0,
                approved: 0
            }
        }
    }

    onClick(entityType, userName) {
        switch (entityType) {

        }
    }

    componentDidMount() {
        this._isMounted = true;

        let driverStats = this.state.drivers;

        trackPromise(
            driverService.GetTotalsAsync(null, null, false)
                .then(response => {
                    if (response.statusText !== 'OK' || response.data.success === false) {

                    } else {
                        driverStats.registered = response.data ?? 0;
                        this.setState({ drivers: driverStats })
                    }
                })
                .catch(error => { }),
            Areas.driverStats
        );

        trackPromise(
            driverService.GetTotalsAsync(null, true, false)
                .then(response => {
                    if (response.statusText !== 'OK' || response.data.success === false) {

                    } else {
                        driverStats.approved = response.data ?? 0;
                        this.setState({ drivers: driverStats })
                    }
                })
                .catch(error => { }),
            Areas.driverStats
        );
        let operatorStats = this.state.operators;

        trackPromise(
            operatorService.GetTotalsAsync(null, null, false)
                .then(response => {
                    if (response.statusText !== 'OK' || response.data.success === false) {

                    } else {
                        operatorStats.registered = response.data ?? 0;
                        this.setState({ operators: operatorStats })
                    }
                })
                .catch(error => { }),
            Areas.operatorStats
        );

        trackPromise(
            operatorService.GetTotalsAsync(null, true, false)
                .then(response => {
                    if (response.statusText !== 'OK' || response.data.success === false) {

                    } else {
                        operatorStats.approved = response.data ?? 0;
                        this.setState({ operators: operatorStats })
                    }
                })
                .catch(error => { }),
            Areas.operatorStats
        );

        let vehicleStats = this.state.vehicles;

        trackPromise(
            vehicleService.GetTotalsAsync(null, null, false)
                .then(response => {
                    if (response.statusText !== 'OK' || response.data.success === false) {

                    } else {
                        vehicleStats.registered = response.data ?? 0;
                        this.setState({ vehicles: vehicleStats })
                    }
                })
                .catch(error => { }),
            Areas.vehicleStats
        );

        trackPromise(
            vehicleService.GetTotalsAsync(null, true, false)
                .then(response => {
                    if (response.statusText !== 'OK' || response.data.success === false) {

                    } else {
                        vehicleStats.approved = response.data ?? 0;
                        this.setState({ vehicles: vehicleStats })
                    }
                })
                .catch(error => { }),
            Areas.vehicleStats
        );
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return (
            <div className="container-fluid p-0 h-100">
                <div className="row h-100">
                    <NavLink tag={Link} className="col-sm-6 text-dark" to={ApplicationPaths.Driver}>
                        <div className="row p-3 h-100">
                            <div className="card card-border-radius col d-flex flex-column">
                                <Spinner area={Areas.driverStats} className="h-100 container-fluid" innerClassName="col h-100 text-center" style={{ position: 'absolute' }} />
                                <div className="row pt-4 justify-content-between">
                                    <div className="col">
                                        <img src={DriverSelectedIcon} style={{ height: "4em" }} />
                                    </div>
                                    <div className="col text-center">
                                        <h2>Drivers</h2>
                                    </div>
                                    <div className="col text-right">
                                        <ArrowForwardIcon style={{ fontSize: "4em" }}></ArrowForwardIcon>
                                    </div>
                                </div>
                                <div className="row flex-grow-1 align-items-center">
                                    <div className="col text-center">
                                        <span style={{ fontSize: '5em' }}>{this.state.drivers.registered}</span>
                                    </div>
                                    <div className="col text-center">
                                        <span style={{ fontSize: '5em' }}>{this.state.drivers.approved}</span>
                                        <img src={TickIcon} style={{ top: 0, height: "3em", position: 'absolute' }} />
                                    </div>
                                </div>
                                <div className="row flex-grow-1">
                                    <div className="col text-center">
                                        <h3>Registered</h3>
                                    </div>
                                    <div className="col text-center">
                                        <h3>Approved</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </NavLink>
                    <NavLink tag={Link} className="col-sm-6 text-dark" to={ApplicationPaths.Operator}>
                        <div className="row p-3 h-100">
                            <div className="card card-border-radius col d-flex flex-column">
                                <Spinner area={Areas.operatorStats} className="h-100 container-fluid" innerClassName="col h-100 text-center" style={{ position: 'absolute' }} />
                                <div className="row pt-4 justify-content-between">
                                    <div className="col">
                                        <img src={OperatorSelectedIcon} style={{ height: "4em" }} />
                                    </div>
                                    <div className="col text-center">
                                        <h2>Operators</h2>
                                    </div>
                                    <div className="col text-right">
                                        <ArrowForwardIcon style={{ fontSize: "4em" }}></ArrowForwardIcon>
                                    </div>
                                </div>
                                <div className="row flex-grow-1 align-items-center">
                                    <div className="col text-center">
                                        <span style={{ fontSize: '5em' }}>{this.state.operators.registered}</span>
                                    </div>
                                    <div className="col text-center">
                                        <span style={{ fontSize: '5em' }}>{this.state.operators.approved}</span>
                                        <img src={TickIcon} style={{ top: 0, height: "3em", position: 'absolute' }} />
                                    </div>
                                </div>
                                <div className="row flex-grow-1">
                                    <div className="col text-center">
                                        <h3>Registered</h3>
                                    </div>
                                    <div className="col text-center">
                                        <h3>Approved</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </NavLink>
                    <NavLink tag={Link} className="col-sm-6 text-dark" to={ApplicationPaths.Vehicle}>
                        <div className="row p-3 h-100">
                            <div className="card card-border-radius col d-flex flex-column">
                                <Spinner area={Areas.operatorStats} className="h-100 container-fluid" innerClassName="col h-100 text-center" style={{ position: 'absolute' }} />
                                <div className="row pt-4 justify-content-between">
                                    <div className="col">
                                        <img src={VehicleSelectedIcon} style={{ height: "4em" }} />
                                    </div>
                                    <div className="col text-center">
                                        <h2>Vehicles</h2>
                                    </div>
                                    <div className="col text-right">
                                        <ArrowForwardIcon style={{ fontSize: "4em" }}></ArrowForwardIcon>
                                    </div>
                                </div>
                                <div className="row flex-grow-1 align-items-center">
                                    <div className="col text-center">
                                        <span style={{ fontSize: '5em' }}>{this.state.vehicles.registered}</span>
                                    </div>
                                    <div className="col text-center">
                                        <span style={{ fontSize: '5em' }}>{this.state.vehicles.approved}</span>
                                        <img src={TickIcon} style={{ top: 0, height: "3em", position: 'absolute' }} />
                                    </div>
                                </div>
                                <div className="row flex-grow-1">
                                    <div className="col text-center">
                                        <h3>Registered</h3>
                                    </div>
                                    <div className="col text-center">
                                        <h3>Approved</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </NavLink>
                </div>
            </div>
        );
    }
}