import bankingService from "../services/BankingService";
import operatorService from "../services/OperatorService";
import regionalCompanyService from "../services/RegionalCompanyService";
import identityServerService from '../services/IdentityServerService';
import { DashboardTabs, MasterDataType, Roles } from '../constants/RoleScreenConstants';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { blue } from '@material-ui/core/colors';

export function validateSingleField(fieldProp, isRequired, value) {
  if (isRequired && !value) {
    fieldProp.error = true;
    fieldProp.message = "This field is required";
  }
  else if (isRequired && value) {
    fieldProp.error = false;
    fieldProp.message = "";
  }

  return fieldProp;
}

export function validateRequiredField(event, requiredInputs) {
  let name = event.target.name;

  if (event.target.required && !event.target.value) {
    requiredInputs[name].error = true;
    requiredInputs[name].message = "This field is required";

    return requiredInputs;
  }
  else if (event.target.required && event.target.value) {
    requiredInputs[name].error = false;
    requiredInputs[name].message = "";

    return requiredInputs;
  }
}

export function handleBankingChange(event, state) {
  let name = event.target.name;
  let fieldProps = state.requiredInputs;

  let fieldProp = validateSingleField(fieldProps[name], event.target.required, event.target.value);

  if (fieldProp != null) {
    fieldProps[name] = fieldProp;
  }

  let bankingDetails = state.bankingDetails;
  bankingDetails[name] = event.target.value;
  return bankingDetails;
}

export function handleChange(event, state) {
  let name = event.target.name;
  let fieldProps = state.requiredInputs;

  if (event.target.required && !event.target.value) {
    fieldProps[name].error = true;
    fieldProps[name].message = "This field is required";
  }
  else if (event.target.required && event.target.value) {
    fieldProps[name].error = false;
    fieldProps[name].message = "";
  }

  if (state.userObj) {
    let userObj = state.userObj;

    if (event.target.type === 'checkbox') {
      if (name === 'isAlsoDriver') {
        userObj[name] = event.target.checked;
      } else {
        userObj.user[name] = event.target.checked;
      }
    } else {
      userObj.user[name] = event.target.value;
    }

    return userObj;
  }
  else if (state.verificationDetail) {
    let verificationDetail = state.verificationDetail;
    verificationDetail[name] = event.target.value;

    return verificationDetail;
  }

  return {};
}

export function validateIdNumber(value) {
	const idNumber = value?.toString();

	//Validate length of value to be 13
	if(idNumber.length != 13) return false;		
	
	//Validate that all characters in value are digits
	let hasDigitsOnly = /^\d+$/.test(idNumber);
	if(hasDigitsOnly !== true) return false;

	//Validate first 6 digits to be a valid date
	var parsedDate = new Date(idNumber.substring(0, 2), idNumber.substring(2, 4) - 1, idNumber.substring(4, 6));

	if (!(
		(parsedDate.getYear() == idNumber.substring(0, 2)) && 
		(parsedDate.getMonth() == idNumber.substring(2, 4) - 1) && 
		(parsedDate.getDate() == idNumber.substring(4, 6))
	)) return false
	
	//Validate with Checksum: Apply Luhn formula for check-digits
	var tempTotal = 0;
	var checkSum = 0;
	var multiplier = 1;
	for (var i = 0; i < 13; ++i) {
			tempTotal = parseInt(idNumber.charAt(i)) * multiplier;
			if (tempTotal > 9) {
					tempTotal = parseInt(tempTotal.toString().charAt(0)) + parseInt(tempTotal.toString().charAt(1));
			}
			checkSum = checkSum + tempTotal;
			multiplier = (multiplier % 2 == 0) ? 1 : 2;
	}
	if ((checkSum % 10) != 0) return false;

	return true;
}

export function validateCompanyRegisterNumber(value) {
	const companyRegNumber = value?.toString();

	//Validate length of value to be 12
	if(companyRegNumber.length != 12) return false;
	
	//Validate that all characters in value are digits
	let hasDigitsOnly = /^\d+$/.test(companyRegNumber);
	if(hasDigitsOnly !== true) return false;

	return true;
}

export function handleChangePassword(event, requiredInputs) {
  let name = event.target.name;

  if (event.target.required && !event.target.value) {
    requiredInputs[name].error = true;
    requiredInputs[name].message = "This field is required";
    requiredInputs[name].value = "";
  }
  else if (event.target.required && event.target.value) {
    if ((name === "password" && (event.target.value !== requiredInputs.confirmPassword.value)) ||
      (name === "confirmPassword" && (event.target.value !== requiredInputs.password.value))) {
      requiredInputs.confirmPassword.error = true;
      requiredInputs.confirmPassword.message = "Passwords don't match";
    }
    else {
      requiredInputs[name].error = false;
      requiredInputs[name].message = "";
    }
    requiredInputs[name].value = event.target.value;
  }

  return requiredInputs;
}

export function handleVehicleChange(event, state) {
  let id = event.target.id;
  let fieldProps = state.requiredInputs;

  if (event.target.required && !event.target.value) {
    fieldProps[id].error = true;
    fieldProps[id].message = "This field is required";
  }
  else if (event.target.required && event.target.value) {
    fieldProps[id].error = false;
    fieldProps[id].message = "";
  }

  let vehicle = state.vehicle;
  vehicle[id] = event.target.value;
  return vehicle;
}

export function handleCompanyChange(event, state) {
  let name = event.target.name;
  let fieldProps = state.requiredInputs;

  let fieldProp = validateSingleField(fieldProps[name], event.target.required, event.target.value);

  if (fieldProp != null) {
    fieldProps[name] = fieldProp;
  }

  let company = state.company;
  company[name] = event.target.value;

  return company;
}

export function handleRadioButtonChange(id, value) {
  let radioButtonField = {};

  switch (id) {
    case "businessRegistrationNumber":
      radioButtonField.id = "businessRegistrationNumber";
      radioButtonField.label = "Company Registration Number";
      radioButtonField.placeholder = "e.g. 123456785121";
      radioButtonField.type = "text";

      return radioButtonField;
    case "trafficRegisterNumber":
      radioButtonField.id = "trafficRegisterNumber";
      radioButtonField.label = "Traffic Register Number";
      radioButtonField.placeholder = "e.g. 12345678W1234";
      radioButtonField.type = "text";

      return radioButtonField;
    case "idNumber":
      radioButtonField.id = "idNumber";
      radioButtonField.label = "SA ID Number";
      radioButtonField.placeholder = "e.g. 8123456789123";
      radioButtonField.type = "number";

      return radioButtonField;
    case "entityReferenceType":
      radioButtonField.entityReferenceType = value;

      return radioButtonField;
    default:
      return radioButtonField;
  }
}

export async function getParentDropdownValues(tabName) {
  switch (tabName) {
    case DashboardTabs.RegionalCompanyUsers:
      return regionalCompanyService.GetAllAsync()
        .then(response => {
          return response;
        });
    case DashboardTabs.Operators:
      return regionalCompanyService.GetAllAsync()
        .then(response => {
          return response;
        });
    case DashboardTabs.Drivers:
      return operatorService.GetAllAsync()
        .then(response => {
          if (response.success) {
            response.data = response.data?.map(opData => opData.user);
          }

          return response;
        });
    case DashboardTabs.Vehicles:
      return operatorService.GetAllAsync()
        .then(response => {
          if (response.success) {
            response.data = response.data?.map(opData => opData.user);
          }

          return response;
        });
    default:
      return [];
  }
}

export function getMasterDataDropdown(masterDataType) {
  switch (masterDataType) {
    case MasterDataType.Bank:
      return bankingService.GetBanks()
        .then(response => {
          return response;
        });
    case MasterDataType.BankAccountType:
      return bankingService.GetBankAccountTypes()
        .then(response => {
          return response;
        });
    default:
      return [];
  }
}

export function setDialogDetail(row) {
  if (row) {
    row.action = "Update";
  }
  else {
    row = {
      maaaNumber: '',
      entityReferenceNumber: '',
      entityReferenceType: '',
      action: "Create"
    };
  }

  return row;
}

export function setDialogUser(row, role) {
  let form = {
    userObj: {
      user: {
      }
    }
  };

  if (row) {
    form.userObj.user.id = row.id;
    form.userObj.user.firstName = row.firstName;
    form.userObj.user.lastName = row.lastName;
    form.userObj.user.idNumber = row.idNumber;
    form.userObj.user.trafficRegisterNumber = row.trafficRegisterNumber;
    form.userObj.user.businessRegistrationNumber = row.businessRegistrationNumber;
    form.userObj.user.phoneNumber = row.phoneNumber;
    form.userObj.user.emailAddress = row.emailAddress;
    form.userObj.parentId = row.parentId ?? '';
    form.userObj.user.role = row.role;
    form.userObj.role = row.role;
    form.userObj.isAlsoDriver = row.isAlsoDriver;
    form.action = 'Update';

		if(row.role === Roles.Driver || row.role === Roles.Operator && form.userObj.isAlsoDriver === true){
			let expiryDateStr = row.prDPExpiryDate;
			let expiryTimeIndex = row.prDPExpiryDate.indexOf('T');
			if(expiryTimeIndex >= 0) expiryDateStr = expiryDateStr.substring(0, expiryTimeIndex);
			form.userObj.prdpExpiryDate = expiryDateStr;
			
			if(row.prDPRenewalDate === '' || row.prDPRenewalDate === null){
				form.userObj.prdpRenewalDate = '';
				form.userObj.prdpRenewalInProgress = false;
				form.userObj.prdpRenewalApproved = null;
				form.userObj.prdpRenewedBeforeExpiryDate = null;
			}else{
				let renewalDateStr = row.prDPRenewalDate;
				let renewalTimeIndex = row.prDPExpiryDate.indexOf('T');
				if(expiryTimeIndex >= 0) renewalDateStr = renewalDateStr.substring(0, expiryTimeIndex);
				form.userObj.prdpRenewalDate = renewalDateStr;
				form.userObj.prdpRenewalInProgress = row.prDPRenewalInProgress === true;
				form.userObj.prdpRenewalApproved = row.prDPRenewalApproved === true;
				form.userObj.prdpRenewedBeforeExpiryDate = row.prDPRenewedBeforeExpiryDate === true;
			}
		} else{
			form.userObj.prdpExpiryDate = null;
			form.userObj.prdpRenewalInProgress = null;
			form.userObj.prdpRenewalDate = '';
			form.userObj.prdpRenewalApproved = null;
			form.userObj.prdpRenewedBeforeExpiryDate = null;
		}
  }
  else {
    form.userObj.user.id = '';
    form.userObj.user.firstName = '';
    form.userObj.user.lastName = '';
    form.userObj.user.idNumber = '';
    form.userObj.user.trafficRegisterNumber = '';
    form.userObj.user.phoneNumber = '';
    form.userObj.user.emailAddress = '';
    form.userObj.parentId = '';
    form.userObj.user.role = role;
    form.userObj.role = role;
    form.userObj.isAlsoDriver = false;
    form.action = 'Create';
  }

  return form;
};

export function setDialogVehicle(row) {
  let form = {
    vehicle: {

    }
  };

  if (row) {
    form.vehicle.id = row.Id;
    form.vehicle.licencePlateNumber = row.natisResponse?.sAPVehicleQueryResponse[0]?.vehicle?.licenceNumber ?? '';
    form.vehicle.vinNumber = row.vin;
    form.vehicle.operatorId = row.operatorId;
    form.action = 'Update';
  }
  else {
    form.vehicle.id = '';
    form.vehicle.licencePlateNumber = '';
    form.vehicle.vinNumber = '';
    form.vehicle.operatorId = '';
    form.action = 'Create';
  }

  return form;
}

export function setDialogCompany(row) {
  let form = {
    company: {

    }
  };

  if (row) {
    form.company.id = row.id;
    form.company.name = row.name;
    form.company.phoneNumber = row.phoneNumber;
    form.company.emailAddress = row.emailAddress;
    form.company.controllingRegion = row.controllingRegion;
    form.company.businessRegistrationNumber = row.businessRegistrationNumber;
    form.action = 'Update';
  }
  else {
    form.company.id = '';
    form.company.name = '';
    form.company.phoneNumber = '';
    form.company.emailAddress = '';
    form.company.controllingRegion = '';
    form.company.businessRegistrationNumber = '';
    form.action = 'Create';
  }

  return form;
}

export function setBankingDetailDialog(data, isOriginalDetail) {
  let bankingDetails = {};

  if (!isOriginalDetail) {
    bankingDetails.id = data.id;
  }

  bankingDetails.taxNumber = data.taxNumber;
  bankingDetails.bankId = data.bankId;
  bankingDetails.accountNumber = data.accountNumber;
  bankingDetails.accountHolder = data.accountHolder;
  bankingDetails.bankAccountTypeId = data.bankAccountTypeId;
  bankingDetails.branchCode = data.branchCode;

  return bankingDetails;
}

export function formatNumber(number) {
  let num = number?.split(' ').join('');
  if (num.length === 10 && num?.startsWith('0')) {
    num = '27' + num.substring(1);
  }
  return num;
}

export function formatToLocalNumber(number) {
  if (number?.length === 11 && number?.startsWith('27')) {
    number = '0' + number.substring(2);
  }
  return number;
}

export function isNumber(number) {
  return !isNaN(parseInt(number));
}

export function sortData(a, b) {
  let compareVals = a.name && b.name ? a.name < b.name : a.firstName < b.firstName;

  if (compareVals) {
    return -1;
  }

  if (compareVals) {
    return 1;
  }

  return 0;
}

export function isFormValid(model, inputFields) {
  let status = true;
  let requiredFields = inputFields;
  Object.keys(inputFields).forEach((field) => {
    if (model.user != null && field !== 'parentId') {
      requiredFields[field] = validateSingleField(inputFields[field], true, model.user[field]);
    } else {
      requiredFields[field] = validateSingleField(inputFields[field], true, model[field]);
    }
    if (requiredFields[field].error) {
      status = false;
    }
  });

  return { status: status, inputFields: requiredFields };
}

export function getWelcomeText() {
  return <List
    component="ol"
    disablePadding
    style={{ display: "block" }}
  >
    <ListItem component="span" style={{ fontSize: 13, color: blue[800] }} divider disableGutters dense>
      In order to be successfully signed up and registered as a Blue Dot Operator, the following processes must be completed successfully:
          </ListItem>
    <ListItem key={1} component="li" disableGutters divider dense>
      <ListItemText
        disableTypography
        style={{ fontSize: 11, color: blue[800] }}
        primary="1. Operator must complete sign-up and add eligible Vehicle(s) and Driver(s)" />
    </ListItem>
    <ListItem key={2} component="li" disableGutters divider dense>
      <ListItemText
        disableTypography
        style={{ fontSize: 11, color: blue[800] }}
        primary="2. Blue Dot Terms and Conditions must be signed by Operator and Drivers" />
    </ListItem>
    <ListItem key={3} component="li" disableGutters divider dense>
      <ListItemText
        disableTypography
        style={{ fontSize: 11, color: blue[800] }}
        primary="3. Registration for Operator Payment must be completed (manual process with WCG)" />
    </ListItem>
    <ListItem key={4} component="li" disableGutters divider dense>
      <ListItemText
        disableTypography
        style={{ fontSize: 11, color: blue[800] }}
        primary="4. Vehicle inspection and fitout  must be completed" />
    </ListItem>
    <ListItem key={5} component="li" disableGutters divider dense>
      <ListItemText
        disableTypography
        style={{ fontSize: 11, color: blue[800] }}
        primary="5. Operator and driver training must be completed" />
    </ListItem>
    <ListItem component="span" style={{ fontSize: 13, color: blue[800] }} divider disableGutters dense>
      You are then ready for Go Live.
          </ListItem>
  </List>
}

export async function uploadData(tabName, file){
  switch (tabName) {
      case DashboardTabs.BankingDetails:
          return bankingService.UploadAsync(file)
              .then(response => {
                  if (response.success) {
                  }
                  return response;
              });
      default:
          return [];
  }
}

export function getFile(role){
  if(role === Roles.Driver){
      identityServerService.GetDriverTsAndCsAsync()
          .then(response => {
              const file = new Blob(
                  [response.data], 
                  {type: 'application/pdf'});
                  
              const fileURL = URL.createObjectURL(file);

              window.location.replace(fileURL);
          });
  }
  else if(role === Roles.Operator){
      identityServerService.GetOperatorTsAndCsAsync()
          .then(response => {
              const file = new Blob(
                  [response.data], 
                  {type: 'application/pdf'});
                  
              const fileURL = URL.createObjectURL(file);

              window.location.replace(fileURL);
          });
  }
  else{
      return <div></div>
  }
}