import React, { Component } from 'react';
import { Overview } from '../Overview';
import { EntityGrid } from '../grids/EntityGrid';
import { TabBar } from "../TabBar";
import '../../styles/dashboard.css';
import '../../styles/NavMenu.css';
import { Roles, DashboardTabs } from '../../constants/RoleScreenConstants';
import authService from '../api-authorization/AuthorizeService';
import userService from '../../services/UserService';
import { VehicleColumns } from '../grids/VehicleColumns';

export class VehiclePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loggedInEntityType: "",
            user: {},
            tabVisibility: {
                showOverview: false,
                showVehicles: false,
            },
            dashboardstats: {
                drivers: {
                    registered: 0,
                    approved: 0
                },
                operators: {
                    registered: 0,
                    approved: 0
                },
                vehicles: {
                    registered: 0,
                    approved: 0
                },
                routes: {
                    registered: 0,
                    approved: 0
                }
            }
        }
    }

    manageTabVisibility() {
        let tabsToSee = this.state.tabVisibility;

        switch (this.state.loggedInEntityType) {
            case Roles.BlueDotAdmin:
                tabsToSee.showOverview = true;
                tabsToSee.showVehicles = true;
                this.setState({ tabVisibility: tabsToSee });
                break;
            case Roles.Uts:
                tabsToSee.showOverview = true;
                this.setState({ tabVisibility: tabsToSee });
                break;
            case Roles.RegionalCompany:
                tabsToSee.showOverview = true;
                tabsToSee.showVehicles = true;
                this.setState({ tabVisibility: tabsToSee });
                break;
            case Roles.Operator:
                tabsToSee.showOverview = true;
                tabsToSee.showVehicles = true;
                this.setState({ tabVisibility: tabsToSee });
                break;
            default:
                break;
        };
    }

    componentDidMount() {
        authService.getUser()
            .then(userProfile => {
                userService.GetUserByIdAsync(userProfile?.sub)
                    .then(response => {
                        if(response?.success){
                            if (response?.data) {
                                let user = response?.data;
                                let role = user?.role ?? userProfile?.role;
                                this.setState({ loggedInEntityType: role, user: user });
                                this.manageTabVisibility();
                            }
                        }
                    });
            });
    }

    tabBarChildren() {
        let childrenTabs = [];
        let tabCounter = 0;

        if (this.state.tabVisibility.showOverview) {
            childrenTabs.push(
                <div index={tabCounter} label={DashboardTabs.Overview} key={DashboardTabs.Overview}>
                    <Overview loggedInEntity={DashboardTabs.Overview} dashboardstats={this.state.dashboardstats} />
                </div>
            );

            tabCounter++;
        }

        if (this.state.tabVisibility.showVehicles) {
            childrenTabs.push(
                <div index={1} label={DashboardTabs.Vehicles} key={DashboardTabs.Vehicles}>
                    <EntityGrid user={this.state.user} columns={VehicleColumns} tabName={DashboardTabs.Vehicles} />
                </div>
            );

            tabCounter++;
        }

        if (childrenTabs.length !== 0) {
            return (<TabBar>{childrenTabs}</TabBar>)
        }
    }

    render() {
        return (
            <div className="h-100">
                { this.tabBarChildren()}
            </div>
        );
    }
}