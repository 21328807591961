import React from 'react';
import moment from 'moment';
import '../../styles/grid.css';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import { withStyles } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';
import { formatToLocalNumber } from '../../common/FormHelpers';
import { Columns } from '../../constants/RoleScreenConstants';
import Tooltip from '@material-ui/core/Tooltip';
import { Button } from '@material-ui/core';
const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.gray,
        color: 'rgba(255,255,255, 0.9)',
        boxShadow: theme.shadows[1],
        fontSize: 15,
    },
}))(Tooltip)

export const OperatorColumns = [{
    label: Columns.FirstName.label,
    name: Columns.FirstName.name,
    options: {
        setCellProps: () => ({ style: { whiteSpace: "pre" } })
    }
},
{
    label: Columns.LastName.label,
    name: Columns.LastName.name,
    options: {
        setCellProps: () => ({ style: { whiteSpace: "pre" } })
    }
},
{
    label: Columns.IdNumber.label,
    name: Columns.IdNumber.name,
    options: {
        display: false
    }
},
{
    label: Columns.BusinessRegistrationNumber.label,
    name: Columns.BusinessRegistrationNumber.name,
    options: {
        display: false
    }
},
{
    label: Columns.EmailAddress.label,
    name: Columns.EmailAddress.name,
    options: {
        display: false
    }
},
{
    label: Columns.PhoneNumber.label,
    name: Columns.PhoneNumber.name,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            if (value) {
                return formatToLocalNumber(value);
            }
            return '';
        },
        setCellProps: () => ({ style: { whiteSpace: "pre" } })
    }
},
{
    label: Columns.Status.label,
    name: Columns.Status.name,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            if (value.status === true) {
                return <LightTooltip title="Eligible">
                    <CheckIcon style={{ color: green[500] }} />
                </LightTooltip>;
            }
            else if (value.status === false) {
                return <LightTooltip style={{ fontSize: 20 }} title={value.ineligibilityReasons?.join(', ') ?? ''}>
                    <ClearIcon style={{ color: red[500] }} />
                </LightTooltip>;
            }
            else {
                return <LightTooltip style={{ fontSize: 20 }} title={value.ineligibilityReasons?.join(', ') ?? ''}>
                    <AccessTimeIcon />
                </LightTooltip>;
            }
        }
    }
},
{
    label: Columns.IsAlsoDriver.label,
    name: Columns.IsAlsoDriver.name,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            if (value === true) {
                return <CheckIcon style={{ color: green[500], whiteSpace: 'pre' }} />
            }
            else if (value === false) {
                return <ClearIcon style={{ color: red[500], whiteSpace: 'pre' }} />
            }
        }
    }
},
{
    label: Columns.IsEligibleAsDriver.label,
    name: Columns.IsEligibleAsDriver.name,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            if (value.status === true) {
                return <LightTooltip title="Is Eligible As Driver">
                    <CheckIcon style={{ color: green[500] }} />
                </LightTooltip>;
            }
            else if (value.status === false) {
                return <LightTooltip style={{ fontSize: 20 }} title={value.ineligibilityReasons?.join(', ') ?? ''}>
                    <ClearIcon style={{ color: red[500] }} />
                </LightTooltip>;
            }
            else {
                return <LightTooltip style={{ fontSize: 20 }} title={value.ineligibilityReasons?.join(', ') ?? ''}>
                    <AccessTimeIcon />
                </LightTooltip>;
            }
        }
    }
},
{
    label: Columns.PrDPExpiryDate.label,
    name: Columns.PrDPExpiryDate.name,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            if (value) {
                return moment(value).format('DD MMM YYYY');
            }
            return '';
        },
        setCellProps: () => ({ style: { whiteSpace: "pre" } })
    }
},
{
    label: Columns.ParentCompany.label,
    name: Columns.ParentCompany.name,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            return value?.name ?? '';
        },
        setCellProps: () => ({ style: { whiteSpace: "pre" } })
    }
},
{
    label: Columns.TrainingStatus.label,
    name: Columns.TrainingStatus.name,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            if (value.hasCompletedTraining) {
                return <LightTooltip title={value.trainingStatus}>
                    <CheckIcon style={{ color: green[500] }} />
                </LightTooltip>;
            }
            else {
                return <LightTooltip title={value.trainingStatus}>
                    <ClearIcon style={{ color: red[500] }} />
                </LightTooltip>;
            }
        }
    }
},
{
    label: Columns.TagNumber.label,
    name: Columns.TagNumber.name,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            return value.tagNumber ?? '';
        },
        setCellProps: () => ({ style: { whiteSpace: "pre" } })
    }
},
{
    label: Columns.TrainingCompletionDate.label,
    name: Columns.TrainingCompletionDate.name,
    type: Columns.TrainingCompletionDate.type,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            if (value.trainingCompletionDate) {
                return moment(value.trainingCompletionDate).format('DD MMM YYYY');
            }
            return '';
        },
        setCellProps: () => ({ style: { whiteSpace: "pre" } })
    }
},
{
    label: Columns.DateAdded.label,
    name: Columns.DateAdded.name,
    type: Columns.DateAdded.type,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            if (value) {
                return moment(value).format('DD MMM YYYY');
            }
            return '';
        },
        setCellProps: () => ({ style: { whiteSpace: "pre" } })
    }
},
{
    label: Columns.DateModified.label,
    name: Columns.DateModified.name,
    type: Columns.DateModified.type,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            if (value) {
                return moment(value).format('DD MMM YYYY');
            }
            return '';
        },
        setCellProps: () => ({ style: { whiteSpace: "pre" } })
    }
},
{
    label: Columns.HasAcceptedTermsAndConditions.label,
    name: Columns.HasAcceptedTermsAndConditions.name,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            if (value) {
                return <LightTooltip>
                    <CheckIcon style={{ color: green[500] }} />
                </LightTooltip>;
            }
            else {
                return <LightTooltip>
                    <ClearIcon style={{ color: red[500] }} />
                </LightTooltip>;
            }
        }
    }
},
{
    label: Columns.HasCreatedPassword.label,
    name: Columns.HasCreatedPassword.name,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            if (value) {
                return <LightTooltip>
                    <CheckIcon style={{ color: green[500] }} />
                </LightTooltip>;
            }
            else {
                return <LightTooltip>
                    <ClearIcon style={{ color: red[500] }} />
                </LightTooltip>;
            }
        }
    }
},
{
    label: Columns.UpdateEligibility.label,
    name: "id",
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            return <Button name='updateOperator'>
                {'Update'} 
            </Button>;
        },
        download: false
    }
},
{
    label: Columns.EditButton.label,
    name: Columns.EditButton.name,
    options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            return <Button value={value}>{'Edit'}</Button>;
        },
        download: false
    }
}
];