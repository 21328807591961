import React, { Component } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import regionalCompanyService from "../../services/RegionalCompanyService";
import { formatToLocalNumber, formatNumber, isFormValid, handleCompanyChange } from '../../common/FormHelpers';
import { alertMessages, alertType } from '../AlertNotification';
import NumberFormat from 'react-number-format';
import { trackPromise } from 'react-promise-tracker';
import { Areas } from '../../constants/areas';
import { Spinner } from '../Spinner';

export class CompanyDialog extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            action: '',
            tabName: '',
            requiredInputs: {
                name: {
                    error: false,
                    message: ''
                },
                phoneNumber: {
                    error: false,
                    message: ''
                },
                controllingRegion: {
                    error: false,
                    message: ''
                }
            },
            company: {
                id: '',
                name: '',
                emailAddress: '',
                phoneNumber: '',
                businessRegistrationNumber: '',
                controllingRegion: ''
            },
            shouldDisableButton: false
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange = (event) => {
        let company = handleCompanyChange(event, this.state);
        this.setState({ company: company });
    }

    setSelectedRow = () => {
        this.setState({ company: this.props.row.company, action: this.props.row.action });
    }

    addCompany = () => {
        let { status, inputFields } = isFormValid(this.state.company, this.state.requiredInputs);

        if (status === true) {
            this.setState({ shouldDisableButton: true });
            let company = this.state.company;

            company.phoneNumber = formatNumber(company.phoneNumber);

            if (this.state.action === 'Create') {
                company.id = undefined;

                trackPromise(
                    regionalCompanyService.CreateAsync(company)
                        .then(response => {
                            this.setState({ shouldDisableButton: false });

                            if (response.success) {
                                this.props.closeDialog(true, response?.message ?? alertMessages.saveSuccess, alertType.success);

                                return;
                            }

                            this.props.closeDialog(true, response?.message ?? alertMessages.saveError, alertType.error, true);
                        })
                        .catch(error => {
                            this.setState({ shouldDisableButton: false });
                            this.props.closeDialog(true, error.message?.join(', ') ?? alertMessages.saveError, alertType.error, true);
                        }),
                    Areas.dialog
                );
            }
            else {
                trackPromise(
                    regionalCompanyService.UpdateAsync(company)
                        .then(response => {
                            this.setState({ shouldDisableButton: false });

                            if (response.success) {
                                this.props.closeDialog(true, response.message ?? alertMessages.updateSuccess, alertType.success);

                                return;
                            }

                            this.props.closeDialog(true, response.message ?? alertMessages.saveError, alertType.error);
                        })
                        .catch(error => {
                            this.setState({ shouldDisableButton: false });
                            this.props.closeDialog(true, error.message?.join(', ') ?? alertMessages.saveError, alertType.error, true);
                        }),
                    Areas.dialog
                );
            }
        }
        else {
            this.setState({ requiredInputs: inputFields });
        }
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        let { name, phoneNumber, emailAddress, businessRegistrationNumber, controllingRegion } = this.props.row.company;

        phoneNumber = formatToLocalNumber(phoneNumber);

        return (
            <Dialog open={this.props.open} onEnter={this.setSelectedRow}>
                <DialogTitle id="form-dialog-title">{this.state.action} {this.props.role}</DialogTitle>
                <DialogContent>
                    <div className="row justify-content-around">
                        <Spinner area={Areas.dialog} className="h-50 container-fluid" innerClassName="col h-100 text-center" style={{ position: 'absolute' }} />
                    </div>
                    <form>
                        <TextField
                            autoFocus
                            required
                            error={this.state.requiredInputs.name.error}
                            helperText={this.state.requiredInputs.name.message}
                            margin="dense"
                            name="name"
                            label="Company Name"
                            type="text"
                            fullWidth
                            onChange={this.handleInputChange}
                            defaultValue={name}
                        />
                        <NumberFormat
                            format="### ### ####"
                            placeholder="e.g. 071 234 5678"
                            customInput={TextField}
                            autoFocus
                            margin="dense"
                            required
                            error={this.state.requiredInputs.phoneNumber.error}
                            helperText={this.state.requiredInputs.phoneNumber.message}
                            name="phoneNumber"
                            label="Phone Number"
                            type="text"
                            fullWidth
                            onChange={this.handleInputChange}
                            defaultValue={phoneNumber}
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            name="emailAddress"
                            label="Email Address"
                            type="email"
                            fullWidth
                            onChange={this.handleInputChange}
                            defaultValue={emailAddress}
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            name="businessRegistrationNumber"
                            label="Business Reg. Number"
                            type="text"
                            fullWidth
                            onChange={this.handleInputChange}
                            defaultValue={businessRegistrationNumber}
                        />
                        <TextField
                            autoFocus
                            required
                            error={this.state.requiredInputs.controllingRegion.error}
                            helperText={this.state.requiredInputs.controllingRegion.message}
                            margin="dense"
                            name="controllingRegion"
                            label="Controlling Region"
                            type="text"
                            fullWidth
                            onChange={this.handleInputChange}
                            defaultValue={controllingRegion}
                        />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { this.props.closeDialog(false, '', alertType.success) }} color="primary">
                        Cancel
                    </Button>
                    <Button type="button" onClick={this.addCompany} color="primary" disabled={this.state.shouldDisableButton}>
                        {this.state.action}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}