export const baseApiUrl = "https://asp-bluedot-prod-app-api.azurewebsites.net";
export const baseIdentityServerUrl = "https://asp-bluedot-prod-identityserver.azurewebsites.net";

export const Endpoints =
{
    Auth: {
        Login: `${baseIdentityServerUrl}/Login`,
        SignIn: `${baseIdentityServerUrl}/SignIn`
    },
    BankingDetails: {
        GetAll: `${baseApiUrl}/BankAccount/GetAll`,
        GetByUserId: `${baseApiUrl}/BankAccount/GetByUserId`,
        GetByRegionalCompanyUserId: `${baseApiUrl}/BankAccount/GetByRegionalCompanyUserId`,
        GetUts: `${baseApiUrl}/BankAccount/GetUts`,
        Create: `${baseApiUrl}/BankAccount/Create`,
        Update: `${baseApiUrl}/BankAccount/Update`,
        Upload: `${baseApiUrl}/BankAccount/Upload`,
        GetBankAccountTypes: `${baseApiUrl}/BankAccount/GetBankAccountTypes`,
        GetBanks: `${baseApiUrl}/BankAccount/GetBanks`
    },
    Driver: {
        Get: `${baseApiUrl}/Driver/GetDrivers`,
        GetTotals: `${baseApiUrl}/Driver/GetTotals`,
        UpdateDriverEligibility: `${baseApiUrl}/Driver/UpdateDriverEligibility`
    },
    IdentityServer: {
        GetDriverTsAndCs: `${baseIdentityServerUrl}/consent/GetTsAndCsByRole?role=Driver`,
        GetOperatorTsAndCs: `${baseIdentityServerUrl}/consent/GetTsAndCsByRole?role=Operator`
    },
    Operator: {
        UpdateOperatorEligibility: `${baseApiUrl}/Operator/UpdateOperatorEligibility`,
        Get: `${baseApiUrl}/Operator/GetOperators`,
        GetTotals: `${baseApiUrl}/Operator/GetTotals`
    },
    RegionalCompany: {
        Get: `${baseApiUrl}/RegionalCompany/GetRegionalCompanies`,
        GetUsers: `${baseApiUrl}/RegionalCompany/GetRegionalCompanyUsers`,
        GetTotals: `${baseApiUrl}/RegionalCompany/GetRegionalCompanyTotals`,
        GetUserTotals: `${baseApiUrl}/RegionalCompany/GetRegionalCompanyUserTotals`,
        Create: `${baseApiUrl}/RegionalCompany/CreateRegionalCompany`,
        Update: `${baseApiUrl}/RegionalCompany/UpdateRegionalCompany`
    },
    ServiceProviderVerificationDetail: {
        Get: `${baseApiUrl}/ServiceProviderVerificationDetail/Get`,
        GetByEntityReference: `${baseApiUrl}/ServiceProviderVerificationDetail/GetByEntityReference`,
        Create: `${baseApiUrl}/ServiceProviderVerificationDetail/Create`,
        Update: `${baseApiUrl}/ServiceProviderVerificationDetail/Update`,
        Delete: `${baseApiUrl}/ServiceProviderVerificationDetail/Delete`
    },
    User: {
        Create: `${baseApiUrl}/User/Create`,
        Update: `${baseApiUrl}/User/Update`,
        Delete: `${baseApiUrl}/User/Delete`,
        CreatePassword: `${baseApiUrl}/User/CreatePassword`,
        SignUpUser: `${baseApiUrl}/User/FirstTimeSignIn`,
        GetUserById: `${baseApiUrl}/User/GetUserById`,
        GetUserBySearchCriteria: `${baseApiUrl}/User/GetUserBySearchCriteria`,
        GetUserByUserName: `${baseApiUrl}/User/GetUserByUserName`,
        GetUsersByRole: `${baseApiUrl}/User/GetUsersByRole`,
        GetUsers: `${baseApiUrl}/User/GetUsers`,
        VerifyOtp: `${baseApiUrl}/User/VerifyOtp`,
    },
    Uts: {
        GetUserById: `${baseApiUrl}/Uts/GetUserById`,
        GetUserBySearchCriteria: `${baseApiUrl}/Uts/GetUserBySearchCriteria`,
        GetUserByUserName: `${baseApiUrl}/Uts/GetUserByUserName`,
        GetUtsUserTotals: `${baseApiUrl}/Uts/GetUtsUserTotals`,
        GetUsers: `${baseApiUrl}/Uts/GetUsers`
    },
    Vehicle: {
        Create: `${baseApiUrl}/Vehicle/Create`,
        Get: `${baseApiUrl}/Vehicle/GetVehicles`,
        GetWithNatisInfo: `${baseApiUrl}/Vehicle/GetVehicles?getNatisInfo=true`,
        GetTotals: `${baseApiUrl}/Vehicle/GetTotals`,
        UpdateVehicleEligibility: `${baseApiUrl}/Vehicle/UpdateVehicleElgibility`,
        UpdateVehicleData: `${baseApiUrl}/Vehicle/ImportVehiclesFromPtrs`
    },
    Notifications: {
        Get: `${baseApiUrl}/Notification/GetAllNotifications`,
        GetOperatorNotifications: `${baseApiUrl}/Notification/GetNotificationsForOperators`,
        GetDriverNotifications: `${baseApiUrl}/Notification/GetAllDriverNotifications`,
        GetUtsUsersNotifications: `${baseApiUrl}/Notification/GetAllUtsNotifications`,
        GetRegionalCompanyUsersNotifications: `${baseApiUrl}/Notification/GetNotificationsForRegionalCompanyUsers`,
        GetNotificationsById: `${baseApiUrl}/Notification/GetNotificationsByUserId`,
        Create: `${baseApiUrl}/Notification/SendSmsNotification`
    }
};

export const Headers = {
    ContentType: 'Content-Type',
    ApimSubscriptionHeader: 'Ocp-Apim-Subscription-Key',
    ApimTraceHeader: 'Ocp-Apim-Trace'
};

export const StatusCodes = {
    Success: 200,
    Created: 201,
    NotFound: 404,
    BadRequest: 404,
    InternalServerError: 404
};

export const TermsAndConditionsConstants = {
    DevBaseUrl: 'https://asp-bluedot-dev-app.azurewebsites.net',
    StagingBaseUrl: 'https://asp-bluedot-staging-app.azurewebsites.net',
    ProductionBaseUrl: 'https://bluedot.westerncape.gov.za'
};

export const EnviromentConstants = {
    development: 'development',
    Staging: 'Staging',
    Production: 'Production'
};